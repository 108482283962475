import { strToMin } from './strToMin'

export const timeDiff = (start, end) => {
  const startH = parseInt(start?.slice(0, 2))
  const endH = parseInt(end?.slice(0, 2))
  // const startM = parseInt(start.slice(3, 5))
  // const endM = parseInt(end.slice(3, 5))

  if (!end) return 0
  if (endH - startH < 0 || startH >= 24 || endH >= 24 || startH < 0 || endH < 0)
    return 0
  return strToMin(end) - strToMin(start)
}
