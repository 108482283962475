import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { timeDiff } from '../../utils/timeDiff'
import { minutesToTime } from '../../utils/minutesToTime'

const TrackingHead = ({ activity }) => {
  const [helmetTitle, setHelmetTitle] = useState('🐵 Tracking - CITO')
  const { _id, title, project, company, category, timeStart } = activity

  const printTabMessage = (diff) => {
    // prettier-ignore
    if (!_id) return diff?.slice(-2) % 2 === 0 || !diff ? 
    `⛔ ${diff ? diff + ' - ' : ''}NO TRACKING!` : 
    `🚫 ${diff ? diff + ' - ' : ''}START TASK NOW!`

    // prettier-ignore
    else if (!title) return  diff?.slice(-2) % 2 === 0 || !diff ? 
    `☑ ${diff ? diff + ' - ' : ''}${_id}` :
    `🔄 ${diff ? diff + ' - ' : ''}REFRESH`

    // prettier-ignore
    else if (category?.type === 'PRZERWA') return  diff?.slice(-2) % 2 === 0 || !diff ? 
    `⏸ ${diff ? diff + ' - ':''}PRZERWA` :
    `🚾 ${diff ? diff + ' - ':''}CITO`

    // prettier-ignore
   else return `✅ ${diff ? diff + ' - ':''}${company?.name} - ${title} [${category?.name}] ${project?.name && `(${project.name})`} `
  }

  const nowOutside = new Date().toString().slice(16, 24)
  let interval

  const animateTab = () => {
    let now = new Date().toString().slice(16, 24)
    let seconds = now.slice(6, 8)
    let diff =
      minutesToTime(timeDiff(timeStart || nowOutside, now)) + ':' + seconds
    document.title = printTabMessage(diff)
  }

  // const animateState = () => {
  //   let now = new Date().toString().slice(16, 24)
  //   let seconds = now.slice(6, 8)
  //   let diff =
  //     minutesToTime(timeDiff(timeStart || nowOutside, now)) + ':' + seconds
  //   setHelmetTitle(printTabMessage(diff))
  // }

  function handleTabChange() {
    if (!document.hidden) {
      if (interval) clearInterval(interval)
      document.title = printTabMessage()
    } else interval = setInterval(() => animateTab(), 1000)
  }

  useEffect(() => {
    window.addEventListener('visibilitychange', handleTabChange)
    return () => window.removeEventListener('visibilitychange', handleTabChange)
  })

  /* eslint-disable */
  useEffect(() => {
    setHelmetTitle(printTabMessage())
  }, [])
  /* eslint-enable */

  /* eslint-disable */
  useEffect(() => {
    handleTabChange()
  }, [activity])
  /* eslint-enable */

  return (
    <Helmet>
      <title>{helmetTitle}</title>
    </Helmet>
  )
}

export default TrackingHead
