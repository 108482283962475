/** @jsxImportSource @emotion/react */

import { useQuery } from '@apollo/client'
import { CircularProgress, Grid, Hidden } from '@material-ui/core'
import _ from 'lodash'
import { Redirect } from 'react-router-dom'
import { toast } from 'react-toastify'
import ActivityUserCard from '../../components/ActivityUserCard'
import PageWrapper from '../../components/PageWrapper'
import ActivitiesDetails from '../Activities/ActivitiesDetails'
import { ACTIVITY_STOP_TODAY } from '../../graphql/activity.graphql'
import { s, colors } from '../../style'
import PageTitle from '../../components/PageTitle'

const Stop = () => {
  const { loading, data, error } = useQuery(ACTIVITY_STOP_TODAY)

  if (error) {
    toast.error(error.toString(), { autoClose: false })
    return <Redirect to='/tracking' />
  }

  if (loading || !data)
    return (
      <div css={[sView, { justifyContent: 'center' }]}>
        <CircularProgress
          thickness={5}
          size={160}
          css={{ color: colors.gray400 }}
        />
      </div>
    )

  let activities

  activities = data.activityStopToday?.filter((activity) =>
    activity.timeEnd && activity.minutes < 1 ? false : true
  )

  if (!loading && activities?.length === 0) {
    toast.dark(
      <div>
        <strong>Rozpocznij Tracking! 😎</strong>
        <br />
        <span>Dzisiaj jeszcze nie zarejestrowano aktywności</span>
      </div>
    )
    return <Redirect to='/tracking' />
  }

  const results = _(activities)
    .groupBy('author._id')
    .map((objs, key) => {
      const h_activities = _.sumBy(objs, 'minutes') / 60

      const l_activities = objs.filter(
        (obj) => obj.__typename === 'Activity'
      ).length

      const shine = objs.filter((obj) => !obj.timeEnd).length > 0

      const categories = _(objs)
        .groupBy('category.name')
        .map((cats, id) => {
          const categoryH = _.sumBy(cats, 'minutes') / 60
          const categoryWD = (categoryH / h_activities) * 100

          const shine = cats.filter((obj) => !obj.timeEnd).length > 0

          const companies = _(cats)
            .groupBy('company.name')
            .map((comps, id) => {
              const companyH = _.sumBy(comps, 'minutes') / 60
              const companyWD = (companyH / categoryH) * 100

              const shine = comps.filter((obj) => !obj.timeEnd).length > 0

              const projects = _(comps)
                .groupBy('project.name')
                .map((projs, id) => {
                  const projectH = _.sumBy(projs, 'minutes') / 60
                  const projectWD = (projectH / companyH) * 100

                  const shine = comps.filter((obj) => !obj.timeEnd).length > 0

                  // console.log(athrs)
                  return {
                    _id: projs[0].project?._id,
                    name: projs[0].project?.name || 'Bez projektu',
                    color: projs[0].project?.color || colors.gray600,
                    h_activities: projectH,
                    workDone: projectWD,
                    tasks: projs,
                    shine,
                  }
                })
                .sortBy(['h_activities', 'name'])
                .reverse()
                .value()

              return {
                _id: comps[0].company._id,
                name: id,
                color: comps[0].company.color,
                icon: comps[0].company.icon,
                h_activities: companyH,
                workDone: companyWD,
                projects,

                shine,
              }
            })
            .sortBy(['h_activities', 'name'])
            .reverse()
            .value()

          return {
            _id: cats[0].category._id,
            name: id,
            color: cats[0].category.color,
            h_activities: categoryH,
            workDone: categoryWD,
            companies,
            shine,
          }
        })
        .sortBy(['h_activities', 'name'])
        .reverse()
        .value()

      const companies = _(objs)
        .groupBy('company.name')
        .map((comps, id) => {
          const companyH = _.sumBy(comps, 'minutes') / 60
          const companyWD = (companyH / h_activities) * 100

          const shine = comps.filter((obj) => !obj.timeEnd).length > 0

          const projects = _(comps)
            .groupBy('project.name')
            .map((projs, id) => {
              const projectH = _.sumBy(projs, 'minutes') / 60
              const projectWD = (projectH / companyH) * 100

              const shine = projs.filter((obj) => !obj.timeEnd).length > 0

              const categories = _(projs)
                .groupBy('category.name')
                .map((cats, id) => {
                  const categoryH = _.sumBy(cats, 'minutes') / 60
                  const categoryWD = (categoryH / projectH) * 100

                  const shine = cats.filter((obj) => !obj.timeEnd).length > 0
                  // console.log(athrs)
                  return {
                    _id: cats[0].category._id,
                    name: cats[0].category.name,
                    color: cats[0].category.color,
                    h_activities: categoryH,
                    workDone: categoryWD,
                    tasks: cats,
                    shine,
                  }
                })
                .sortBy(['h_activities', 'name'])
                .reverse()
                .value()

              return {
                _id: projs[0].project?._id,
                name: projs[0].project?.name || 'Bez projektu',
                color: projs[0].project?.color || colors.gray600,
                h_activities: projectH,
                workDone: projectWD,
                categories,
                shine,
              }
            })
            .sortBy(['h_activities', 'name'])
            .reverse()
            .value()

          return {
            _id: comps[0].company._id,
            name: id,
            color: comps[0].company.color,
            icon: comps[0].company.icon,
            h_activities: companyH,
            workDone: companyWD,
            projects,
            shine,
          }
        })
        .sortBy(['h_activities', 'name'])
        .reverse()
        .value()

      const current = objs.find((activity) => !activity.timeEnd)

      return {
        _id: objs[0].author._id,
        firstName: objs[0].author.firstName,
        lastName: objs[0].author.lastName,
        title: objs[0].author.title,
        color: objs[0].author.color,
        image: objs[0].author.image,
        h_activities,
        l_activities,
        role: objs[0].author.role,
        categories,
        companies,
        shine,
        current,
      }
    })
    .sortBy(['h_activities', 'name'])
    .reverse()
    .value()

  return (
    <div css={{ backgroundColor: 'white' }}>
      <PageWrapper>
        <PageTitle>
          <h1
            css={{
              lineHeight: '1.35em !important',
              textAlign: 'center',
              paddingTop: '1rem',
            }}>
            Dzięki <strong>{activities[0].author.firstName}</strong>
            <br />
            Dobra robota! 💪🏼
          </h1>
        </PageTitle>
      </PageWrapper>
      <Hidden mdUp>
        <PageWrapper>
          <ActivitiesDetails
            data={activities}
            days={1}
            users={1}
            currentMinutes={[]}
            minimal
          />
        </PageWrapper>
      </Hidden>
      <Hidden smDown>
        <PageWrapper>
          <ActivitiesDetails
            data={activities}
            days={1}
            users={1}
            currentMinutes={[]}
          />
        </PageWrapper>
      </Hidden>
      <PageWrapper>
        <Grid container spacing={3}>
          {results.map((user) => (
            <ActivityUserCard data={user} key={user._id} large forceOpen />
          ))}
        </Grid>
      </PageWrapper>
    </div>
  )
}

const sView = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  height: 'max-content',
  minHeight: 'calc(100vh - 4rem)',
  backgroundColor: 'white',
  [s.md]: {
    minHeight: '100vh',
  },
}

export default Stop
