/** @jsxImportSource @emotion/react */

import { useQuery } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import PageTitle from '../../components/PageTitle'
import PageWrapper from '../../components/PageWrapper'
import { GET_USER_TASKS } from '../../graphql/task.graphql'
import Skeleton from '@material-ui/lab/Skeleton'
import _ from 'lodash'
import { Grid } from '@material-ui/core'
import UserContainer from './UserContainer'
import ActivitiesEditWrapper from '../Activities/ActivitiesEditWrapper'
import EstimateEditWrapper from '../Estimates/EstimateEditWrapper'
import { useAuth } from '../../hooks/auth.hook'
import { timeDiff } from '../../utils/timeDiff'

const UserTasks = () => {
  const { data, loading, error } = useQuery(GET_USER_TASKS)
  const { userId } = useAuth()
  const now = new Date().toString().slice(16, 21)

  if (error) {
    toast.error(error.toString())
    return <div>{error.toString()}</div>
  }

  if (loading) {
    return (
      <>
        <PageTitle>
          <h1>Tasks</h1>
          <p>
            <span>
              Poniżej znajduje się lista zadań z ClickUp, które zostały dodane
              do estymacji CITO przypisanych do Ciebie w bieżącym miesiącu.
            </span>
          </p>
        </PageTitle>
        <PageWrapper>
          <PageWrapper>
            <Skeleton variant='rect' height={300} css={{ width: '100%' }} />
          </PageWrapper>
        </PageWrapper>
      </>
    )
  }

  // console.log(data)

  let users = _.uniqBy(data?.getUserTasks?.map((t) => t.users).flat(), '_id')

  if (!data || !users?.length > 0)
    return (
      <PageTitle>
        <h1>Tasks</h1>
        <p>
          <span>Nie znaleziono zadań</span>
        </p>
      </PageTitle>
    )

  let tasks = {}

  for (let user of users) {
    let foundTasks = data.getUserTasks.filter(
      (dataTask) =>
        dataTask.users.filter((taskUser) => taskUser._id === user._id).length >
        0
    )

    const isNow = foundTasks.reduce(
      (acc, curr) =>
        acc ||
        curr.activities.reduce((acc1, curr1) => acc1 || !curr1.minutes, false),
      false
    )

    foundTasks = foundTasks.map((dataTask) => ({
      ...dataTask,
      activities: dataTask.activities.map((activity) => ({
        ...activity,
        minutes:
          !activity.minutes && now
            ? timeDiff(activity.timeStart, now)
            : activity.minutes,
      })),
    }))

    const activs = foundTasks.reduce(
      (acc, curr) =>
        acc + curr.activities.reduce((acc1, curr1) => acc1 + curr1.minutes, 0),
      0
    )

    const estims =
      foundTasks.reduce((acc, curr) => acc + curr.estimate.hours, 0) * 60

    tasks = {
      ...tasks,
      [user._id]: { ...user, tasks: foundTasks, activs, estims },
      isNow,
    }
  }

  console.log(tasks)

  return (
    <>
      <PageTitle>
        <h1>Tasks</h1>
        <p>
          <span>
            Poniżej znajduje się lista zadań z ClickUp, które zostały dodane do
            estymacji CITO przypisanych do Ciebie w bieżącym miesiącu.
          </span>
        </p>
      </PageTitle>
      <PageWrapper>
        <PageWrapper>
          <Grid container spacing={3}>
            {Object.values(tasks)
              .filter((user) => user._id === userId)
              .map((user) => (
                <UserContainer data={user} key={user._id} />
              ))}
          </Grid>
        </PageWrapper>
      </PageWrapper>
      <ActivitiesEditWrapper />
      <EstimateEditWrapper />
    </>
  )
}

export default UserTasks
