import _ from 'lodash'
import React from 'react'
import EstimateCard from '../../components/EstimateCard'
import { colors } from '../../style'
import { getToday } from '../../utils/getToday'
import DashboardCompaniesLegend from './DashboardCompaniesLegend'

const DashboardEstimate = ({
  activities,
  estimates,
  selectedCompany,
  setSelectedCompany,
}) => {
  const today = getToday(new Date())

  let activsEstims = activities.concat(estimates)

  const data = _(activsEstims)
    .groupBy('company.name')
    .map((objs, key) => {
      const Ah = _.sumBy(objs, 'minutes') / 60 || 0
      const Eh = _.sumBy(objs, 'hours') || 0
      const workDone = Eh ? (Ah / Eh) * 100 : 0
      const workLeft = Eh - Ah
      const CPH_company = objs[0].company.cph_default || 0

      const l_activities = objs.filter(
        (obj) => obj.__typename === 'Activity'
      ).length
      const l_estimates = objs.filter(
        (obj) => obj.__typename === 'Estimate'
      ).length

      const shine =
        objs.filter((obj) => obj.__typename === 'Activity' && !obj.timeEnd)
          .length > 0

      const isToday =
        objs.filter(
          (obj) =>
            obj.__typename === 'Activity' && obj.date?.slice(0, 10) == today
        ).length > 0

      const projects = _(objs)
        .groupBy('project.name')
        .map((projs, id) => {
          const pAh = _.sumBy(projs, 'minutes') / 60 || 0
          const pEh = _.sumBy(projs, 'hours') || 0
          const pWorkDone = pEh ? (pAh / pEh) * 100 : 0
          const pWorkLeft = pEh - pAh
          let CPH_project

          const projectNoCategory = projs.find(
            (item) => item.__typename === 'Estimate' && !item.category
          )
          if (!pEh || !projectNoCategory) CPH_project = CPH_company
          else CPH_project = projectNoCategory.cph

          const shine =
            projs.filter((obj) => obj.__typename === 'Activity' && !obj.timeEnd)
              .length > 0

          const categories = _(projs)
            .groupBy('category.name')
            .map((cats, id) => {
              const cAh = _.sumBy(cats, 'minutes') / 60 || 0
              const cEh = _.sumBy(cats, 'hours') || 0
              const cWorkDone = cEh ? (cAh / cEh) * 100 : 0
              const cWorkLeft = cEh - cAh
              const CPH_category = cEh
                ? cats.find((cat) => cat.__typename === 'Estimate').cph
                : CPH_project
              const costA = CPH_category * cAh
              const costE = CPH_category * cEh

              const shine =
                cats.filter(
                  (obj) => obj.__typename === 'Activity' && !obj.timeEnd
                ).length > 0

              const authors = _(cats)
                .groupBy('author._id')
                .map((athrs, id) => {
                  const aAh = _.sumBy(athrs, 'minutes') / 60 || 0
                  const aWorkDone = (aAh / cAh) * 100

                  const shine =
                    athrs.filter(
                      (obj) => obj.__typename === 'Activity' && !obj.timeEnd
                    ).length > 0

                  // console.log(athrs)
                  return {
                    name:
                      athrs[0]?.author?.firstName +
                      ' ' +
                      (athrs[0]?.author?.lastName
                        ? athrs[0]?.author?.lastName
                        : ''),

                    color: athrs[0]?.author?.color,
                    image: athrs[0]?.author?.image,
                    h_activities: aAh,
                    workDone: aWorkDone,
                    tasks: athrs,
                    shine,
                  }
                })
                .sortBy(['workDone', 'h_activities', 'name'])
                .reverse()
                .value()

              const users = cats
                .filter((cat) => cat.__typename === 'Estimate')
                ?.map(({ users }) => users)
                ?.flat()

              const usersNames = users.map(({ firstName }, id) =>
                id > 0 ? ' ' + firstName : firstName
              )

              return {
                name:
                  !id || id === 'undefined'
                    ? 'Bez kategorii'
                    : users?.length > 0
                    ? `${id} [${usersNames}]`
                    : id,
                color: cats[0].category?.color || colors.gray600,
                h_activities: cAh,
                h_estimates: cEh,
                workDone: cWorkDone,
                workLeft: cWorkLeft,
                authors: authors.filter((e) => e.h_activities),
                shine,
                costA,
                costE,
                cph: CPH_category,
                estimateIds: cats
                  .filter((cat) => cat.__typename === 'Estimate')
                  ?.map(({ _id }) => _id),
                users,
              }
            })
            .sortBy(['workDone', 'h_activities', 'name'])
            .reverse()
            .value()

          const costA = categories.reduce((sum, curr) => curr.costA + sum, 0)
          const costE = categories.reduce((sum, curr) => curr.costE + sum, 0)

          return {
            name: !id || id === 'undefined' ? 'Bez projektu' : id,
            color: projs[0].project?.color || colors.gray600,
            h_activities: pAh,
            h_estimates: pEh,
            workDone: pWorkDone,
            workLeft: pWorkLeft,
            categories,
            shine,
            costA,
            costE,
            cph: CPH_project,
            estimateIds: projs
              .filter((proj) => proj.__typename === 'Estimate')
              ?.map(({ _id }) => _id),
          }
        })
        .sortBy(['workDone', 'h_activities', 'name'])
        .reverse()
        .value()

      const costA = projects.reduce((sum, curr) => curr.costA + sum, 0)
      const costE = projects.reduce((sum, curr) => curr.costE + sum, 0)

      return {
        _id: objs[0]?.company?._id,
        name: key,
        color: objs[0]?.company?.color,
        icon: objs[0]?.company?.icon,
        h_activities: Ah,
        h_estimates: Eh,
        l_activities,
        l_estimates,
        type: objs[0]?.company?.type,
        workDone,
        workLeft,
        items: projects,
        typename: objs[0]?.company?.__typename,
        shine,
        isToday,
        costA,
        costE,
        cph: CPH_company,
        companyId: objs[0].company._id,
      }
    })
    .sortBy(['workDone', 'h_activities', 'name'])
    .reverse()
    .value()

  let dataSelected = selectedCompany
    ? data.filter((thing) => thing?._id == selectedCompany)
    : data

  return (
    <div>
      {dataSelected && dataSelected[0] && (
        <EstimateCard data={dataSelected[0]} showCPHDetails={false} />
      )}
      {data && (
        <DashboardCompaniesLegend
          data={data.filter((company) => company.isToday)}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
      )}
    </div>
  )
}

export default DashboardEstimate
