// pass new Date() or Date format
// returns string with simple date like 2021-08-15

export const getToday = (now) =>
  now.getFullYear() +
  '-' +
  (now.getMonth() + 1 < 10 ? '0' : '') +
  (now.getMonth() + 1) +
  '-' +
  (now.getDate() < 10 ? '0' : '') +
  now.getDate()
