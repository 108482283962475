/** @jsxImportSource @emotion/react */

// Hooks
import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// Utils
import { sTableCategory } from './Tracking'
import { imageLink } from '../../utils/imageLink'

// Material UI
import { Grid, TextField, Button } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

import ClickupTrackingGetTask from '../Clickup/ClickupTrackingGetTask'
import { toast } from 'react-toastify'
// import axios from 'axios'

import queryString from 'query-string'

const TrackingNew = ({
  namesData,
  // activitiesTitles,
  onNewActivityCreate,
  onNewBreakActivity,
  isLoading,
  setLoading,
}) => {
  const history = useHistory()
  const location = useLocation()
  let params = queryString.parse(location.search)

  // console.log(params)

  const initialNewTaskValues = {
    title: '',
    company: null,
    project: null,
    category: null,
    clickup: '',
  }

  const [token, setToken] = useState(null)
  const [newTaskValues, setNewTaskValues] = useState(initialNewTaskValues)
  // const [newTaskTitleInput, setNewTaskTitleInput] = useState('')

  const onActionCallback = () => {
    setNewTaskValues(initialNewTaskValues)
    // setNewTaskTitleInput('')
  }

  // const { companies, projects, categories } = namesData
  const companies = namesData.companies.filter((thing) => !thing.isHidden)
  const projects = namesData.projects.filter((thing) => !thing.isHidden)
  const categories = namesData.categories.filter((thing) => !thing.isHidden)

  const projectsInCompany = newTaskValues.company
    ? companies
        ?.find((company) => company._id === newTaskValues.company._id)
        ?.projects.filter((project) => !project.isHidden).length
    : -1

  // console.log(projectsInCompany)

  // async function clickupGetTask(id) {
  //   const task = id.replace('#', '')
  //   const response = await fetch(
  //     `https://api.clickup.com/api/v2/task/${task}`,
  //     {
  //       method: 'GET',
  //       // mode: 'no-cors',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Access-Control-Allow-Origin': '*',
  //         Authorization: token,
  //       },
  //     }
  //   )
  //   return response
  // }

  // console.log(newTaskValues.project)
  // console.log(projects)

  // console.log(newTaskValues)

  const selectedProject = newTaskValues.project

  const clickupOverridesFields = (task) => {
    const nodata =
      !task ||
      !task.name ||
      !task.company ||
      !task.project ||
      !projects ||
      !companies

    if (nodata) return null

    const foundProjects = projects.filter(
      ({ name }) =>
        task.project &&
        (name.toLowerCase().includes(task.project.toLowerCase()) ||
          task.project.toLowerCase().includes(name.toLowerCase()))
    )

    // console.log('FOUND PROJECTS', foundProjects)

    const foundProject = foundProjects?.find((project) => {
      const foundCompany = companies.find(
        ({ _id }) => _id === project.company._id
      )
      // console.log('FOUND COMPANY', foundCompany)

      return (
        foundCompany &&
        (foundCompany.name.toLowerCase().includes(task.company.toLowerCase()) ||
          task.company.toLowerCase().includes(foundCompany.name.toLowerCase()))
      )
    })

    // console.log('FOUND PROJECT', foundProject)

    if (foundProjects?.length === 0)
      return toast.error('Projekt ClickUp nieodnaleziony w CITO')

    if (!foundProject) {
      toast.warn(
        'Projekt ClickUp odnaleziony w CITO, ale nie odnaleziono sprzężonej firmy (sprawdź nazwę listy w ClickUp - powinna zawierać nazwę firmy taką samą jak nazwa firmy w CITO (do znacznika " - "))'
      )
      setNewTaskValues({
        ...newTaskValues,
        project: foundProjects[0],
        title: task.name,
      })
    }

    setNewTaskValues({
      ...newTaskValues,
      project: foundProject,
      title: task.name,
    })
  }

  useEffect(() => {
    // if (!params.clickup) return null
    if (!companies || !categories || !projects) return null
    setNewTaskValues({
      ...newTaskValues,
      clickup: params.clickup,
      company: companies.find((company) => company._id === params.company),
      project: projects.find((project) => project._id === params.project),
      category: categories.find((category) => category._id === params.category),
      title: params.title,
    })
  }, [])

  useEffect(() => {
    const getToken = JSON.parse(localStorage.getItem('clickup'))
    if (getToken) setToken(getToken)
  }, [])

  useEffect(() => {
    if (!selectedProject) return null

    const foundProjectCompanyId = selectedProject?.company?._id
    const foundCompany = companies?.find(
      ({ _id }) => _id === foundProjectCompanyId
    )
    if (!foundCompany) return toast.error('Firma nieodnaleziona')

    setNewTaskValues({
      ...newTaskValues,
      company: foundCompany,
    })
  }, [selectedProject])

  return (
    <>
      <Grid
        container
        css={{
          width: '100%',
          padding: '1rem',
          justifyContent: 'center',
          fontSize: '0.75rem',
          overflow: 'hidden',
        }}
        spacing={2}
      >
        <Grid
          item
          xs={12}
          sm={6}
          lg={2}
          css={{
            letterSpacing: '-0.025em',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            position: 'relative',
            top: 0,
            left: 0,
          }}
        >
          <TextField
            fullWidth
            variant='outlined'
            label='ClickUp'
            type='text'
            size='small'
            inputProps={newTaskValues.clickup}
            value={newTaskValues.clickup}
            onChange={(e) =>
              setNewTaskValues({ ...newTaskValues, clickup: e.target.value })
            }
            css={sInput}
          />
          {newTaskValues.clickup && (
            <ClickupTrackingGetTask
              input={newTaskValues.clickup}
              token={token}
              updateInputs={clickupOverridesFields}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <Autocomplete
            value={newTaskValues.company}
            onChange={(event, newValue) => {
              setNewTaskValues({
                ...newTaskValues,
                company: newValue,
              })
            }}
            id='company'
            options={companies}
            filterOptions={(options, state) => {
              if (newTaskValues.category?.type === 'PRZERWA')
                return options.filter((option) => option.name === 'PRZERWA')
              const isProject = newTaskValues.project
              const newOptions = options.filter(
                (option) =>
                  (isProject
                    ? option?.projects?.filter(
                        (project) => project._id === isProject._id
                      ).length > 0
                    : true) &&
                  option.name
                    .toLowerCase()
                    .includes(state.inputValue?.toLowerCase())
              )
              return newOptions
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <div
                css={{
                  fontSize: '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {option.icon ? (
                  <img
                    src={imageLink(option.icon)}
                    css={{ width: 16, height: 16, marginRight: 16 }}
                    loading='lazy'
                    alt={option.name}
                  />
                ) : (
                  <div
                    css={{
                      width: 16,
                      height: 16,
                      marginRight: 16,
                      border: '1px solid black',
                    }}
                  />
                )}
                {option.name}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                label='Company'
                placeholder='Company'
                required
                css={sInput}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <Autocomplete
            value={newTaskValues.project}
            onChange={(event, newValue) => {
              setNewTaskValues({
                ...newTaskValues,
                project: newValue,
              })
            }}
            id='project'
            options={projects}
            filterOptions={(options, state) => {
              const isCompany = newTaskValues.company
              const newOptions = options.filter(
                (option) =>
                  (isCompany ? option.company._id === isCompany._id : true) &&
                  option.name
                    .toLowerCase()
                    .includes(state.inputValue?.toLowerCase())
              )
              return newOptions
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
              <div
                css={{
                  fontSize: '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  css={{
                    width: 8,
                    height: 8,
                    marginRight: 16,
                    backgroundColor: option.color || 'transparent',
                    border: option.color ? 'none' : '1px solid black',
                    transform: 'rotate(45deg)',
                  }}
                />
                {option.name}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                error={
                  newTaskValues.company?.require_projects &&
                  !newTaskValues.project
                }
                variant='outlined'
                size='small'
                label={
                  projectsInCompany >= 0
                    ? `Project (${projectsInCompany})`
                    : 'Project'
                }
                placeholder='Project '
                css={sInput}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={2}>
          <Autocomplete
            value={newTaskValues.category}
            onChange={(event, newValue) => {
              setNewTaskValues({
                ...newTaskValues,
                category: newValue,
              })
            }}
            id='category'
            options={categories}
            getOptionLabel={(option) => option?.name || ''}
            filterOptions={(options, state) => {
              if (newTaskValues.company?.name === 'PRZERWA')
                return options.filter((option) => option.type === 'PRZERWA')
              return options.filter((option) =>
                option.name
                  .toLowerCase()
                  .includes(state.inputValue?.toLowerCase())
              )
            }}
            renderOption={(option, { selected }) => (
              <div
                css={[
                  sTableCategory(option?.color),
                  {
                    width: '100%',
                  },
                ]}
              >
                {option.name}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                size='small'
                label='Category'
                placeholder='Category'
                required
                css={sInput}
              />
            )}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={2}
          css={{
            letterSpacing: '-0.025em',
            fontSize: '0.75rem',
            fontWeight: 'bold',
            // '.MuiOutlinedInput-inputMarginDense': {
            //   padding: '4.5px 4px',
            // },
          }}
        >
          {/* <Autocomplete
            freeSolo
            fullWidth
            inputValue={newTaskTitleInput}
            onInputChange={(event, newInputValue) => {
              setNewTaskTitleInput(newInputValue)
            }}
            options={activitiesTitles}
            onChange={(event, newValue) => {
              setNewTaskValues({
                ...newTaskValues,
                title: newValue,
              })
            }}
            renderOption={(option, { selected }) => (
              <div css={{ fontSize: '0.75rem' }}>{option}</div>
            )}
            renderInput={(params) => ( */}
          <TextField
            // {...params}
            fullWidth
            variant='outlined'
            label='Title'
            type='text'
            size='small'
            inputProps={
              newTaskValues.title && {
                spellCheck: 'true',
              }
            }
            required
            value={newTaskValues.title}
            onChange={(e) =>
              setNewTaskValues({ ...newTaskValues, title: e.target.value })
            }
            css={sInput}
          />
          {/* )} */}
          {/* /> */}
        </Grid>

        <Grid
          item
          xs={8}
          sm={8}
          lg={1}
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            onClick={() => {
              setLoading(true)
              onNewActivityCreate({
                data: newTaskValues,
                callback: onActionCallback,
              })
            }}
            size='large'
            variant='contained'
            disabled={
              !(
                newTaskValues.title &&
                newTaskValues.company &&
                newTaskValues.category &&
                !(
                  newTaskValues.company?.require_projects &&
                  !newTaskValues.project
                )
              ) || isLoading
            }
            css={{
              width: '100%',

              minWidth: 32,
              backgroundColor: 'green',
              color: 'white',
              fontWeight: 900,
              fontSize: '0.675rem',
              '&:hover': {
                backgroundColor: 'green',
              },
            }}
          >
            START
          </Button>
        </Grid>
        <Grid
          item
          xs={4}
          sm={4}
          lg={1}
          css={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Button
            disabled={isLoading}
            onClick={() => {
              setLoading(true)
              onNewBreakActivity({
                title: newTaskValues.title,
                callback: onActionCallback,
              })
            }}
            size='large'
            variant='contained'
            css={{
              width: '100%',
              minWidth: 32,
              fontSize: '0.675rem',
            }}
          >
            BREAK
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

const sInput = {
  input: { fontSize: '0.875rem' },
  label: { fontSize: '0.75rem' },
}

export default TrackingNew
