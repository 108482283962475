import { gql } from '@apollo/client'

export const GET_ESTIMATES = gql`
  query getEstimates(
    $dateFrom: Date
    $dateTo: Date
    $companies: [ID]
    $projects: [ID]
    $categories: [ID]
  ) {
    getEstimates(
      dateFrom: $dateFrom
      dateTo: $dateTo
      companies: $companies
      projects: $projects
      categories: $categories
    ) {
      _id
      title
      dateStart
      dateEnd
      hours
      cph
      company {
        _id
        name
        icon
        type
        color
        cph_default
      }
      category {
        _id
        name
        color
        type
      }
      project {
        _id
        name
        color
      }
      clickupTasks
      users {
        _id
        email
        firstName
        image
      }
    }
  }
`

export const GET_ESTIMATES_BY_IDS = gql`
  query getEstimatesIds($ids: [ID]) {
    getEstimatesIds(ids: $ids) {
      _id
      title
      dateStart
      dateEnd
      hours
      cph
      company {
        _id
        name
        icon
        type
        color
        cph_default
      }
      category {
        _id
        name
        color
        type
      }
      project {
        _id
        name
        color
      }
      clickupTasks
      users {
        _id
        email
        firstName
        image
      }
    }
  }
`

export const ESTIMATE_CREATE = gql`
  mutation estimateCreate($input: EstimateCreateInput!) {
    estimateCreate(input: $input) {
      _id
      title
      dateStart
      dateEnd
      hours
      cph
      company {
        _id
        name
        icon
        type
        color
      }
      category {
        _id
        name
        color
        type
      }
      project {
        _id
        name
        color
      }
      clickupTasks
      users {
        _id
        email
        firstName
        image
      }
    }
  }
`

export const ESTIMATE_UPDATE = gql`
  mutation estimateUpdate($id: ID!, $input: EstimateUpdateInput!) {
    estimateUpdate(id: $id, input: $input) {
      _id
      title
      dateStart
      dateEnd
      hours
      cph
      company {
        _id
        name
        icon
        type
        color
      }
      category {
        _id
        name
        color
        type
      }
      project {
        _id
        name
        color
      }
      clickupTasks
      users {
        _id
        email
        firstName
        image
      }
    }
  }
`

export const ESTIMATE_DELETE = gql`
  mutation estimateDelete($id: ID!) {
    estimateDelete(id: $id)
  }
`
