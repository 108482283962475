/** @jsxImportSource @emotion/react */

import { forEach } from 'lodash'
import { useState, useEffect, useCallback } from 'react'

const DashboardCompaniesLegend = ({
  data,
  selectedCompany,
  setSelectedCompany,
}) => {
  return (
    <div css={sOuter}>
      {data.map((company, id) => (
        <Company
          key={id}
          data={company}
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
        />
      ))}
    </div>
  )
}

const Company = ({ data, selectedCompany, setSelectedCompany }) => {
  const { _id, name, color } = data
  const [maxOver, setMaxOver] = useState(0)
  const isSelected = selectedCompany === _id

  const checkIfOver = useCallback((arr) => {
    const dangerousItems = arr
      .filter((item) => item?.workDone >= 80)
      .sort((a, b) => (a?.workDone > b?.workDone ? -1 : 1))

    if (dangerousItems.length > 0 && dangerousItems[0]?.workDone > maxOver)
      setMaxOver(dangerousItems[0].workDone)
    // else setMaxOver(0)
  }, [])

  useEffect(() => {
    if (!selectedCompany) setSelectedCompany(_id)
  }, [selectedCompany, setSelectedCompany, maxOver, _id])

  useEffect(() => {
    checkIfOver(data.items)
    data.items?.map((item) => checkIfOver(item?.categories))
  }, [selectedCompany, checkIfOver, data])

  return (
    <div
      onClick={() => setSelectedCompany(_id)}
      css={[
        sInner,
        { backgroundColor: color },
        maxOver >= 80 && {
          animation:
            'alert-company-oversoon-outside 0.6s linear alternate both infinite',
        },
        maxOver >= 90 && {
          animation:
            'alert-company-overwarning-outside 0.4s linear alternate both infinite',
        },
        maxOver >= 101 && {
          animation:
            'alert-company-overtime-outside 0.2s linear alternate both infinite',
        },
        !isSelected && maxOver < 80 && { opacity: 0.3 },
      ]}>
      {name}
    </div>
  )
}

const sOuter = {
  display: 'flex',
  paddingTop: '2rem',
  flexWrap: 'wrap',
}

const sInner = {
  fontSize: '0.5rem',
  fontWeight: 'bold',
  padding: '0.375rem 0.75rem',
  borderRadius: '2rem',
  color: 'white',
  margin: '0 0.25rem 0.5rem',
  cursor: 'pointer',
  transition: 'opacity 0.3s ease-out',
}

export default DashboardCompaniesLegend
