import React, { useEffect } from 'react'
import Seo from '../../components/Seo'
import { useAuth } from '../../hooks/auth.hook'
import AllTasks from './AllTasks'
import UserTasks from './UserTasks'

const Tasks = () => {
  const { userRole } = useAuth()
  const isManager = userRole >= 3

  useEffect(() => {
    localStorage.setItem('visited', new Date().toISOString().slice(0, 10))
  }, [])

  return (
    <>
      <Seo title='☑️ Tasks' />
      <UserTasks />
      {isManager && <AllTasks />}
    </>
  )
}

export default Tasks
