export const USER_STORAGE = 'user-storage'
export const INTERN = 'INTERN'
export const EXPERT = 'EXPERT'
export const MANAGER = 'MANAGER'
export const CHIEF = 'CHIEF'
export const ADMIN = 'ADMIN'
export const INACTIVE = 'INACTIVE'
export const SHIFTMANAGER = '610134798ac0ff0d54ac2dbf' // Łukasz Sierputowski

export const categoryTypes = [
  'CLIENT',
  'GRAFIKA',
  'GOOGLE',
  'SOCIAL',
  'HR',
  'IT',
  'MANAGEMENT',
  'MANUAL',
  'OFFICE',
  'OTHER',
  'PLAN',
  'PROGRESS',
  'PRZERWA',
  'RAPORT',
  'VIDEO',
  'TALK',
]

export const companyTypes = ['INTERNAL', 'EXTERNAL', 'OTHER', 'RDBTECHNICS']

export const roleTypes = [INACTIVE, INTERN, EXPERT, MANAGER, CHIEF, ADMIN]

export const SHIFTS_PREDEFINED = [
  'Dyżur Standardowy',
  'Blaty i górne półki',
  'Półki',
  'Śmieci',
  'Zmywarka',
  'Odkurzanie',
  'Zmywanie podłóg',
  'Sprzątanie kuchni',
  'Lista zakupów',
  'Weryfikacja czystości',
]
