/** @jsxImportSource @emotion/react */

import _ from 'lodash'
import { Pie } from 'react-chartjs-2'
import { alpha } from '../../style'
import { hoursToTime } from '../../utils/hoursToTime'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const DashboardChart = ({ data, setSelectedCompany }) => {
  const allMinutes = data.reduce((sum, curr) => curr.minutes + sum, 0)

  const companyHours = _(data)
    .groupBy('company.name')
    .map((objs, key) => {
      const sumMins = _.sumBy(objs, 'minutes')
      return {
        company: key,
        hours: sumMins / 60,
        minutes: sumMins,
        color: objs[0].company.color,
        id: objs[0].company._id,
      }
    })
    .sortBy(['minutes', 'company'])
    .reverse()
    .value()

  const chartCompanyHours = {
    labels: companyHours.map((item) => item.company),
    datasets: [
      {
        index: companyHours.map((item) => item.id),
        id: companyHours.map((item) => item.id),
        animation: false,
        label: 'Company',
        data: companyHours.map((item) => item.hours),
        backgroundColor: companyHours.map((item) =>
          item.color.concat(alpha[100])
        ),
        borderColor: 'transparent',
        // borderColor: companyHours.map((item) => item.color),
        datalabels: {
          color: '#ffffff',
          index: companyHours.map((item) => item.id),
        },
        dataIndex: companyHours.map((item) => item.id),
      },
    ],
  }

  const chartOptions = {
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
        padding: 20,
        position: 'bottom',
        labels: {
          boxWidth: 10,
          padding: 16,
          font: {
            size: 10,
          },
        },
      },
      datalabels: {
        font: {
          weight: 'bolder',
          size: 12,
        },
        formatter: function (value, context) {
          // return context.chart.data.labels[context.dataIndex]
          return value.toFixed(1) + 'h'
        },
        // textShadowColor: '#000000'.concat(alpha[100]),
        // textShadowBlur: 20,
        textStrokeColor: '#000000'.concat(alpha[100]),
        textStrokeWidth: 3,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            // console.log(context)
            let label = context.label
            let hours = context.raw
            return `${label}: ${hoursToTime(hours, 'HH:MM')} (${(
              ((context.raw * 60) / allMinutes) *
              100
            ).toFixed(1)}%)`
          },
        },
      },
    },
  }

  const getElementAtEvent = (e) => {
    if (!e.length) return

    const { index, element } = e[0]
    setSelectedCompany(element.$datalabels[0].$context.dataset.id[index])
  }

  return (
    <Pie
      data={chartCompanyHours}
      options={chartOptions}
      plugins={[ChartDataLabels]}
      getElementAtEvent={getElementAtEvent}
    />
  )
}

export default DashboardChart
