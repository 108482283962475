// const timeStart = '13:50'
// const timeEnd = '20:12'

export const strToMin = (str) => {
  const splt = str.split(':')
  let hours = parseInt(splt[0])
  let minutes = parseInt(splt[1])
  if (hours < 0) hours = 0
  if (hours > 23) hours = 23
  if (minutes < 0) minutes = 0
  if (minutes > 59) minutes = 59
  return hours * 60 + minutes
}

// console.log(strToMin(timeStart))
