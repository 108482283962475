/** @jsxImportSource @emotion/react */

import _ from 'lodash'
import { useState, useEffect } from 'react'
import { getToday } from '../../utils/getToday'
import { useQuery } from '@apollo/client'
import { Skeleton } from '@material-ui/lab'
import { GET_ACTIVITIES } from '../../graphql/activity.graphql'
import ActivitiesInputs from '../Activities/ActivitiesInputs'
import { toast } from 'react-toastify'
import { timeDiff } from '../../utils/timeDiff'
import { Grid, FormControlLabel, Switch } from '@material-ui/core'
import ActivityUserCard from '../../components/ActivityUserCard'
import PageWrapper from '../../components/PageWrapper'
import { colors } from '../../style'

const TeamActivities = ({ now, hideInputs }) => {
  const today = getToday(new Date())

  const initialValues = {
    authors: [],
    dateFrom: today,
    dateTo: today,
  }

  const [inputValues, setInputValues] = useState(initialValues)
  const [isPolling, setIsPolling] = useState(false)

  const {
    data: activitiesData,
    loading: activitiesLoading,
    error: activitiesError,
    startPolling,
    stopPolling,
  } = useQuery(GET_ACTIVITIES, {
    variables: inputValues,
  })

  useEffect(() => {
    if (isPolling) startPolling(5 * 60 * 1000)
    // 15 min
    else stopPolling()
    return () => {
      stopPolling()
    }
  }, [isPolling, startPolling, stopPolling])

  if (activitiesLoading)
    return (
      <div css={sOuter}>
        {' '}
        <Skeleton variant='rect' height={300} css={{ width: '100%' }} />
      </div>
    )

  if (activitiesError) {
    toast.error(activitiesError.toString())
    return <div css={sOuter}>Error</div>
  }

  let activities

  activities = activitiesData?.getActivities?.filter((activity) =>
    activity.timeEnd && activity.minutes < 1 ? false : true
  )

  activities = activities.map((activity) => ({
    ...activity,
    minutes:
      !activity.minutes && now
        ? timeDiff(activity.timeStart, now)
        : activity.minutes,
  }))

  const data = _(activities)
    .groupBy('author._id')
    .map((objs, key) => {
      const h_activities = _.sumBy(objs, 'minutes') / 60

      const l_activities = objs.filter(
        (obj) => obj.__typename === 'Activity'
      ).length

      const shine = objs.filter((obj) => !obj.timeEnd).length > 0

      const categories = _(objs)
        .groupBy('category.name')
        .map((cats, id) => {
          const categoryH = _.sumBy(cats, 'minutes') / 60
          const categoryWD = (categoryH / h_activities) * 100

          const shine = cats.filter((obj) => !obj.timeEnd).length > 0

          const companies = _(cats)
            .groupBy('company.name')
            .map((comps, id) => {
              const companyH = _.sumBy(comps, 'minutes') / 60
              const companyWD = (companyH / categoryH) * 100

              const shine = comps.filter((obj) => !obj.timeEnd).length > 0

              const projects = _(comps)
                .groupBy('project.name')
                .map((projs, id) => {
                  const projectH = _.sumBy(projs, 'minutes') / 60
                  const projectWD = (projectH / companyH) * 100

                  const shine = comps.filter((obj) => !obj.timeEnd).length > 0

                  // console.log(athrs)
                  return {
                    _id: projs[0].project?._id,
                    name: projs[0].project?.name || 'Bez projektu',
                    color: projs[0].project?.color || colors.gray600,
                    h_activities: projectH,
                    workDone: projectWD,
                    tasks: projs,
                    shine,
                  }
                })
                .sortBy(['h_activities', 'name'])
                .reverse()
                .value()

              return {
                _id: comps[0].company._id,
                name: id,
                color: comps[0].company.color,
                icon: comps[0].company.icon,
                h_activities: companyH,
                workDone: companyWD,
                projects,

                shine,
              }
            })
            .sortBy(['h_activities', 'name'])
            .reverse()
            .value()

          return {
            _id: cats[0].category._id,
            name: id,
            color: cats[0].category.color,
            h_activities: categoryH,
            workDone: categoryWD,
            companies,
            shine,
          }
        })
        .sortBy(['h_activities', 'name'])
        .reverse()
        .value()

      const companies = _(objs)
        .groupBy('company.name')
        .map((comps, id) => {
          const companyH = _.sumBy(comps, 'minutes') / 60
          const companyWD = (companyH / h_activities) * 100

          const shine = comps.filter((obj) => !obj.timeEnd).length > 0

          const projects = _(comps)
            .groupBy('project.name')
            .map((projs, id) => {
              const projectH = _.sumBy(projs, 'minutes') / 60
              const projectWD = (projectH / companyH) * 100

              const shine = projs.filter((obj) => !obj.timeEnd).length > 0

              const categories = _(projs)
                .groupBy('category.name')
                .map((cats, id) => {
                  const categoryH = _.sumBy(cats, 'minutes') / 60
                  const categoryWD = (categoryH / projectH) * 100

                  const shine = cats.filter((obj) => !obj.timeEnd).length > 0
                  // console.log(athrs)
                  return {
                    _id: cats[0].category._id,
                    name: cats[0].category.name,
                    color: cats[0].category.color,
                    h_activities: categoryH,
                    workDone: categoryWD,
                    tasks: cats,
                    shine,
                  }
                })
                .sortBy(['h_activities', 'name'])
                .reverse()
                .value()

              return {
                _id: projs[0].project?._id,
                name: projs[0].project?.name || 'Bez projektu',
                color: projs[0].project?.color || colors.gray600,
                h_activities: projectH,
                workDone: projectWD,
                categories,
                shine,
              }
            })
            .sortBy(['h_activities', 'name'])
            .reverse()
            .value()

          return {
            _id: comps[0].company._id,
            name: id,
            color: comps[0].company.color,
            icon: comps[0].company.icon,
            h_activities: companyH,
            workDone: companyWD,
            projects,
            shine,
          }
        })
        .sortBy(['h_activities', 'name'])
        .reverse()
        .value()

      // const projects = _(objs)
      //   .groupBy('project.name')
      //   .map((projs, id) => {
      //     const projectH = _.sumBy(projs, 'minutes') / 60
      //     const projectWD = (projectH / h_activities) * 100

      //     const shine = projs.filter((obj) => !obj.timeEnd).length > 0

      //     const categories = _(projs)
      //       .groupBy('category.name')
      //       .map((cats, id) => {
      //         const categoryH = _.sumBy(cats, 'minutes') / 60
      //         const categoryWD = (categoryH / projectH) * 100

      //         const shine = cats.filter((obj) => !obj.timeEnd).length > 0
      //         // console.log(athrs)
      //         return {
      //           _id: cats[0].category._id,
      //           name: cats[0].category.name,
      //           color: cats[0].category.color,
      //           h_activities: categoryH,
      //           workDone: categoryWD,
      //           tasks: cats,
      //           shine,
      //         }
      //       })
      //       .sortBy(['h_activities', 'name'])
      //       .reverse()
      //       .value()

      //     return {
      //       _id: projs[0].project?._id,
      //       name: id,
      //       color: projs[0].project?.color,
      //       h_activities: projectH,
      //       workDone: projectWD,
      //       categories,
      //       shine,
      //     }
      //   })
      //   .sortBy(['h_activities', 'name'])
      //   .reverse()
      //   .value()

      const current = objs.find((activity) => !activity.timeEnd)

      return {
        _id: objs[0].author._id,
        firstName: objs[0].author.firstName,
        lastName: objs[0].author.lastName,
        title: objs[0].author.title,
        color: objs[0].author.color,
        image: objs[0].author.image,
        h_activities,
        l_activities,
        role: objs[0].author.role,
        categories,
        companies,
        shine,
        current,
      }
    })
    .sortBy(['h_activities', 'name'])
    .reverse()
    .value()

  // console.log(data)

  return (
    <>
      {!hideInputs && (
        <ActivitiesInputs
          inputValues={inputValues}
          setInputValues={setInputValues}
          loading={activitiesLoading}
          initialValues={initialValues}
          isTeam
        />
      )}

      {!hideInputs && (
        <PageWrapper>
          <div
            css={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => setIsPolling(!isPolling)}
          >
            <FormControlLabel
              control={<Switch checked={isPolling} />}
              css={{ marginRight: 0 }}
            />
            <span css={sSwithLabel}>POLLING 5 MIN</span>
          </div>
        </PageWrapper>
      )}
      <Grid container spacing={3}>
        {data.map((user) => (
          <ActivityUserCard data={user} key={user._id} now={now} />
        ))}
      </Grid>
    </>
  )
}

const sOuter = {
  width: '100%',
  paddingTop: '2rem',
  '.MuiDataGrid-cell': { fontSize: '0.75rem' },
}

const sPage = {
  padding: '0 0 4rem',
}

const sSwithLabel = {
  marginLeft: '0rem',
  marginRight: '2rem',
  fontSize: '0.75rem',
  textTransform: 'uppercase',
  opacity: 0.5,
  letterSpacing: '0.1em',
}

export default TeamActivities
