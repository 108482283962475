import { gql } from '@apollo/client'

export const GET_SHIFTS = gql`
  query getShifts($users: [ID], $dateFrom: Date, $dateTo: Date) {
    getShifts(users: $users, dateFrom: $dateFrom, dateTo: $dateTo) {
      _id
      date
      isDone
      info
      user {
        _id
        firstName
        lastName
        color
        image
      }
    }
  }
`

export const SHIFT_CREATE = gql`
  mutation shiftCreate($input: ShiftCreateInput!) {
    shiftCreate(input: $input) {
      _id
      date
      isDone
      info
      user {
        _id
        firstName
        lastName
        color
        image
      }
    }
  }
`

export const SHIFT_UPDATE = gql`
  mutation shiftUpdate($input: ShiftUpdateInput!, $id: ID!) {
    shiftUpdate(input: $input, id: $id) {
      _id
      date
      info
      isDone
      user {
        _id
        firstName
        lastName
        color
        image
      }
    }
  }
`

export const SHIFT_TOGGLE_DONE = gql`
  mutation shiftToggleDone($id: ID!) {
    shiftToggleDone(id: $id) {
      _id
      isDone
    }
  }
`
