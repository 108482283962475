import { useState, useCallback, useEffect } from 'react'
import { USER_STORAGE } from '../constants'

export const useAuth = () => {
  const [token, setToken] = useState(null)
  const [userId, setUserId] = useState(null)
  const [userRole, setUserRole] = useState(0)
  const [userName, setUserName] = useState('')
  const [ready, setReady] = useState(false)

  // console.log('auth.hook.js', userId)

  const login = useCallback((token, userId, userRole, timestamp, userName) => {
    // console.log('login')

    const userData = JSON.parse(localStorage.getItem(USER_STORAGE))

    localStorage.setItem(
      USER_STORAGE,
      JSON.stringify({
        token,
        userId,
        userRole,
        timestamp,
        userName,
      })
    )

    if (!userData) {
      // console.log('no storage data, userid:', userId)
      window.location.reload(true)
    }

    setToken(token)
    setUserId(userId)
    setUserRole(userRole)
    setUserName(userName)
  }, [])

  const logout = useCallback(() => {
    // console.log('logout')
    setToken(null)
    setUserId(null)
    setUserRole(0)
    setUserName('')

    localStorage.clear()
  }, [])

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem(USER_STORAGE))
    const now = new Date().getTime()
    const timeExpired = 13 * 24 * 60 * 60 * 1000 // 14 days

    if (userData && userData.token) {
      // console.log('time expired check')
      if (
        !userData.timestamp ||
        now - parseInt(userData.timestamp) >= timeExpired
      ) {
        logout()
        setReady(true)
        return null
      }
      if (userData.token)
        login(
          userData.token,
          userData.userId,
          userData.userRole,
          userData.timestamp,
          userData.userName
        )
    }
    setReady(true)
  }, [login, logout])

  return {
    login,
    logout,
    token,
    userId,
    userRole,
    ready,
    userName,
  }
}
