/** @jsxImportSource @emotion/react */

import { useState, useRef } from 'react'
import _ from 'lodash'

import { useQuery, useMutation } from '@apollo/client'
import {
  GET_CATEGORIES,
  CATEGORY_UPDATE,
  CATEGORY_CREATE,
} from '../../graphql/category.graphql'
import { toast } from 'react-toastify'
import {
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import Skeleton from '@material-ui/lab/Skeleton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { categoryTypes } from '../../constants'

const SettingsCategories = () => {
  const { data, loading, error } = useQuery(GET_CATEGORIES)
  const [categoryCreate] = useMutation(CATEGORY_CREATE)
  const [categoryUpdate] = useMutation(CATEGORY_UPDATE)
  const [open, setOpen] = useState(false)
  const tableRef = useRef(null)

  const scrollToBottom = () => {
    tableRef.current?.scrollTo({
      top: tableRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  if (loading)
    return <Skeleton variant='rect' height={300} css={{ width: '100%' }} />

  if (error) {
    toast.error(error.toString())
    return <div>{error.toString()}</div>
  }

  const onUpdateSubmit = ({ id, input, callback }) => {
    categoryUpdate({
      variables: { id, input: _.pickBy(_.omit(input, ['__typename', '_id'])) },
      refetchQueries: [{ query: GET_CATEGORIES }],
    })
      .then((res) => {
        // console.log(res.data)
        if (res.data.categoryUpdate)
          toast.info(
            <div>
              <strong>
                #️⃣&nbsp;&nbsp;<span>UPDATED</span>
              </strong>
              <p>
                <strong
                  css={[
                    sTableCategory(input.color),
                    {
                      backgroundColor: input?.color,
                      fontWeight: 'bold',
                      color: 'white',
                    },
                  ]}>
                  {input.name}
                </strong>
              </p>
            </div>,
            { autoClose: 10000 }
          )
        callback()
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  const onCreateSubmit = ({ input, callback }) => {
    categoryCreate({
      variables: { input: _.pickBy(input) },
      // refetchQueries: [{ query: GET_CATEGORIES }],
      update: (cache, { data }) => {
        const categoriesCache = cache.readQuery({
          query: GET_CATEGORIES,
        })
        cache.writeQuery({
          query: GET_CATEGORIES,
          data: {
            getCategories: [
              ...categoriesCache?.getCategories,
              data?.categoryCreate,
            ],
          },
        })
      },
    })
      .then((res) => {
        if (res.data.categoryUpdate)
          toast.success(
            <div>
              <strong>
                #️⃣&nbsp;&nbsp;<span>CREATED</span>
              </strong>
              <p>
                <strong
                  css={[
                    sTableCategory(input.color),
                    {
                      backgroundColor: input?.color,
                      fontWeight: 'bold',
                      color: 'white',
                    },
                  ]}>
                  {input.name}
                </strong>
              </p>
            </div>,
            { autoClose: 10000 }
          )
        callback()
        scrollToBottom()
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  return (
    <>
      <TableContainer
        component={Paper}
        css={{ maxHeight: '50vh' }}
        ref={tableRef}>
        <Table stickyHeader aria-label='collapsible table' size='small'>
          <TableHead
            css={{
              th: { fontSize: '0.75rem', color: 'rgba(0,0,0,0.5)' },
            }}>
            <TableRow>
              <TableCell css={{ paddingLeft: '3rem' }}>Category</TableCell>
              <TableCell align='left'>Type</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {data.getCategories.map((row, id) => (
              <Row key={row._id} data={row} onSubmit={onUpdateSubmit} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper>
        <Collapse in={open}>
          <Form
            open={open}
            setOpen={setOpen}
            onSubmit={onCreateSubmit}
            button={{ title: 'CREATE' }}
          />
        </Collapse>
      </Paper>
      <Button
        css={{ marginTop: '1rem' }}
        size='small'
        onClick={() => setOpen(!open)}
        color={open ? 'default' : 'primary'}
        variant='contained'>
        {!open ? 'NEW CATEGORY' : 'CANCEL CREATION'}
      </Button>
    </>
  )
}

const Row = ({ data, onSubmit }) => {
  const [open, setOpen] = useState(false)
  const { name, type, color, isHidden } = data

  return (
    <>
      <TableRow
        css={[
          isHidden && { opacity: 0.25 },
          {
            td: { fontSize: '0.75rem' },
            '&:hover': {
              backgroundColor: '#fcfcfc',
            },
          },
        ]}>
        <TableCell component='th' scope='row'>
          <div css={[sTableCategory(color), { width: '100%' }]}>{name}</div>
        </TableCell>
        <TableCell align='left' css={{ opacity: 0.5 }}>
          {type}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}>
            {open ? <CloseIcon /> : <EditIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow css={{ backgroundColor: '#f8f8f8', width: '100%' }}>
        <TableCell css={{ width: '100%', padding: 0, margin: 0 }} colSpan={10}>
          <Collapse
            in={open}
            timeout='auto'
            unmountOnExit
            css={{ width: '100%' }}>
            <Form
              open={open}
              setOpen={setOpen}
              data={data}
              onSubmit={onSubmit}
              button={{ title: 'UPDATE' }}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const Form = ({ onSubmit, open, setOpen, data, button }) => {
  const initialValues = {
    name: '',
    type: 'OTHER',
    color: '#' + Math.floor(Math.random() * 16777215).toString(16),
    isHidden: false,
  }

  const [inputValues, setInputValues] = useState(
    open ? data || initialValues : initialValues
  )
  const handleOnChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  return (
    <Grid
      container
      css={{
        width: '100%',
        padding: '1rem',
        justifyContent: 'space-between',
      }}
      spacing={2}>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='name'
          label='Name'
          type='text'
          required
          value={inputValues.name}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Autocomplete
          fullWidth
          value={inputValues.type}
          options={categoryTypes}
          onChange={(event, newValue) => {
            setInputValues({
              ...inputValues,
              type: newValue,
            })
          }}
          renderOption={(option, { selected }) => (
            <div css={{ fontSize: '0.75rem' }}>{option}</div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              variant='outlined'
              label='Type'
              type='text'
              size='small'
              css={sInput}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          size='small'
          variant='outlined'
          name='color'
          label='Color'
          type='color'
          value={inputValues.color}
          onChange={handleOnChange}
          css={sInput}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={2}>
        <FormControlLabel
          css={{ span: { fontSize: '0.75rem' } }}
          control={
            <>
              <Switch
                name='isHidden'
                checked={inputValues.isHidden}
                onChange={() =>
                  setInputValues({
                    ...inputValues,
                    isHidden: !inputValues.isHidden,
                  })
                }
                color='primary'
              />
            </>
          }
          label=''
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={1}>
        <Button
          onClick={() =>
            onSubmit({ id: data?._id, input: inputValues, callback: setOpen })
          }
          size='small'
          variant='contained'
          disabled={!inputValues.name}
          css={{
            width: '100%',
            backgroundColor: 'green',
            color: 'white',
            fontSize: '0.75rem',
            '&:hover': {
              backgroundColor: 'green',
            },
          }}>
          {button.title}
        </Button>
      </Grid>
    </Grid>
  )
}

const sInput = {
  input: { fontSize: '0.75rem' },
  label: { fontSize: '0.75rem' },
}

const sTableCategory = (color) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: color.concat('40') || 'rgba(0,0,0,0.25)',
  padding: '0.25rem 0.5rem',
  borderRadius: '0.25rem',
  fontSize: '0.675rem',
  fontWeight: 'bold',
  color: color.concat('80') || 'rgba(0,0,0,1)',
  textShadow: '0px 0px 0px rgba(0,0,0,0.5)',
})

export default SettingsCategories
