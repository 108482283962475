/** @jsxImportSource @emotion/react */

import packageJson from '../../package.json'
import { Link, useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { IconButton } from '@material-ui/core'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import { s } from '../style'
import { updates } from '../views/Version/Version'

const VersionFixed = () => {
  const [close, setClose] = useState(true)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname !== '/track') setClose(false)
  }, [location])

  return (
    <>
      <div
        css={[
          close && { display: 'none' },
          {
            position: 'fixed',
            bottom: 0,
            left: 0,
            zIndex: 100,
            [s.xs]: { position: 'relative' },
          },
        ]}>
        <div css={sVersion}>
          <div css={{ marginRight: '2rem' }}>
            {process.env.NODE_ENV === 'production' ? `✨` : `⚙️`} &nbsp;
            <strong>
              {process.env.REACT_APP_NAME.replace('&nbsp;', ' ')}{' '}
              {updates[0].version}&nbsp;{updates[0].subtitle}&nbsp;
            </strong>
            <span>| {updates[0].date}</span>
          </div>
          <div
            css={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginRight: '1rem',
              [s.sm_down]: {
                marginTop: '1rem',
              },
              [s.xs]: { display: 'none' },
            }}>
            <Link to='/version' css={sLink}>
              Sprawdź Zmiany!
            </Link>
            <Link to='/version?docs=true' css={sLink}>
              Dokumentacja
            </Link>
          </div>
          <IconButton
            onClick={() => setClose(true)}
            css={{
              position: 'absolute',
              top: '1rem',
              right: '1rem',
              color: 'white',
              padding: 0,
              opacity: 1,
              [s.md]: {
                top: '1rem',
                right: '2rem',
              },
            }}>
            <HighlightOffIcon fontSize='small' />
          </IconButton>
        </div>
      </div>
    </>
  )
}

const sVersion = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: 'white',
  backgroundColor: 'black',
  fontSize: '10px',
  padding: '1rem 2rem',
  position: 'relative',
  bottom: 0,
  left: 0,
  width: '100vw',
  height: '100%',
  lineHeight: '1.6em',
  zIndex: 100,
  // borderRadius: '0.5rem 0.5rem 0 0',
  [s.sm_down]: {
    padding: '1rem',
  },

  span: {
    opacity: 0.4,
  },
}

const sLink = {
  cursor: 'pointer',
  transition: 'color 0.2s ease-out, background-color 0.2s ease-out',
  border: '2px solid white',
  padding: '3px 0.5rem',
  borderRadius: '6px',
  fontWeight: 900,
  fontSize: '0.5rem',
  textTransform: 'uppercase',
  marginRight: '1rem',
  '&:hover': {
    backgroundColor: 'white',
    color: 'black',
  },
}

export default VersionFixed
