/** @jsxImportSource @emotion/react */

// Utils
import { imageLink } from '../../utils/imageLink'

// Material UI
import { TableRow, TableCell, Button, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

// Styles
import { sTableCategory } from './Tracking'
import ClickupIcon from '../../assets/ClickupIcon'

import { Link, useHistory } from 'react-router-dom'

const TrackingRow = ({
  currentActivity,
  setEditActivity,
  onStopActivity,
  onRepeatActivity,
  row,
  isLoading,
  setLoading,
}) => {
  const {
    title,
    company,
    project,
    category,
    date,
    timeStart,
    timeEnd,
    minutes,
    _id: rowId,
    clickup,
  } = row

  const history = useHistory()
  const isBreak = company?.name === 'PRZERWA'

  return (
    // <div css={{ position: 'relative', top: 0, left: 0, width: 'max-content', height: 'max-content' }}>
    <>
      <TableRow
        // prettier-ignore
        css={[
          sTableRow,
          currentActivity?._id === rowId && sRowActive, row.hasGap && sRowHasGap, row.hasOverlap && sRowHasOverlap
        ]}>
        <TableCell align='left'>
          <div
            css={[
              {
                display: 'flex',
                alignItems: 'center',
              },
              isBreak && {
                opacity: 0.2,
                fontWeight: 'bold',
                fontSize: '0.75rem',
              },
            ]}>
            {company.icon ? (
              <img
                src={imageLink(company.icon)}
                css={[
                  { width: 16, height: 16, marginRight: 16 },
                  isBreak && {
                    width: 12,
                    height: 12,
                    marginRight: 18,
                    marginLeft: 2,
                  },
                ]}
                loading='lazy'
                alt={company.name}
              />
            ) : (
              <div
                css={{
                  width: 16,
                  height: 16,
                  marginRight: 16,
                  backgroundColor: 'white',
                }}></div>
            )}
            {isBreak ? 'Przerwa' : company?.name}
          </div>
        </TableCell>
        <TableCell align='left'>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            {project && (
              <div
                css={{
                  width: 8,
                  height: 8,
                  marginRight: 16,
                  backgroundColor: project?.color || 'rgba(0,0,0,0.2)',
                  transform: 'rotate(45deg)',
                }}
              />
            )}
            {project?.name}
          </div>
        </TableCell>
        <TableCell align='left'>
          <div css={sTableCategory(category?.color)}>{category?.name}</div>
        </TableCell>
        <TableCell
          css={{
            letterSpacing: '-0.025em',
            fontSize: '0.75rem',
            fontWeight: 'bold',
          }}>
          {title}
          {/* <span css={{ fontSize: 8 }}>{rowId}</span> */}
        </TableCell>
        <TableCell align='center'>
          <div css={{ opacity: 0.25 }}>{date?.slice(0, 10)}</div>
        </TableCell>
        <TableCell align='center' css={{ position: 'relative' }}>
          {timeStart}
          {timeEnd && (
            <Button
              disabled={isLoading}
              className='repeatActivity'
              onClick={() => {
                setLoading(true)
                onRepeatActivity({
                  id: rowId,
                })
              }}
              size='small'
              variant='contained'
              css={{
                // transition: 'opacity 0.15s ease-out',
                opacity: 0,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%,-50%)',
                minWidth: 32,
                backgroundColor: 'green',
                color: 'white',
                fontSize: '0.675rem',
                // [s.lg]: {
                //   left: '100%',
                // },
                '&:hover': {
                  backgroundColor: 'green',
                },
              }}>
              START
            </Button>
          )}
        </TableCell>
        <TableCell align='center'>
          {timeEnd || (
            <Button
              disabled={isLoading}
              onClick={() => {
                setLoading(true)
                onStopActivity({
                  id: rowId,
                  data: row,
                })
              }}
              size='small'
              variant='contained'
              css={{
                minWidth: 32,
                backgroundColor: 'red',
                color: 'white',
                fontSize: '0.675rem',
                '&:hover': {
                  backgroundColor: '#f44336',
                },
              }}>
              STOP
            </Button>
          )}
        </TableCell>
        <TableCell align='right'>
          {minutes >= 60 ? (
            <>
              <strong>{Math.floor(minutes / 60)}</strong>
              <span css={sTableTimeUnit}>h&nbsp;</span>
              <strong>{minutes % 60}</strong>
              <span css={sTableTimeUnit}>m</span>
            </>
          ) : minutes > 0 ? (
            <>
              <strong>{minutes}</strong>
              <span css={sTableTimeUnit}>m</span>
            </>
          ) : (
            <strong>-</strong>
          )}
        </TableCell>
        <TableCell align='left' css={{ button: { margin: '0 0.5rem' } }}>
          <Link to={`/track?id=${rowId}`}>
            <IconButton
              // onClick={() => history.push(`/track&id=${rowId}`)}
              size='small'>
              <OpenInNewIcon fontSize='inherit' />
            </IconButton>{' '}
          </Link>
          <IconButton onClick={() => setEditActivity(rowId)} size='small'>
            <EditIcon fontSize='inherit' />
          </IconButton>
          {clickup ? (
            <a
              href={`clickup://t/${clickup.replace('#', '')}`}
              target='_blank'
              rel='noreferrer noopener'>
              <IconButton
                // onClick={() => window.open(`https://app.clickup.com/t/${clickup.replace('#', '')}`, '_blank')}
                css={{ svg: { width: '0.875rem', height: '0.875rem' } }}
                size='small'>
                <ClickupIcon />
              </IconButton>
            </a>
          ) : (
            <IconButton
              disabled
              // onClick={() => window.open(`https://app.clickup.com/t/${clickup.replace('#', '')}`, '_blank')}
              css={{
                svg: { width: '0.875rem', height: '0.875rem', opacity: 0.5 },
              }}
              size='small'>
              <ClickupIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

const sTableTimeUnit = {
  opacity: 0.5,
}

export const sTableRow = {
  position: 'relative',
  top: 0,
  left: 0,
  height: 'max-content',
  width: 'max-content',
  td: { fontSize: '0.75rem', border: 'none', th: { border: 'none' } },
  boxShadow: `inset 0px 0px 0px 1px rgb( 0 0 0 / 10%)`,

  display: 'table-row',
  '&:hover': {
    backgroundColor: 'hsl(0deg 0% 96%)',
    '.repeatActivity': {
      opacity: 1,
    },
  },
}

// const sAbsolute = {
//   position: 'absolute',
//   top: 0,
//   left: 0,
//   width: '100%',
//   height: '100%',
//   transform: 'translate3d(0,0,0)',
// }

export const sRowActive = {
  boxShadow: `
  inset 4px 0px 0px 0px hsl(212deg 100% 50%), 
  inset -8px 0px 0px 0px hsl(212deg 100% 50%), 
  inset 0px 0px 0px 1px hsl(212deg 100% 50% / 25%)
  `,
  // background: 'linear-gradient(to right, hsl(212deg 100% 96% / 0%), hsl(212deg 100% 50% / 16%))',
  background: 'hsl(212deg 100% 50% / 16%)',
  backgroundSize: '50% 100%',
  backgroundRepeat: 'no-repeat',
}

export const sRowHasGap = {
  boxShadow: `
  inset 4px 0px 0px 0px hsl(52deg 100% 50%), 
  inset -8px 0px 0px 0px hsl(52deg 100% 50%), 
  inset 0px 0px 0px 1px hsl(52deg 100% 50% / 25%)
  `,
  // background: 'linear-gradient(to right, hsl(52deg 100% 96% / 0%), hsl(52deg 100% 50% / 16%))',
  background: 'hsl(52deg 100% 50% / 16%)',
}

export const sRowHasOverlap = {
  boxShadow: `
  inset 4px 0px 0px 0px hsl(0deg 100% 50%), 
  inset -8px 0px 0px 0px hsl(0deg 100% 50%), 
  inset 0px 0px 0px 1px hsl(0deg 100% 50% / 10%)
  `,
  // background: 'linear-gradient(to right, hsl(0deg 100% 96% / 0%), hsl(0deg 100% 50% / 16%))',
  background: 'hsl(0deg 100% 50% / 16%)',
}

export default TrackingRow
