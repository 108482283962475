/** @jsxImportSource @emotion/react */

import { Avatar, Collapse, Grid, IconButton, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import { alpha, colors, s } from '../../style'
import moment from 'moment'
import ColorLine from '../../components/ColorLine'
import _ from 'lodash'
import { ItemAuthors } from '../../components/EstimateCard'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import { useUIContext } from '../../context/ui.context'
import { hoursToTime } from '../../utils/hoursToTime'
import { useAuth } from '../../hooks/auth.hook'
import { imageLink } from '../../utils/imageLink'
import { Link } from 'react-router-dom'
import { sNow } from '../../components/ActivityUserCard'

const TaskContainer = ({ data }) => {
  const {
    _id,
    name,
    description,
    priority,
    status,
    estimate,
    activities,
    start_date,
    due_date,
    // time_spent,
    // time_estimate,
  } = data

  const { userRole } = useAuth()
  const isChief = userRole >= 4

  const { setEditEstimateOpen } = useUIContext()

  const estims = estimate.hours
  const activs = activities.reduce((sum, curr) => sum + curr.minutes, 0) / 60

  const workDone = estims ? (activs / estims) * 100 : 0

  const [open, setOpen] = useState(false)
  const [isOvertime, setIsOvertime] = useState(workDone >= 101)
  const [isOverWarning, setIsOverWarning] = useState(workDone >= 90)
  const [isOverSoon, setIsOverSoon] = useState(workDone >= 80)

  const isActivityNow = activities.reduce(
    (sum, curr) => sum || !curr.timeEnd,
    false
  )

  const authors = _(activities)
    .groupBy('author._id')
    .map((athrs, id) => {
      const aAh = _.sumBy(athrs, 'minutes') / 60 || 0
      const aWorkDone = (aAh / estims) * 100
      // console.log(athrs)

      const shine =
        athrs.filter((obj) => obj.__typename === 'Activity' && !obj.timeEnd)
          .length > 0

      return {
        name:
          athrs[0]?.author?.firstName +
          ' ' +
          (athrs[0]?.author?.lastName ? athrs[0]?.author?.lastName : ''),

        color: athrs[0]?.author?.color,
        image: athrs[0]?.author?.image,
        h_activities: aAh,
        workDone: aWorkDone,
        tasks: athrs,
        shine,
      }
    })
    .sortBy(['workDone', 'h_activities', 'name'])
    .reverse()
    .value()

  // console.log(authors)

  return (
    <Grid item xs={12} lg={6} css={sTaskOuter}>
      <Paper elevation={0} css={sPaper}>
        <div
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            width: '100%',
            [s.xs]: {
              flexDirection: 'column',
            },
          }}
        >
          <div
            css={{
              display: 'flex',
              [s.xs]: {
                marginBottom: '1rem',
              },
            }}
          >
            <div css={{ display: 'flex' }}>
              <div css={{ position: 'relative', top: 0, left: 0 }}>
                <div
                  css={[
                    sStatus,
                    { backgroundColor: status?.color },
                    status?.status === 'pending' && {
                      color: colors.black.concat(alpha[60]),
                    },
                  ]}
                >
                  {status?.status}
                </div>
                <Link
                  className='task-track'
                  to={`/tracking?clickup=${_id}&company=${estimate.company._id}&project=${estimate.project._id}&category=${estimate.category._id}&title=${name}`}
                  css={[sStatus, { backgroundColor: colors.green }]}
                >
                  TRACK
                </Link>
              </div>
            </div>
            {priority?.priority && (
              <div
                css={{
                  margin: '0 0.5rem',
                  color: priority.color,
                  alignItems: 'center',
                  display: 'flex',
                }}
              >
                <svg
                  width='24'
                  height='24'
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 20 20'
                  fill='currentColor'
                >
                  <path
                    fillRule='evenodd'
                    d='M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z'
                    clipRule='evenodd'
                  />
                </svg>
                {/* <span css={{ fontSize: '0.75rem', fontWeight: 'bold' }}>
                  {priority.priority}
                </span> */}
              </div>
            )}
          </div>
          <div
            css={[
              {
                display: 'flex',
                alignItems: 'center',
                backgroundColor: colors.gray400,
                padding: '0.25rem',
                borderRadius: '0.25rem',
                color: colors.white,
              },
              moment
                .unix(parseInt(due_date) / 1000)
                .diff(moment.now(), 'days') < 0 && {
                backgroundColor: 'red',
              },
            ]}
          >
            <span css={sDate}>
              {moment.unix(parseInt(start_date) / 1000).format('DD MMMM HH:HH')}
            </span>
            <svg
              css={{ margin: '0 0.25rem' }}
              width='12'
              height='12'
              xmlns='http://www.w3.org/2000/svg'
              className='h-5 w-5'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
            <span css={[sDate]}>
              {moment.unix(parseInt(due_date) / 1000).format('DD MMMM HH:HH')}
            </span>
          </div>
        </div>
        <a href={`clickup://t/${_id}`} css={sTitle}>
          {name}
        </a>
        {sDescription && (
          <p
            css={[
              sDescription,
              !open && {
                display: '-webkit-box',
                WebkitLineClamp: 1,
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              },
            ]}
          >
            {description}
          </p>
        )}
        <ColorLine
          onClick={() => setOpen(!open)}
          extraCss={{ width: '100%', marginTop: '1rem', cursor: 'pointer' }}
          color={estimate.company.color}
          percentage={workDone}
          isOvertime={isOvertime}
          isOverWarning={isOverWarning}
          isOverSoon={isOverSoon}
          shine={isActivityNow}
          lightBack
          title={
            <>
              {estimate.company.icon ? (
                <img
                  src={imageLink(estimate.company.icon)}
                  css={sIcon}
                  alt={name}
                />
              ) : (
                <Avatar
                  css={{
                    width: 12,
                    height: 12,
                    fontSize: 12,
                    marginRight: '1rem',
                    backgroundColor: estimate.company.color || '#ffffff',
                  }}
                >
                  {estimate.company.name.split(' ')?.[0]?.[0]}
                  {estimate.company.name.split(' ')?.[1]?.[0]}
                </Avatar>
              )}
              {estimate.company.name} | {estimate.project.name} |{' '}
              {estimate.category.name}
            </>
          }
        >
          <div css={{ display: 'flex', alignItems: 'center' }}>
            {isActivityNow && (
              <div
                css={[sNow(estimate.company.color), !open && { opacity: 1 }]}
              />
            )}
            <div css={isChief && { marginRight: '2rem' }}>
              {hoursToTime(activs, 'HH:MM')} / {hoursToTime(estims, 'HH:MM')}
              &nbsp;&nbsp;
              {workDone.toFixed(0)}%
            </div>
            {isChief && (
              <IconButton
                size='small'
                aria-label='edit'
                onClick={() =>
                  setEditEstimateOpen({ estimateIds: [estimate._id] })
                }
                css={{
                  position: 'absolute',
                  top: '50%',
                  right: 0,
                  transform: 'translate(-8px, -50%)',
                  fontSize: '0.875rem',
                  opacity: 0.5,
                  color: estimate.company.color,
                  '&:hover': {
                    opacity: 1,
                  },
                }}
              >
                <AccessTimeIcon fontSize='inherit' />
              </IconButton>
            )}
          </div>
        </ColorLine>
        {open && (
          <Collapse in={open} css={{ width: '100%' }}>
            <div css={[{ paddingTop: '1rem' }]} />
            <Grid container spacing={1}>
              {authors.map((author, id) => (
                <ItemAuthors
                  key={id}
                  data={author}
                  typename={'User'}
                  item_activities={activs}
                  useWorkDonePercentage
                />
              ))}
            </Grid>
          </Collapse>
        )}
        {/* <div>
          ClickUp Time - spent: {time_spent / 1000 / 60} | estimated :{' '}
          {time_estimate}
        </div> */}
      </Paper>
    </Grid>
  )
}

const sStatus = {
  fontSize: '0.675rem',
  borderRadius: '0.25rem',
  padding: '0.5rem 1rem',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  color: colors.white,
}

const sTaskOuter = {
  padding: '1rem',
  // cursor: 'pointer',
}

const sPaper = {
  background: 'white',
  padding: '1rem',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  flexDirection: 'column',

  [s.hover]: {
    '.task-track': {
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
      opacity: 0,
      width: '100%',
      textAlign: 'center',
      transition: 'opacity 0.15s ease-out',
    },
    '&:hover': {
      '.task-track': {
        opacity: 1,
      },
    },
  },
}

const sIcon = {
  width: 12,
  height: 12,
  marginRight: '0.5rem',
}

const sTitle = {
  margin: '1rem 0 0',
  fontSize: '1.25rem',
  fontWeight: 'bold',
  letterSpacing: '-0.05em',
  '&:hover': {
    textDecoration: 'underline',
  },
}

const sDescription = {
  marginTop: '0.25rem',
  fontSize: '0.75rem',
  opacity: 0.6,
  flexWrap: 'wrap',
  display: 'flex',
  wordBreak: 'break-word',
}

const sDate = {
  fontSize: '0.675rem',
  color: colors.white,
  fontWeight: 'bold',
  letterSpacing: '-0.05em',
}

export default TaskContainer
