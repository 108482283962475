/** @jsxImportSource @emotion/react */

import { useState, useRef } from 'react'
import _ from 'lodash'
import { FormControlLabel, Switch, Grid, Button } from '@material-ui/core'
import { Pie, Doughnut, PolarArea, Bar } from 'react-chartjs-2'
import { companyTypeToColor, categoryTypeToColor } from '../../utils'
import { hoursToTime } from '../../utils/hoursToTime'
import { alpha } from '../../style'
import PDF from 'react-to-pdf'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const ActivitiesCharts = ({ data }) => {
  const [isBars, setIsBar] = useState(false)
  const [isLabels, setIsLabels] = useState(false)
  const [formatTime, setFormatTime] = useState('HH:MM')
  const refChartCompany = useRef(null)
  const refChartCompanyType = useRef(null)
  const refChartCategory = useRef(null)
  const refChartCategoryType = useRef(null)
  const refChartEmployee = useRef(null)
  const refChartProject = useRef(null)

  if (!data || data.length <= 0) return null

  const allMinutes = data.reduce((sum, curr) => curr.minutes + sum, 0)

  const companyHours = _(data)
    .groupBy('company.name')
    .map((objs, key) => {
      const sumMins = _.sumBy(objs, 'minutes')
      return {
        company: key,
        hours: sumMins / 60,
        minutes: sumMins,
        color: objs[0].company.color,
      }
    })
    .sortBy(['minutes', 'company'])
    .reverse()
    .value()

  // const getCategories = (data) =>
  //   _(data)
  //     .groupBy('category.name')
  //     .map((objs, key) => ({
  //       category: key,
  //       color: objs[0]?.category.color,
  //       minutes: _.sumBy(objs, 'minutes'),
  //     }))
  //     .value()

  const companyTypeHours = _(data)
    .groupBy('company.type')
    .map((objs, key) => {
      const sumMins = _.sumBy(objs, 'minutes')
      return {
        companyType: key,
        hours: sumMins / 60,
        minutes: sumMins,
        color: companyTypeToColor(objs[0]?.company.type),
      }
    })
    .sortBy(['minutes', 'companyType'])
    .reverse()
    .value()

  const categoryHours = _(data)
    .groupBy('category.name')
    .map((objs, key) => {
      const sumMins = _.sumBy(objs, 'minutes')
      return {
        category: key,
        color: objs[0]?.category.color,
        hours: sumMins / 60,
        minutes: sumMins,
      }
    })
    .sortBy(['minutes', 'category'])
    .reverse()
    .value()

  const categoryTypeHours = _(data)
    .groupBy('category.type')
    .map((objs, key) => {
      const sumMins = _.sumBy(objs, 'minutes')
      return {
        categoryType: key,
        hours: sumMins / 60,
        minutes: sumMins,
        color: categoryTypeToColor(key),
      }
    })
    .sortBy(['minutes', 'categoryType'])
    .reverse()
    .value()

  const userHours = _(data)
    .groupBy('author._id')
    .map((objs, key) => {
      const sumMins = _.sumBy(objs, 'minutes')
      return {
        name: objs[0].author.firstName + ' ' + objs[0].author.lastName,
        hours: sumMins / 60,
        minutes: sumMins,
        color: objs[0].author.color,
      }
    })
    .sortBy(['minutes', 'name'])
    .reverse()
    .value()

  // const companyCategoryHours = _(data)
  //   .groupBy('company.name')
  //   .map((objs, key) => ({
  //     company: key,
  //     color: objs[0]?.company.color,
  //     categories: getCategories(objs),
  //   }))
  //   .value()

  const projectHours = _(data)
    .groupBy('project.name')
    .map((objs, key) => {
      const sumMins = _.sumBy(objs, 'minutes')
      return {
        project: key,
        hours: sumMins / 60,
        minutes: sumMins,
        color: objs[0].project?.color || '#ffffff',
      }
    })
    .sortBy(['minutes', 'project'])
    .reverse()
    .value()

  const chartCompanyHours = {
    labels: companyHours.map((item) => item.company),
    datasets: [
      {
        animation: false,
        label: 'Company',
        data: companyHours.map((item) => item.hours),
        backgroundColor: companyHours.map((item) =>
          item.color.concat(alpha[100])
        ),
        borderColor: 'transparent',
        // borderColor: companyHours.map((item) => item.color),
        datalabels: {
          color: '#ffffff',
        },
      },
    ],
  }

  const chartCompanyTypeHours = {
    labels: companyTypeHours.map((item) => item.companyType),
    datasets: [
      {
        animation: false,
        label: 'CompanyType',
        data: companyTypeHours.map((item) => item.hours),
        backgroundColor: companyTypeHours.map((item) =>
          item.color.concat(alpha[100])
        ),
        // borderColor: companyTypeHours.map((item) => item.color),
        borderColor: 'transparent',
        datalabels: {
          color: '#ffffff',
        },
      },
    ],
  }

  const chartCategoryHours = {
    labels: categoryHours.map((item) => item.category),
    datasets: [
      {
        animation: false,
        label: 'Category',
        data: categoryHours.map((item) => item.hours),
        backgroundColor: categoryHours.map((item) =>
          isBars && item.category === 'Przerwa'
            ? item.color.concat(alpha[100])
            : item.color.concat(alpha[25])
        ),
        borderColor: categoryHours.map((item) => item.color),

        datalabels: {
          color: categoryHours.map((item) => item.color),
        },
      },
    ],
  }

  const chartCategoryTypeHours = {
    labels: categoryTypeHours.map((item) => item.categoryType),
    datasets: [
      {
        animation: false,
        label: 'CategoryType',
        data: categoryTypeHours.map((item) => item.hours),
        backgroundColor: categoryTypeHours.map((item) =>
          isBars && item.categoryType === 'PRZERWA'
            ? item.color.concat(alpha[100])
            : item.color.concat(alpha[25])
        ),
        borderColor: categoryTypeHours.map((item) => item.color),
        datalabels: {
          color: categoryTypeHours.map((item) => item.color),
        },
      },
    ],
  }

  const chartUserHours = {
    labels: userHours.map((item) => item.name),
    datasets: [
      {
        animation: false,
        label: 'User',
        data: userHours.map((item) => item.hours),
        backgroundColor: userHours.map((item) => item.color.concat(alpha[25])),
        datalabels: {
          color: userHours.map((item) => item.color),
        },
      },
    ],
  }

  const chartProjectHours = {
    labels: projectHours.map((item) => item.project),
    datasets: [
      {
        animation: false,
        label: 'Project',
        data: projectHours.map((item) => item.hours),
        backgroundColor: projectHours.map((item) => item.color),
        datalabels: {
          color: projectHours.map((item) => item.color),
        },
      },
    ],
  }

  const chartOptions = {
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        padding: 20,
        position: 'bottom',
        labels: {
          boxWidth: 10,
          padding: 16,
          font: {
            size: 10,
          },
        },
      },
      datalabels: {
        font: {
          weight: 'bolder',
          size: 12,
        },
        formatter: function (value, context) {
          // return context.chart.data.labels[context.dataIndex]
          return isLabels ? value.toFixed(1) + 'h' : ''
        },
        // textShadowColor: '#000000'.concat(alpha[100]),
        // textShadowBlur: 20,
        textStrokeColor: '#000000'.concat(alpha[100]),
        textStrokeWidth: 3,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            // console.log(context)
            let label = context.label
            let hours = context.raw
            return `${label}: ${
              formatTime === 'HH:MM'
                ? hoursToTime(hours, 'HH:MM')
                : hours.toFixed(1) + ' h'
            } (${(((context.raw * 60) / allMinutes) * 100).toFixed(1)}%)`
          },
        },
      },
    },
  }

  return (
    <>
      <FormControlLabel
        control={<Switch checked={isBars} onChange={() => setIsBar(!isBars)} />}
        label='Słupki'
        css={{ margin: '0 2rem' }}
      />
      <FormControlLabel
        control={
          <Switch checked={isLabels} onChange={() => setIsLabels(!isLabels)} />
        }
        label='Oznaczenia'
        css={{ margin: '2rem' }}
      />
      <FormControlLabel
        control={
          <Switch
            checked={formatTime}
            onChange={() => setFormatTime(!formatTime ? 'HH:MM' : '')}
          />
        }
        label={`Format ${formatTime ? formatTime : 'Decimal'}`}
        css={{ margin: '2rem' }}
      />
      <Grid container justifyContent='space-evenly' spacing={6} css={sCharts}>
        <Grid
          item
          xs={12}
          md={isBars ? 12 : 6}
          lg={isBars ? 12 : 6}
          xl={isBars ? 12 : 5}
          ref={refChartCompany}>
          <h2>Company</h2>
          {isBars ? (
            <Bar
              data={chartCompanyHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Pie
              data={chartCompanyHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          )}
          <ExportPDF name='Company' targetRef={refChartCompany} data={data} />
        </Grid>
        <Grid
          item
          xs={12}
          md={isBars ? 12 : 6}
          lg={isBars ? 12 : 6}
          xl={isBars ? 12 : 5}
          ref={refChartCompanyType}>
          <h2>Company Type</h2>
          {isBars ? (
            <Bar
              data={chartCompanyTypeHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Pie
              data={chartCompanyTypeHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          )}
          <ExportPDF
            name='CompanyType'
            targetRef={refChartCompanyType}
            data={data}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={isBars ? 12 : 6}
          lg={isBars ? 12 : 6}
          xl={isBars ? 12 : 5}
          ref={refChartCategory}>
          <h2>Category</h2>
          {isBars ? (
            <Bar
              data={chartCategoryHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Doughnut
              data={chartCategoryHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          )}
          <ExportPDF name='Category' targetRef={refChartCategory} data={data} />
        </Grid>
        <Grid
          item
          xs={12}
          md={isBars ? 12 : 6}
          lg={isBars ? 12 : 6}
          xl={isBars ? 12 : 5}
          ref={refChartCategoryType}>
          <h2>Category Type</h2>
          {isBars ? (
            <Bar
              data={chartCategoryTypeHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Doughnut
              data={chartCategoryTypeHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          )}
          <ExportPDF
            name='CategoryType'
            targetRef={refChartCategoryType}
            data={data}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={isBars ? 12 : 6}
          lg={isBars ? 12 : 6}
          xl={isBars ? 12 : 5}
          ref={refChartEmployee}>
          <h2>Employee</h2>
          {isBars ? (
            <Bar
              data={chartUserHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <PolarArea
              data={chartUserHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          )}
          <ExportPDF name='Employee' targetRef={refChartEmployee} data={data} />
        </Grid>
        <Grid
          item
          xs={12}
          md={isBars ? 12 : 6}
          lg={isBars ? 12 : 6}
          xl={isBars ? 12 : 5}
          ref={refChartProject}>
          <h2>Project</h2>
          {isBars ? (
            <Bar
              data={chartProjectHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Pie
              data={chartProjectHours}
              options={chartOptions}
              plugins={[ChartDataLabels]}
            />
          )}
          <ExportPDF name='Project' targetRef={refChartProject} data={data} />
        </Grid>
      </Grid>
    </>
  )
}

const ExportPDF = ({ targetRef, name, data }) => (
  <PDF
    targetRef={targetRef}
    filename={
      data[0].company.name?.trim() +
      ' - ' +
      data[0].date?.slice(0, 10) +
      ' - ' +
      data[data.length - 1].date?.slice(0, 10) +
      ' - ' +
      name +
      'Chart.pdf'
    }>
    {({ toPdf }) => (
      <Button
        size='small'
        variant='text'
        onClick={toPdf}
        css={{ color: 'rgb(0 0 0 /25%)' }}>
        GENERATE PDF
      </Button>
    )}
  </PDF>
)

const sCharts = {
  h2: {
    fontSize: '0.875rem',
    padding: '4rem 0 2rem',
    textAlign: 'center',
    fontWeight: 300,
    // letterSpacing: '0.5em',
    // textTransform: 'uppercase',
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default ActivitiesCharts
