/** @jsxImportSource @emotion/react */

// import { useState } from 'react'
// import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContext } from '../context/auth.context'
import { useQuery } from '@apollo/client'
import { GET_USER } from '../graphql/user.graphql'
import { toast } from 'react-toastify'
import { roleValueToName, roleToColor } from '../utils'
import { Avatar, Button, Grid, Tooltip } from '@material-ui/core'
import { imageLink } from '../utils/imageLink'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { s } from '../style'

const UserInfo = ({ collapsed }) => {
  // const [open, setOpen] = useState(null)
  const { userId, logout } = useContext(AuthContext)
  const { data, loading, error } = useQuery(GET_USER, {
    variables: { id: userId },
    skip: !userId,
  })
  const handleUserLogout = ({ text }) => {
    logout()
    toast.dark(
      <div>
        <strong>Wylogowano {text}</strong>
        <br />
        <span>Do zobaczenia wkrótce!</span>✌
      </div>,
      { autoClose: false }
    )
  }

  if (!userId)
    return (
      <div css={[sUserInfo]}>
        <Button onClick={() => handleUserLogout({ text: '' })} variant='outlined' size='small'>
          Logout
        </Button>
      </div>
    )
  if (loading) return <div />
  if (error) {
    toast.error(error.toString())

    return (
      <div css={[sUserInfo]}>
        <Button onClick={() => handleUserLogout({ text: '' })} variant='outlined' size='small'>
          Logout
        </Button>
      </div>
    )
  }

  const { firstName, lastName, image, title, role, email, color } = data.getUser

  return (
    <div css={sUserInfo}>
      <div
        css={{ display: 'flex', flexShrink: 0 }}
        // onClick={(e) => setOpen(e.currentTarget)}
      >
        {image ? (
          <img css={sImage} src={imageLink(image)} loading='lazy' alt={firstName + ' ' + lastName} />
        ) : (
          <Avatar css={[sImage, { backgroundColor: color }]}>
            {firstName[0]}
            {lastName?.[0]}
          </Avatar>
        )}
        <div css={[sDetail, collapsed && { opacity: 0 }]}>
          <p css={sName}>
            <span>
              {firstName} {lastName}
            </span>
            <span css={[sRole, { backgroundColor: roleToColor(role) }]}>{roleValueToName(role)}</span>
          </p>

          <p css={sTitle}>{title || email}</p>
        </div>
      </div>
      <Grid container justifyContent='flex-start' alignItems='center'>
        <Grid xs={3} item>
          <Tooltip title='Logout'>
            <div css={[sLogoutButton]} onClick={() => handleUserLogout({ text: firstName + ' ' + lastName })}>
              <ExitToAppIcon fontSize='small' css={{ [s.smd_down]: { marginRight: '1rem' } }} />
              <div css={{ [s.md]: { display: 'none' } }}>LOGOUT</div>
            </div>
          </Tooltip>
        </Grid>
        {/* <Grid xs={3} item>
          <Tooltip title='Account'>
            <Link to='/account' css={[sLogoutButton]}>
              <ExitToAppIcon fontSize='small' />
            </Link>
          </Tooltip>
        </Grid> */}
      </Grid>
    </div>
  )
}

const sUserInfo = {
  display: 'flex',
  flexDirection: 'column',
  justifySelf: 'flex-end',
  alignSelg: 'flex-end',
  justifyContent: 'space-between',
  backgroundColor: '#b7b7b7',
  padding: '1.5rem 2rem 5rem',
  borderRadius: '0.5rem 0.5rem 0 0',
  margin: '2rem 0rem 0',
  height: 'max-content',
  width: 300,
  [s.sm_down]: {
    width: '100vw',
    padding: '1.5rem 1rem 8rem',
  },
}

const sDetail = {
  transition: 'opacity 0.15s ease-out',
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '1rem',
  width: '100%',
  paddingBottom: '2rem',
}

const sName = {
  display: 'flex',
  fontSize: '0.75rem',
  fontWeight: 900,
  alignItems: 'center',
  lineHeight: '1em',
  justifyContent: 'space-between',
  width: '100%',
}

const sTitle = {
  fontSize: '0.75rem',
  // fontStyle: 'italic',
  opacity: 0.6,
}

const sImage = {
  width: 48,
  height: 48,
  borderRadius: '50%',
  flexShrink: 0,
}

const sRole = {
  padding: '0.25rem 0.5rem',
  borderRadius: '0.25rem',
  fontSize: 9,
  fontWeight: 'bold',
  backgroundColor: '#007aff',
  color: 'white',
}

const sLogoutButton = {
  width: 48,
  transition: 'color 0.15s ease-out, background-color 0.15s ease-out, width 0.3s ease-out',
  cursor: 'pointer',
  backgroundColor: 'rgba(0,0,0,0.15)',
  height: 32,
  borderRadius: 4,
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '0.75rem',
  letterSpacing: '0.5em',
  textTransform: 'uppercase',
  fontWeight: 700,
  padding: '0.875rem',
  [s.sm_down]: {
    width: 'calc(100vw - 2rem)',
  },
  span: {
    opacity: 0,
    width: '100%',
    textAlign: 'center',
    transition: 'opacity 0.15s ease-out',
    margin: '0 1rem',
  },
  '&:hover': {
    backgroundColor: 'rgba(0,0,0,1)',
    color: 'white',
    span: {
      opacity: 1,
    },
  },
}

export default UserInfo
