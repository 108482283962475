/** @jsxImportSource @emotion/react */

import _ from 'lodash'
import { alpha } from '../../style'
import { imageLink } from '../../utils/imageLink'
import { minutesToTime } from '../../utils/minutesToTime'
import { Tooltip } from '@material-ui/core'
import { companyTypeToColor } from '../../utils'

const TrackingAreas = ({ data, group, groupKey, withBorder }) => {
  if (!data || data.length <= 0) return <></>

  const allMinutes = data.reduce((acc, curr) => curr.minutes + acc, 0)

  const aggregatedData = _(data)
    .groupBy(`${group}.${groupKey}`)
    .map((objs, key) => ({
      name: key,
      icon: groupKey !== 'type' && objs[0][group].icon,
      color:
        groupKey === 'type'
          ? companyTypeToColor(objs[0][group].type)
          : objs[0][group].color,
      hours: _.sumBy(objs, 'minutes') / 60,
      minutes: _.sumBy(objs, 'minutes'),
    }))
    .orderBy('minutes', 'desc')
    .value()

  return (
    <div
      css={{
        borderRadius: 6,
        overflow: 'hidden',
        width: '100%',
        height: 50,
        display: 'flex',
        flexWrap: 'nowrap',
        boxShadow: '0px 0px 0px rgb(0 0 0 /8%)',
      }}>
      {aggregatedData.map(({ name, minutes, color, ...item }, id) => {
        const percent = (minutes / allMinutes) * 100
        return (
          <Tooltip
            key={id}
            title={
              name +
              ': ' +
              minutesToTime(minutes, 'HH:MM') +
              ' | ' +
              percent.toFixed(1) +
              '%'
            }>
            <div
              css={[
                {
                  position: 'relative',
                  transition: 'all 0.6s ease-out',
                  height: '100%',
                  width: percent + '%',
                  // backgroundColor: color?.concat(alpha[withBorder ? 25 : 50]),
                  background: `linear-gradient(to right bottom, ${color.concat(
                    alpha[25]
                  )}, ${color.concat(alpha[50])})`,
                },
                withBorder && { border: `2px solid ${color}` },
                color?.toLowerCase() === '#ffffff' && {
                  background: `linear-gradient(to right bottom, ${color.concat(
                    alpha[60]
                  )}, ${color.concat(alpha[100])})`,
                },
              ]}>
              {item.icon ? (
                <img
                  alt={name}
                  src={imageLink(item.icon)}
                  css={{
                    width: 16,
                    height: 16,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                  }}
                />
              ) : (
                <span
                  css={{
                    fontSize: '0.675rem',
                    fontWeight: 'bold',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: color.concat([50]),
                    textShadow: '0px 0px 0px rgba(0,0,0,0.5)',
                  }}>
                  {percent >= 5 && name}
                </span>
              )}
            </div>
          </Tooltip>
        )
      })}
    </div>
  )
}

export default TrackingAreas
