import { useMutation, useQuery } from '@apollo/client'
import { useEffect, useState, useCallback } from 'react'
import { toast } from 'react-toastify'
import { useAuthContext } from '../context/auth.context'
import { GET_SHIFTS, SHIFT_TOGGLE_DONE } from '../graphql/shifts.graphql'
import { useAuth } from './auth.hook'

export const useShifts = () => {
  const [hasShift, setHasShift] = useState(null)
  // const [userId, setUserId] = useState(null)
  const { userName, userId } = useAuth()
  const today = new Date().toISOString().slice(0, 10)
  const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    .toISOString()
    .slice(0, 10)

  const [shiftToggleDone] = useMutation(SHIFT_TOGGLE_DONE)

  // const { userName: userNameuseAuthContext } = useAuthContext()
  // console.log('userNameuseAuthContext', userNameuseAuthContext)
  // console.log('userNameuseAuth', userNameuseAuth)

  const { data, loading, error, refetch } = useQuery(GET_SHIFTS, {
    variables: { dateFrom: today, dateTo: tomorrow },
  })

  const findAndSetShift = (id) => {
    const shift = data?.getShifts?.find((shift) => shift.user._id === id)
    const isToday = shift?.date.slice(0, 10) === today
    setHasShift(isToday ? shift : null)
    if (shift && isToday && !hasShift && !shift.isDone)
      toast.warning(
        <div>
          <strong>
            {userName && userName.split(' ')[0] + '. '}Dziś twój dyżur 🧹
          </strong>
          <br />
          <span>Powodzenia {shift?.info && `z ${shift.info}`} </span>
        </div>,
        { autoClose: false }
      )
  }

  const onCompleteShift = (id) => {
    // console.log(`Click toggle ${id}`)
    shiftToggleDone({
      variables: { id },
      refetchQueries: [
        { query: GET_SHIFTS, variables: { dateFrom: today, dateTo: tomorrow } },
      ],
    })
      .then((res) => {
        // console.log(res.data.shiftToggleDone)
        if (res.data.shiftToggleDone)
          setHasShift({ ...hasShift, isDone: !hasShift.isDone })
      })
      .catch((error) => toast.error(error.toString()))
  }

  useEffect(() => {
    refetch()
  }, [userId, refetch])

  useEffect(() => {
    findAndSetShift(userId)
  }, [data, userId])

  return { hasShift, onCompleteShift }
}
