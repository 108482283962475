/** @jsxImportSource @emotion/react */

import { Grid, Paper } from '@material-ui/core'
import { alpha, s, colors } from '../../style'
import { minutesToTime } from '../../utils/minutesToTime'

const ActivityDetails = ({ data, currentMinutes, days, users, minimal }) => {
  if (!data || data.length <= 0)
    return (
      <div css={{ fontSize: '0.75rem', opacity: 0.5 }}>Brak aktywności</div>
    )

  const activitiesCount = data.length

  const totalMinutes = data.reduce((acc, curr) => curr.minutes + acc, 0)
  const totalHours = (totalMinutes / 60).toFixed(2)
  const totalBreakMinutes = data.reduce(
    (acc, curr) =>
      curr.category.type === 'PRZERWA' ? curr.minutes + acc : acc,
    0
  )
  // const totalBreakHours = (totalBreakMinutes / 60).toFixed(2)
  const workDay = ((totalMinutes / (days * 8 * 60)) * 100) / users
  const workDayMinutesLeft = (8 * 60 - (workDay / 100) * 8 * 60) * users
  const workDayHoursLeft = workDayMinutesLeft / 60

  return (
    <Paper
      elevation={0}
      css={{
        position: 'relative',
        backgroundColor: '#b8d9ff'.concat(alpha[16]),
      }}>
      <div css={sDetailsWrapper(workDay, workDayHoursLeft)} />
      <Grid
        container
        css={[
          sDetailContainer,
          // minimal && {
          //   strong: {
          //     color: colors.blue.concat(alpha[60]),
          //     textShadow: '0px 0px 0px rgba(0,0,0,0.5)',
          //   },
          // },
        ]}>
        {!minimal && (
          <Grid item xs={12} sm={4} lg={2}>
            <p>Activities</p>
            <strong>{activitiesCount}</strong>
            <div>
              {days > 1 && (
                <span>{(activitiesCount / days).toFixed(0)} /day</span>
              )}
              {users > 1 && (
                <span> | {(activitiesCount / users).toFixed(0)} /user </span>
              )}
              {days === 1 && users === 1 && <div css={{ opacity: 0 }}>.</div>}
            </div>
          </Grid>
        )}
        {!minimal && (
          <Grid item xs={12} sm={4} lg={2}>
            <p>Average {(days > 1 || users > 1) && 'Work'}</p>
            {users > 1 ? (
              <>
                <strong>
                  {(totalMinutes / users / days / 60).toFixed(1)} h
                </strong>

                <span>
                  {(totalMinutes / activitiesCount).toFixed(0)} min/task
                </span>
              </>
            ) : (
              <>
                <strong>{(totalMinutes / activitiesCount).toFixed(0)}</strong>
                <div>
                  <span>min/task</span>
                  {days > 1 && (
                    <span> | {(totalHours / days).toFixed(2)} h/day</span>
                  )}
                </div>
              </>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm={4} lg={2}>
          <p>Time</p>
          <strong>{minutesToTime(totalMinutes, 'HH:MM')}</strong>
          <span>
            {/* {totalHours} h |{' '} */}
            {totalMinutes} mins
          </span>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <p>Breaks</p>
          <strong>{minutesToTime(totalBreakMinutes, 'HH:MM')}</strong>
          <div>
            <span>
              {((totalBreakMinutes / totalMinutes) * 100).toFixed(0)}%
            </span>
            {/* {days > 1 && (
              <span> | {(totalBreakMinutes / days).toFixed(0)} min/day</span>
            )} */}
            {users > 1 && (
              <span>
                {' '}
                | {(totalBreakMinutes / users / days).toFixed(0)} min/user
              </span>
            )}
          </div>
        </Grid>
        {!minimal && (
          <Grid item xs={12} sm={4} lg={2}>
            <p>Current</p>
            <strong>{minutesToTime(currentMinutes, 'HH:MM')}</strong>
            <span>{users} users</span>
          </Grid>
        )}
        <Grid item xs={12} sm={4} lg={2}>
          <p>Workday</p>
          <strong>{workDay.toFixed(0)}%</strong>
          <span
            css={
              workDayMinutesLeft < 0 && {
                color: 'red',
                fontWeight: 'bold !important',
                opacity: '1 !important',
              }
            }>
            {workDayMinutesLeft <= 60 && workDayMinutesLeft >= -60
              ? workDayMinutesLeft.toFixed(0) + ' mins'
              : workDayHoursLeft.toFixed(1) + ' h'}
            {workDayMinutesLeft >= 0 ? ' left' : ' (overtime)'}
          </span>
        </Grid>
      </Grid>
    </Paper>
  )
}

export const sDetailsWrapper = (workDay, workDayMinutesLeft) => [
  {
    width: workDay + '%',
    maxWidth: '100%',
    maxHeight: '100%',
    height: '100%',
    background: `linear-gradient(to right, ${'#b8d9ff'.concat(
      alpha[30]
    )}, ${'#b8d9ff'})`,
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 1,
    borderRadius: 'inherit',
    opacity: 1,
    [s.sm_down]: {
      top: 'auto',
      bottom: 0,
      width: '100%',
      height: workDay + '%',
      background: `linear-gradient(to top, ${'#b8d9ff'.concat(
        alpha[30]
      )}, ${'#b8d9ff'})`,
    },
  },
  workDayMinutesLeft < 0 && {
    background: `linear-gradient(to right, ${'#ffb8b8'.concat(
      alpha[30]
    )}, ${'#ffb8b8'})`,
    [s.sm_down]: {
      background: `linear-gradient(to top, ${'#ffb8b8'.concat(
        alpha[30]
      )}, ${'#ffb8b8'})`,
    },
  },
]

export const sDetailContainer = {
  padding: '1rem',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  span: {
    opacity: 0.5,
    fontWeight: 'normal',
    fontSize: '0.875rem',
  },
  strong: {
    fontSize: '2rem',
    [s.xs]: {
      fontSize: '3rem',
      letterSpacing: '-0.05em',
    },
  },
  p: {
    textAlign: 'center',
    fontSize: '0.75rem',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    opacity: 0.5,
  },
  'span, strong, p': {
    position: 'relative',
    top: 0,
    left: 0,
    zIndex: 2,
  },
  '& > div': {
    position: 'relative',
    padding: '1rem 0',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '100%',
    [s.sm_down]: {
      padding: '2rem 0',
    },
  },
}

export default ActivityDetails
