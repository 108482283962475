/** @jsxImportSource @emotion/react */

import { useState, useRef } from 'react'
import _ from 'lodash'
import {
  FormControlLabel,
  Switch,
  Grid,
  Button,
  Paper,
} from '@material-ui/core'
import { Pie, Doughnut, Bar } from 'react-chartjs-2'
import { companyTypeToColor, categoryTypeToColor } from '../../utils'
import { alpha } from '../../style'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const EstimatesSimpleCharts = ({ activities, estimates }) => {
  const [isBars, setIsBar] = useState(false)
  const [isLabels, setIsLabels] = useState(false)

  const refActivitiesCompany = useRef(null)
  const refEstimatesCompany = useRef(null)
  const refActivitiesCategory = useRef(null)
  const refEstimatesCategory = useRef(null)

  const allActivitiesMinutes = activities.reduce(
    (sum, curr) => curr.minutes + sum,
    0
  )
  const allActivitiesHours = (allActivitiesMinutes / 60).toFixed(2)
  const allEstimatesHours = estimates.reduce((sum, curr) => curr.hours + sum, 0)

  const workDone = ((allActivitiesHours / allEstimatesHours) * 100).toFixed(2)
  const workLeft = allEstimatesHours - allActivitiesHours

  const activitiesCompanyData = _(activities)
    .groupBy('company.name')
    .map((objs, key) => {
      const sumMins = _.sumBy(objs, 'minutes')
      return {
        name: key,
        hours: (sumMins / 60).toFixed(2),
        color: objs[0].company.color,
      }
    })
    .sortBy(['hours', 'name'])
    .reverse()
    .value()

  const estimatesCompanyData = _(estimates)
    .groupBy('company.name')
    .map((objs, key) => {
      const sumHours = _.sumBy(objs, 'hours')
      return {
        name: key,
        hours: sumHours,
        color: objs[0].company.color,
      }
    })
    .sortBy(['hours', 'name'])
    .reverse()
    .value()

  const activitiesCategoryData = _(activities)
    .groupBy('category.name')
    .map((objs, key) => {
      const sumMins = _.sumBy(objs, 'minutes')
      return {
        name: key,
        color: objs[0]?.category ? objs[0]?.category.color : '#a1a1a1',
        hours: (sumMins / 60).toFixed(2),
      }
    })
    .sortBy(['hours', 'name'])
    .reverse()
    .value()

  const estimatesCategoryData = _(estimates)
    .groupBy('category.name')
    .map((objs, key) => {
      const sumHours = _.sumBy(objs, 'hours')
      return {
        name: key,
        hours: sumHours,
        color: objs[0]?.category ? objs[0]?.category.color : '#a1a1a1',
      }
    })
    .sortBy(['hours', 'name'])
    .reverse()
    .value()

  const cActivitiesCompany = {
    labels: activitiesCompanyData.map((item) => item.name),
    datasets: [
      {
        animation: false,
        label: 'Company A',
        data: activitiesCompanyData.map((item) => item.hours),
        backgroundColor: activitiesCompanyData.map((item) =>
          item.color.concat(alpha[100])
        ),
        borderColor: 'transparent',
        datalabels: {
          color: '#ffffff',
        },
      },
    ],
  }

  const cEstimatesCompany = {
    labels: estimatesCompanyData.map((item) => item.name),
    datasets: [
      {
        animation: false,
        label: 'Company E',
        data: estimatesCompanyData.map((item) => item.hours),
        backgroundColor: estimatesCompanyData.map((item) =>
          item.color.concat(alpha[100])
        ),
        borderColor: 'transparent',
        datalabels: {
          color: '#ffffff',
        },
      },
    ],
  }

  const cActivitiesCategory = {
    labels: activitiesCategoryData.map((item) => item.name),
    datasets: [
      {
        animation: false,
        label: 'Category A',
        data: activitiesCategoryData.map((item) => item.hours),
        backgroundColor: activitiesCategoryData.map((item) =>
          isBars && item.category === 'Przerwa'
            ? item.color.concat(alpha[100])
            : item.color.concat(alpha[25])
        ),
        borderColor: activitiesCategoryData.map((item) => item.color),

        datalabels: {
          color: activitiesCategoryData.map((item) => item.color),
        },
      },
    ],
  }

  const cEstimatesCategory = {
    labels: estimatesCategoryData.map((item) => item.name),
    datasets: [
      {
        animation: false,
        label: 'Category E',
        data: estimatesCategoryData.map((item) => item.hours),
        backgroundColor: estimatesCategoryData.map((item) =>
          isBars && item.category === 'Przerwa'
            ? item.color.concat(alpha[100])
            : item.color.concat(alpha[25])
        ),
        borderColor: estimatesCategoryData.map((item) => item.color),

        datalabels: {
          color: estimatesCategoryData.map((item) => item.color),
        },
      },
    ],
  }

  const chartOptions = ({ tooltipFunction }) => ({
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        padding: 20,
        position: 'bottom',
        labels: {
          boxWidth: 10,
          padding: 16,
          font: {
            size: 10,
          },
        },
      },
      datalabels: {
        font: {
          weight: 'bolder',
          size: 12,
        },
        formatter: function (value, context) {
          return isLabels ? value + 'h' : ''
        },
        textStrokeColor: '#000000'.concat(alpha[100]),
        textStrokeWidth: 3,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return tooltipFunction(context)
          },
        },
      },
    },
  })

  const activitiesTooltip = (context) => {
    let label = context.label
    let hours = context.raw
    return `${label}: ${hours} h (${(
      ((hours * 60) / allActivitiesMinutes) *
      100
    ).toFixed(1)}%)`
  }

  const estimatesTooltip = (context) => {
    let label = context.label
    let hours = context.raw
    return `${label}: ${hours} h (${((hours / allEstimatesHours) * 100).toFixed(
      1
    )}%)`
  }

  const gridProps = {
    xs: 12,
    md: isBars ? 12 : 6,
    lg: isBars ? 12 : 6,
    xl: isBars ? 12 : 5,
  }

  return (
    <>
      <FormControlLabel
        control={<Switch checked={isBars} onChange={() => setIsBar(!isBars)} />}
        label='Słupki'
        css={{ margin: '0 2rem' }}
      />
      <FormControlLabel
        control={
          <Switch checked={isLabels} onChange={() => setIsLabels(!isLabels)} />
        }
        label='Oznaczenia'
        css={{ margin: '2rem' }}
      />
      <Grid container justifyContent='space-evenly' spacing={6} css={sCharts}>
        <Grid item {...gridProps} ref={refActivitiesCompany}>
          <h2>Company Activities</h2>
          {isBars ? (
            <Bar
              data={cActivitiesCompany}
              options={chartOptions({ tooltipFunction: activitiesTooltip })}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Pie
              data={cActivitiesCompany}
              options={chartOptions({ tooltipFunction: activitiesTooltip })}
              plugins={[ChartDataLabels]}
            />
          )}
        </Grid>
        <Grid item {...gridProps} ref={refEstimatesCompany}>
          <h2>Company Estimates</h2>
          {isBars ? (
            <Bar
              data={cEstimatesCompany}
              options={chartOptions({ tooltipFunction: estimatesTooltip })}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Pie
              data={cEstimatesCompany}
              options={chartOptions({ tooltipFunction: estimatesTooltip })}
              plugins={[ChartDataLabels]}
            />
          )}
        </Grid>
        <Grid item {...gridProps} ref={refActivitiesCategory}>
          <h2>Category Activities</h2>
          {isBars ? (
            <Bar
              data={cActivitiesCategory}
              options={chartOptions({ tooltipFunction: activitiesTooltip })}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Pie
              data={cActivitiesCategory}
              options={chartOptions({ tooltipFunction: activitiesTooltip })}
              plugins={[ChartDataLabels]}
            />
          )}
        </Grid>
        <Grid item {...gridProps} ref={refEstimatesCategory}>
          <h2>Category Estimates</h2>
          {isBars ? (
            <Bar
              data={cEstimatesCategory}
              options={chartOptions({ tooltipFunction: estimatesTooltip })}
              plugins={[ChartDataLabels]}
            />
          ) : (
            <Pie
              data={cEstimatesCategory}
              options={chartOptions({ tooltipFunction: estimatesTooltip })}
              plugins={[ChartDataLabels]}
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}

const sCharts = {
  h2: {
    fontSize: '0.875rem',
    padding: '4rem 0 2rem',
    textAlign: 'center',
    fontWeight: 300,
    // letterSpacing: '0.5em',
    // textTransform: 'uppercase',
  },
  div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}

export default EstimatesSimpleCharts
