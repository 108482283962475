export const roleValueToName = (number) => {
  switch (number) {
    case 0:
      return 'INACTIVE'
    case 1:
      return 'INTERN'
    case 2:
      return 'EXPERT'
    case 3:
      return 'MANAGER'
    case 4:
      return 'CHIEF'
    case 5:
      return 'ADMIN'
    default:
      return 'UNDEFINED ROLE'
  }
}

export const roleNameToValue = (role) => {
  switch (role) {
    case 'INACTIVE':
      return 0
    case 'INTERN':
      return 1
    case 'EXPERT':
      return 2
    case 'MANAGER':
      return 3
    case 'CHIEF':
      return 4
    case 'ADMIN':
      return 5
    // sooo important! if you misspell roleName than anyone can access resolver!
    // That's why this value is super high
    default:
      return 0
  }
}

export const roleToColor = (value) => {
  switch (value) {
    case 0:
      return '#9a9a9a'
    case 1:
      return '#00a700'
    case 2:
      return '#007aff'
    case 3:
      return '#ff8800'
    case 4:
      return '#b50216'
    case 5:
      return '#000000'
    default:
      return '#9a9a9a'
  }
}

export const companyTypeToColor = (type) => {
  switch (type) {
    case 'RDBTECHNICS':
      return '#470909'
    case 'INTERNAL':
      return '#b50216'
    case 'EXTERNAL':
      return '#007aff'
    case 'OTHER':
      return '#ffaa00'
    default:
      return '#9a9a9a'
  }
}

export const categoryTypeToColor = (type) => {
  switch (type) {
    case 'CLIENT':
      return '#00ff00'
    case 'GRAFIKA':
      return '#FFA900'
    case 'GOOGLE':
      return '#4489fe'
    case 'SOCIAL':
      return '#4267b2'
    case 'HR':
      return '#cf00ff'
    case 'IT':
      return '#9359ff'
    case 'MANAGEMENT':
      return '#ff0000'
    case 'MANUAL':
      return '#aa6500'
    case 'OFFICE':
      return '#830000'
    case 'OTHER':
      return '#b7b7b7'
    case 'PLAN':
      return '#ffff00'
    case 'PROGRESS':
      return '#ff00ad'
    case 'PRZERWA':
      return '#ffffff'
    case 'RAPORT':
      return '#ff8000'
    case 'VIDEO':
      return '#00005b'
    case 'TALK':
      return '#34a853'
    default:
      return '#b7b7b7'
  }
}
