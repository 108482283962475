/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import { IconButton, Modal, Paper } from '@material-ui/core'
import {
  GET_ESTIMATES,
  GET_ESTIMATES_BY_IDS,
} from '../../graphql/estimate.graphql'
import { toast } from 'react-toastify'
import EstimatesSettings from './EstimatesSettings'
import { s } from '../../style'
import CloseIcon from '@material-ui/icons/Close'

const EstimateEditModal = ({ editEstimate, setEditEstimate }) => {
  const nowYear = new Date().getFullYear()
  const nowMonth = new Date().getMonth()

  const initialValues = {
    companies: [],
    categories: [],
    projects: [],
    dateFrom: new Date(nowYear, nowMonth, 1, 2, 0).toISOString().slice(0, 10),
    dateTo: new Date(nowYear, nowMonth + 1, 0, 24, 59)
      .toISOString()
      .slice(0, 10),
  }

  const [inputValues] = useState(initialValues)

  // console.log(editEstimate)
  // console.log(inputValues)
  // console.log(editEstimate)

  const {
    data: eCompaniesData,
    loading: eCompaniesLoading,
    error: eCompaniesError,
    refetch: eCompaniesRefetch,
  } = useQuery(GET_ESTIMATES, {
    variables: {
      ...inputValues,
      companies: editEstimate.companyId ? [editEstimate.companyId] : [],
      categories: editEstimate.categoryId ? [editEstimate.categoryId] : [],
    },
    skip: !editEstimate.companyId && !editEstimate.categoryId,
  })

  const {
    data: eIdsData,
    loading: eIdsLoading,
    error: eIdsError,
    refetch: eIdsRefetch,
  } = useQuery(GET_ESTIMATES_BY_IDS, {
    variables: { ids: editEstimate.estimateIds },
    skip: !editEstimate.estimateIds,
  })

  const refetchModal = () => {
    if (editEstimate.companyId || editEstimate.categoryId) eCompaniesRefetch()
    if (editEstimate.estimateIds) eIdsRefetch()
  }

  useEffect(() => {
    // if (editEstimate.companyId || editEstimate.categoryId) eCompaniesRefetch()
    // if (editEstimate.estimateIds) eIdsRefetch()
    refetchModal()
  }, [editEstimate.estimateIds])

  if (eCompaniesError) {
    toast.error(eCompaniesError.toString())
    return <div>Error</div>
  }

  if (eIdsError) {
    toast.error(eIdsError.toString())
    return <div>Error</div>
  }

  // useEffect(() => {
  //   if (editEstimate.companyId)
  //     setInputValues({ ...inputValues, companies: [editEstimate.companyId] })
  // }, [editEstimate])

  let estimates = []
  if (eCompaniesData?.getEstimates)
    estimates = [...estimates, ...eCompaniesData.getEstimates]
  if (eIdsData?.getEstimatesIds)
    estimates = [...estimates, ...eIdsData.getEstimatesIds]

  // console.log(eCompaniesData)
  // console.log(eIdsData)

  // console.log(editEstimate)

  return (
    <div>
      <Modal onClose={() => setEditEstimate(null)} open={Boolean(editEstimate)}>
        <Paper css={sFront}>
          <IconButton
            size='small'
            css={{ position: 'absolute', top: '1rem', right: '1rem' }}
            onClick={() => setEditEstimate(null)}
          >
            <CloseIcon />
          </IconButton>
          <EstimatesSettings
            estimates={estimates || []}
            estimatesLoading={eCompaniesLoading || eIdsLoading}
            getEstimatesVaraibles={inputValues}
            editEstimate={editEstimate}
            setEditEstimate={setEditEstimate}
            refetchModal={refetchModal}
            refetchModalCompanies={eCompaniesRefetch}
          />
        </Paper>
      </Modal>
    </div>
  )
}

export const sFront = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  padding: '4rem',
  width: '100%',
  maxWidth: 1440,
  height: 'max-content',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  [s.xs]: {
    width: '100%',
    maxHeight: 'calc(100vh - 6rem)',
    padding: '3rem 2rem',
    top: 'auto',
    bottom: 0,
    transform: 'translate(-50%, 0%)',
  },
}

export default EstimateEditModal
