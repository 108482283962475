/** @jsxImportSource @emotion/react */

import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import ClickupIcon from '../../assets/ClickupIcon'
import { s } from '../../style'

const redirect_url = process.env.REACT_APP_SERVER_URI_production + '/clickup'
export const clickuplink = `https://app.clickup.com/api?client_id=${process.env.REACT_APP_CLICKUP_CLIENT}&redirect_uri=${redirect_url}`

const ClickupAuthButton = ({ reload }) => {
  const [token, setToken] = useState()

  useEffect(() => {
    const getToken = JSON.parse(localStorage.getItem('clickup'))
    if (getToken) setToken(getToken)
  }, [])

  if (reload)
    return (
      <a href={clickuplink} css={[sButton, { backgroundColor: 'lightgray' }]}>
        <ClickupIcon /> Autoryzuj ponownie
      </a>
    )
  if (!token)
    return (
      <a
        href={clickuplink}
        css={[
          sButton,
          {
            background: 'green',
          },
        ]}
      >
        <ClickupIcon /> Connect ClickUp
      </a>
    )
  return (
    <Link
      to='/clickup'
      css={[
        sButton,
        {
          background: `linear-gradient(110deg, #49CCF9, #8930FD, #FF02F0, #FFC800)`,
        },
      ]}
    >
      <ClickupIcon /> Connected
    </Link>
  )
}

const sButton = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  color: 'white',
  svg: {
    width: '1.5rem',
    height: '1.5rem',
    // transform: 'scale(0.1)',
    marginRight: '0.5rem',
  },
  height: 'max-content',
  padding: '0.5rem 1rem',
  borderRadius: '1rem',
  width: 'max-content',
  [s.xs]: {
    fontSize: '0.75rem',
  },
}

export default ClickupAuthButton
