/** @jsxImportSource @emotion/react */

import {
  sDetailContainer,
  sDetailsWrapper,
} from '../Activities/ActivitiesDetails'
import { hoursToTime } from '../../utils/hoursToTime'
import {
  FormControlLabel,
  Switch,
  Grid,
  Button,
  Paper,
} from '@material-ui/core'
import { alpha } from '../../style'

const EstimatesDetails = ({ activities, estimates }) => {
  const allActivitiesMinutes = activities.reduce(
    (sum, curr) => curr.minutes + sum,
    0
  )
  const allActivitiesHours = (allActivitiesMinutes / 60).toFixed(2)
  const allEstimatesHours = estimates.reduce((sum, curr) => curr.hours + sum, 0)

  const workDone = allEstimatesHours
    ? ((allActivitiesHours / allEstimatesHours) * 100).toFixed(2)
    : 0
  const workLeft = allEstimatesHours - allActivitiesHours

  return (
    <Paper
      elevation={0}
      css={{
        position: 'relative',
        backgroundColor: '#b8d9ff'.concat(alpha[16]),
      }}>
      <div css={sDetailsWrapper(workDone, workLeft)} />
      <Grid container css={sDetailContainer}>
        <Grid item xs={12} sm={6} lg={3}>
          <p>Activities</p>
          <strong>{parseFloat(allActivitiesHours).toFixed(0)} h</strong>
          <span>{activities.length} activities</span>
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <p>Estimates</p>
          <strong>{allEstimatesHours} h</strong>
          <span>{estimates.length} estimates</span>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={3}
          css={[
            workDone >= 80 && {
              color: 'orange',
              'p, span': { opacity: '1 !important' },
            },
            workDone >= 90 && {
              color: 'red',
              'p, span': { opacity: '1 !important' },
            },
          ]}>
          <p>WorkDone</p>
          <strong>{workDone} %</strong>
          <span> of all estimates</span>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={3}
          css={[
            workLeft <= 8 && {
              color: 'orange',
              'p, span': { opacity: '1 !important' },
            },
            workLeft <= 1 && {
              color: 'red',
              'p, span': { opacity: '1 !important' },
            },
          ]}>
          <p>WorkLeft</p>
          <strong>
            {workLeft > 0
              ? hoursToTime(workLeft, workLeft < 1000 ? 'HH:MM' : 'h')
              : workLeft.toFixed(2) + ' h'}
          </strong>
          <span>{workLeft < 1000 && workLeft.toFixed(1)} hours left</span>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default EstimatesDetails
