/** @jsxImportSource @emotion/react */
import { alpha, colors } from '../style'

const ColorLine = ({
  title,
  color,
  percentage,
  children,
  isOvertime,
  isOverSoon,
  isOverWarning,
  lightBack,
  lessVisible,
  shine,
  extraCss,
  withIcon,
  onClick,
}) => {
  return (
    <div
      // key={animationTrigger}
      css={[
        sInner(color),
        lightBack && { backgroundColor: color.concat(alpha[6]) },
        extraCss,
      ]}>
      <div
        css={[
          {
            transition: 'opacity 0.3s ease-out',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: `linear-gradient(to right, ${color.concat(
              alpha[0]
            )}, ${color.concat(alpha[30])}, ${color.concat(alpha[0])})`,

            opacity: 0,
          },
          shine && { animation: 'shine 2s ease infinite' },
        ]}></div>
      <div
        css={[
          sBack(color, percentage),
          lessVisible && {
            background: `linear-gradient(to left, ${color.concat(
              alpha[30]
            )}, ${color.concat(alpha[10])})`,
          },
        ]}
      />
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        onClick={() => onClick && onClick()}
      />
      {title && <div css={sTitle}>{title}</div>}
      <div
        css={[
          sChildren,
          {
            svg: {
              color: color,
            },
          },
          isOverSoon && sChildrenOverSoon,
          isOverWarning && sChildrenOverWarning,
          isOvertime && sChildrenOvertime,
          withIcon && sWithIcon,
        ]}>
        {children}
      </div>
    </div>
  )
}

const sInner = (color) => ({
  position: 'relative',
  top: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: color.concat(alpha[0]) || 'rgba(0,0,0,0)',
  borderRadius: '0.25rem',
  fontSize: '0.675rem',
  fontWeight: 'bold',
  overflow: 'hidden',
  color: color.concat(alpha[50]) || 'rgba(0,0,0,1)',
  textShadow: '0px 0px 0px rgba(0,0,0,0.5)',
})

const sTitle = {
  padding: '0.25rem 0.5rem',
  display: 'flex',
  alignItems: 'center',
}

const sWithIcon = {
  paddingRight: '2rem',
}

const sBack = (color, percentage) => ({
  transition: 'width 1s ease-out',
  borderRadius: '0.25rem',
  position: 'absolute',
  top: 0,
  left: 0,
  width: percentage + '%',
  maxWidth: '100%',
  height: '100%',
  background: `linear-gradient(to left, ${color.concat(
    alpha[30]
  )}, ${color.concat(alpha[12])})`,
})

const sChildren = {
  position: 'relative',
  top: 0,
  left: 0,
  span: { opacity: 0.5 },
  padding: '0.25rem 0.5rem',
  // svg: {}
}

const sChildrenOvertime = {
  backgroundColor: 'red',
  color: 'white',
  span: { opacity: 1 },
}

const sChildrenOverWarning = {
  backgroundColor: colors.orange,
  color: 'white',
  span: { opacity: 1 },
}

const sChildrenOverSoon = {
  backgroundColor: colors.yellow,
  color: 'white',
  span: { opacity: 1 },

  svg: {
    color: 'white',
  },
}

export default ColorLine
