import _ from 'lodash'
import {
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Font,
} from '@react-pdf/renderer'
import { minutesToTime } from '../../utils/minutesToTime'

Font.register({
  family: 'Roboto',
  format: 'truetype',
  // src: `https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxPKTU1Kg.ttf`,
  src: `//${window.location.host}/fonts/Roboto.ttf`,
})

const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const styles = StyleSheet.create({
  body: {
    padding: 10,
    fontFamily: 'Roboto',
  },
  table: {
    display: 'table',
    width: 'auto',
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: {
    margin: 'auto',
    flexDirection: 'row',
  },
  tableColHeader: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol: {
    borderStyle: BORDER_STYLE,
    borderColor: BORDER_COLOR,
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 7,
  },
})

const ActivitiesPDF = ({ data }) => {
  if (!data || data.length <= 0)
    return (
      <Document>
        <Page>
          <View>
            <Text>No data</Text>
          </View>
        </Page>
      </Document>
    )

  const allActivities = data.length

  const allMinutes = data.reduce((sum, curr) => curr.minutes + sum, 0)

  const categories = _(data)
    .groupBy('category.name')
    .map((objs, key) => ({
      name: key,
      minutes: _.sumBy(objs, 'minutes'),
    }))
    .sortBy(['minutes'])
    .reverse()
    .value()

  const projects = _(data)
    .groupBy('project.name')
    .map((objs, key) => ({
      name: key,
      minutes: _.sumBy(objs, 'minutes'),
    }))
    .sortBy(['minutes'])
    .reverse()
    .value()

  const users = _(data)
    .groupBy('author._id')
    .map((objs, key) => ({
      name: key,
      firstName: objs[0].author.firstName,
      lastName: objs[0].author.lastName,
      minutes: _.sumBy(objs, 'minutes'),
    }))
    .sortBy(['minutes'])
    .reverse()
    .value()

  const companies = _(data)
    .groupBy('company.name')
    .map((objs, key) => ({
      name: key,
    }))
    .value()

  // console.log('users', users)

  return (
    <Document>
      <Page style={styles.body}>
        <View
          style={{
            marginBottom: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Text style={{ textAlign: 'center', margin: 10 }}>
            {companies.length > 1
              ? 'Raport ' + companies.length + ' firm'
              : data[0].company.name}
          </Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>
            od {data[0].date.slice(0, 10)} do{' '}
            {data[allActivities - 1].date.slice(0, 10)}
          </Text>
          <Text style={{ fontSize: 8, opacity: 0.25 }}>
            Wygenerowano: {new Date().toLocaleString()}
          </Text>
        </View>

        {/* CATEGORIES */}
        <View style={[styles.table, { marginBottom: 10 }]}>
          <View style={styles.tableRow}>
            <View style={[styles.tableColHeader, { width: 60 + '%' }]}>
              <Text style={styles.tableCellHeader}>Kategoria</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 20 + '%' }]}>
              <Text style={styles.tableCellHeader}>Czas</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 20 + '%' }]}>
              <Text style={styles.tableCellHeader}>Procent</Text>
            </View>
          </View>
          {categories.map((category, id) => (
            <View style={styles.tableRow} key={id}>
              <View style={[styles.tableCol, { width: 60 + '%' }]}>
                <Text style={styles.tableCell}>{category.name}</Text>
              </View>
              <View style={[styles.tableCol, { width: 20 + '%' }]}>
                <Text style={styles.tableCell}>
                  {minutesToTime(category.minutes, 'HH:MM')}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: 20 + '%' }]}>
                <Text style={styles.tableCell}>
                  {((category.minutes / allMinutes) * 100).toFixed(2)}%
                </Text>
              </View>
            </View>
          ))}
          <View style={[styles.tableRow, { backgroundColor: '#bfbfbf' }]}>
            <View style={[styles.tableCol, { width: 60 + '%' }]}>
              <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>
                Łącznie
              </Text>
            </View>
            <View style={[styles.tableCol, { width: 20 + '%' }]}>
              <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>
                {minutesToTime(allMinutes, 'HH:MM')}
              </Text>
            </View>
            <View style={[styles.tableCol, { width: 20 + '%' }]}>
              <Text style={styles.tableCell}>100%</Text>
            </View>
          </View>
        </View>

        {/* PROJECTS */}
        <View style={[styles.table, { marginBottom: 10 }]}>
          <View style={styles.tableRow}>
            <View style={[styles.tableColHeader, { width: 60 + '%' }]}>
              <Text style={styles.tableCellHeader}>Projekt</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 20 + '%' }]}>
              <Text style={styles.tableCellHeader}>Czas</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 20 + '%' }]}>
              <Text style={styles.tableCellHeader}>Procent</Text>
            </View>
          </View>
          {projects.map((project, id) => (
            <View style={styles.tableRow} key={id}>
              <View style={[styles.tableCol, { width: 60 + '%' }]}>
                <Text style={styles.tableCell}>
                  {project.name === 'undefined' ? 'Bez projektu' : project.name}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: 20 + '%' }]}>
                <Text style={styles.tableCell}>
                  {minutesToTime(project.minutes, 'HH:MM')}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: 20 + '%' }]}>
                <Text style={styles.tableCell}>
                  {((project.minutes / allMinutes) * 100).toFixed(2)}%
                </Text>
              </View>
            </View>
          ))}
          <View style={[styles.tableRow, { backgroundColor: '#bfbfbf' }]}>
            <View style={[styles.tableCol, { width: 60 + '%' }]}>
              <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>
                Łącznie
              </Text>
            </View>
            <View style={[styles.tableCol, { width: 20 + '%' }]}>
              <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>
                {minutesToTime(allMinutes, 'HH:MM')}
              </Text>
            </View>
            <View style={[styles.tableCol, { width: 20 + '%' }]}>
              <Text style={styles.tableCell}>100%</Text>
            </View>
          </View>
        </View>

        {/* USERS */}
        <View style={[styles.table, { marginBottom: 10 }]}>
          <View style={styles.tableRow}>
            <View style={[styles.tableColHeader, { width: 60 + '%' }]}>
              <Text style={styles.tableCellHeader}>Pracownik</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 20 + '%' }]}>
              <Text style={styles.tableCellHeader}>Czas</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 20 + '%' }]}>
              <Text style={styles.tableCellHeader}>Procent</Text>
            </View>
          </View>
          {users.map((user, id) => (
            <View style={styles.tableRow} key={id}>
              <View style={[styles.tableCol, { width: 60 + '%' }]}>
                <Text style={styles.tableCell}>
                  {user.firstName} {user.lastName?.[0]}.
                </Text>
              </View>
              <View style={[styles.tableCol, { width: 20 + '%' }]}>
                <Text style={styles.tableCell}>
                  {minutesToTime(user.minutes, 'HH:MM')}
                </Text>
              </View>
              <View style={[styles.tableCol, { width: 20 + '%' }]}>
                <Text style={styles.tableCell}>
                  {((user.minutes / allMinutes) * 100).toFixed(2)}%
                </Text>
              </View>
            </View>
          ))}
          <View style={[styles.tableRow, { backgroundColor: '#bfbfbf' }]}>
            <View style={[styles.tableCol, { width: 60 + '%' }]}>
              <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>
                Łącznie
              </Text>
            </View>
            <View style={[styles.tableCol, { width: 20 + '%' }]}>
              <Text style={[styles.tableCell, { fontWeight: 'bold' }]}>
                {minutesToTime(allMinutes, 'HH:MM')}
              </Text>
            </View>
            <View style={[styles.tableCol, { width: 20 + '%' }]}>
              <Text style={styles.tableCell}>100%</Text>
            </View>
          </View>
        </View>

        {/* ACTIVITIES */}
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={[styles.tableColHeader, { width: 18 + '%' }]}>
              <Text style={styles.tableCellHeader}>Marka</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 16 + '%' }]}>
              <Text style={styles.tableCellHeader}>Kategoria</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 16 + '%' }]}>
              <Text style={styles.tableCellHeader}>Projekt</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 28 + '%' }]}>
              <Text style={styles.tableCellHeader}>Opis</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 8 + '%' }]}>
              <Text style={styles.tableCellHeader}>Data</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 6 + '%' }]}>
              <Text style={styles.tableCellHeader}>Czas</Text>
            </View>
            <View style={[styles.tableColHeader, { width: 8 + '%' }]}>
              <Text style={styles.tableCellHeader}>Autor</Text>
            </View>
          </View>

          {data.map(
            (
              { company, category, title, date, minutes, author, project },
              id
            ) => (
              <View style={styles.tableRow} key={id}>
                <View style={[styles.tableCol, { width: 18 + '%' }]}>
                  <Text style={styles.tableCell}>{company.name}</Text>
                </View>
                <View style={[styles.tableCol, { width: 16 + '%' }]}>
                  <Text style={styles.tableCell}>{category.name}</Text>
                </View>
                <View style={[styles.tableCol, { width: 16 + '%' }]}>
                  <Text style={styles.tableCell}>
                    {project ? project?.name : ' - '}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: 28 + '%' }]}>
                  <Text style={styles.tableCell}>{title}</Text>
                </View>
                <View style={[styles.tableCol, { width: 8 + '%' }]}>
                  <Text style={styles.tableCell}>{date.slice(0, 10)}</Text>
                </View>
                <View style={[styles.tableCol, { width: 6 + '%' }]}>
                  <Text style={styles.tableCell}>
                    {minutesToTime(minutes || 0, 'HH:MM')}
                  </Text>
                </View>
                <View style={[styles.tableCol, { width: 8 + '%' }]}>
                  <Text style={styles.tableCell}>
                    {author.firstName} {author.lastName?.[0]}.
                  </Text>
                </View>
              </View>
            )
          )}
        </View>
      </Page>
    </Document>
  )
}

export default ActivitiesPDF
