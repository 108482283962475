import { createContext, useContext } from 'react'

function noop() {}

export const AuthContext = createContext({
  token: null,
  userId: null,
  userRole: 0,
  userName: '',
  login: noop,
  logout: noop,
  isAuth: false,
})

export const useAuthContext = () => useContext(AuthContext)
