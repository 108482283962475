import { gql } from '@apollo/client'

export const ACTIVITY_STOP_TODAY = gql`
  query activityStopToday {
    activityStopToday {
      _id
      title
      date
      timeStart
      timeEnd
      minutes
      company {
        _id
        name
        icon
        type
        color
        cph_default
      }
      author {
        _id
        firstName
        lastName
        image
        color
        role
        title
      }
      category {
        _id
        name
        color
        type
      }
      project {
        _id
        name
        color
      }
      clickup
    }
  }
`

export const ACTIVITY_TRACK = gql`
  mutation activityTrack($input: ActivityTrackInput!) {
    activityTrack(input: $input) {
      _id
      title
      timeStart
      company {
        _id
        name
        icon
        color
      }
      project {
        _id
        name
        color
      }
      category {
        _id
        name
        color
      }
      clickup
    }
  }
`

export const GET_ACTIVITY_TRACK = gql`
  query getActivity($id: ID!) {
    getActivity(id: $id) {
      _id
      title
      timeStart
      timeEnd
      company {
        _id
        name
        icon
        color
      }
      project {
        _id
        name
        color
      }
      category {
        _id
        name
        color
      }
      clickup
    }
  }
`

export const GET_ACTIVITY = gql`
  query getActivity($id: ID!) {
    getActivity(id: $id) {
      _id
      title
      date
      timeStart
      timeEnd
      minutes
      company {
        _id
        name
        icon
        require_projects
      }
      author {
        _id
        firstName
        lastName
        image
      }
      category {
        _id
        name
        color
        type
      }
      project {
        _id
        name
        color
      }
      clickup
    }
  }
`

export const ACTIVITY_UPDATE = gql`
  mutation activityUpdate($id: ID!, $input: ActivityUpdateInput!) {
    activityUpdate(id: $id, input: $input) {
      _id
      title
      date
      timeStart
      timeEnd
      minutes
      company {
        _id
        name
        icon
        color
      }
      author {
        _id
        firstName
        lastName
        image
        role
      }
      category {
        _id
        name
        color
        type
      }
      project {
        _id
        name
        color
      }
      clickup
    }
  }
`

export const ACTIVITY_CREATE = gql`
  mutation activityCreate($input: ActivityCreateInput!) {
    activityCreate(input: $input) {
      _id
      title
      date
      timeStart
      timeEnd
      minutes
      company {
        _id
        name
        icon
        color
      }
      author {
        _id
        firstName
        lastName
        image
      }
      category {
        _id
        name
        color
        type
      }
      project {
        _id
        name
        color
      }
      clickup
    }
  }
`

export const GET_ACTIVITIES = gql`
  query getActivities(
    $title: String
    $dateFrom: Date
    $dateTo: Date
    $companies: [ID]
    $projects: [ID]
    $categories: [ID]
    $minFrom: Int
    $minTo: Int
    $authors: [ID]
  ) {
    getActivities(
      title: $title
      dateFrom: $dateFrom
      dateTo: $dateTo
      companies: $companies
      projects: $projects
      categories: $categories
      minFrom: $minFrom
      minTo: $minTo
      authors: $authors
    ) {
      _id
      title
      date
      timeStart
      timeEnd
      minutes
      company {
        _id
        name
        icon
        type
        color
        cph_default
      }
      author {
        _id
        firstName
        lastName
        image
        color
        role
        title
      }
      category {
        _id
        name
        color
        type
      }
      project {
        _id
        name
        color
      }
      clickup
      # comments {
      #   message
      #   from {
      #     firstName
      #     lastName
      #   }
      #   createAt
      # }
    }
  }
`

export const GET_ACTIVITIES_NO_PROJECTS = gql`
  query getActivities(
    $title: String
    $dateFrom: Date
    $dateTo: Date
    $companies: [ID]
    $projects: [ID]
    $categories: [ID]
    $minFrom: Int
    $minTo: Int
    $authors: [ID]
  ) {
    getActivities(
      title: $title
      dateFrom: $dateFrom
      dateTo: $dateTo
      companies: $companies
      projects: $projects
      categories: $categories
      minFrom: $minFrom
      minTo: $minTo
      authors: $authors
    ) {
      _id
      title
      date
      timeStart
      timeEnd
      minutes
      company {
        _id
        name
        icon
        type
        color
      }
      author {
        _id
        firstName
        lastName
        image
        color
        role
      }
      category {
        _id
        name
        color
        type
      }
      clickup
    }
  }
`
