/** @jsxImportSource @emotion/react */

import { useShiftsContext } from '../../context/shifts.context'
import PageWrapper from '../../components/PageWrapper'
import { useAuthContext } from '../../context/auth.context'
import { useAuth } from '../../hooks/auth.hook'
import { colors, alpha, s } from '../../style'
import { Button } from '@material-ui/core'

const ShiftContainer = () => {
  const { hasShift, onCompleteShift } = useShiftsContext()
  const { userName } = useAuth()

  if (!hasShift) return null
  if (hasShift.date.slice(0, 10) !== new Date().toISOString().slice(0, 10))
    return null

  const { info, isDone, _id } = hasShift

  return (
    <PageWrapper>
      <div
        css={[
          sContainer,
          isDone && {
            background: `linear-gradient(to right, ${colors.green}, ${colors.green800})`,
          },
        ]}>
        <div>
          {isDone ? (
            <p css={sTitle}>Dyżur wykonany</p>
          ) : (
            <p css={sTitle}>
              {userName ? userName.split(' ')[0] + ', dziś' : 'Dziś'} jest twój
              dyżur!
            </p>
          )}
          <p css={sDesc}>
            {info && (
              <>
                Przydzielono Ci zadanie: <strong>{info}.&nbsp;</strong>
              </>
            )}
            {!isDone
              ? `Wciśnij przycisk, po zakończeniu pracy!`
              : `Dzięki. Dobra robota!`}
          </p>
        </div>
        <Button
          variant='contained'
          onClick={() => onCompleteShift(_id)}
          css={[
            { color: 'white' },
            isDone
              ? {
                  backgroundColor: colors.green800,

                  '&:hover': {
                    backgroundColor: colors.greenDimmed,
                  },
                }
              : {
                  backgroundColor: colors.orange,

                  '&:hover': {
                    backgroundColor: colors.orangeDimmed,
                  },
                },
          ]}>
          {isDone ? 'COFNIJ' : 'ZROBIONE'}
        </Button>
      </div>
    </PageWrapper>
  )
}

const sContainer = {
  padding: '2rem',
  background: `linear-gradient(to right, #f1970f, #a16900)`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '0.75rem',
  [s.xs]: {
    flexDirection: 'column',
    padding: '1rem',
  },
}

const sTitle = {
  fontSize: '1rem',
  fontWeight: 'bold',
  color: 'white',
  marginBottom: '0.5rem',
  [s.xs]: {
    fontSize: '1rem',
  },
}

const sDesc = {
  fontSize: '0.875rem',
  color: colors.white.concat(alpha[60]),
  strong: {
    color: colors.white,
  },
  [s.xs]: {
    fontSize: '0.75rem',
  },
}

export default ShiftContainer
