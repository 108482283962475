export const colors = {
  white: '#FFFFFF',
  gray100: '#F7F8FA', // 220 1 98
  gray200: '#F0F2F5', // 216 2 96
  gray250: '#EDEFF2', // 220 2 94
  gray300: '#E4E6EB', // 223 3 92
  gray400: '#D3D7E0', // 222 6 88
  gray500: '#B9BCC4', // 224 6 77
  gray600: '#A3A5B0', // 231 7 69

  textDimmed: '#3433404D', // colors.text.concat(alpha[30])
  text: '#343340',
  black: '#000000',

  lime: '#AAFF00',

  green100: '#EBFFEC',
  green200: '#D6FFDA',
  green400: '#00CC11',
  green: '#00B30F',
  green800: '#006609',
  greenDimmed: '#00B30F4D', // colors.green.concat(alpha[30])

  yellow100: '#FFFCEB',
  yellow: '#FFD500',
  yellowDimmed: '#FFD5004D', // colors.yellow.concat(alpha[30])

  orange100: '#FFF5EB',
  orange200: '#FFAA00',
  orange: '#FF8000',
  orangeDimmed: '#FF80004D', // colors.orange.concat(alpha[30])

  red100: '#FFEBEB',
  red200: '#FFD6D6',
  // red300: '#E74C3C',
  red: '#FF0000',
  red600: '#D32E38',
  // red700: '#5C120A',
  red800: '#6A171C',
  redDimmed: '#FF00004D', // colors.red.concat(alpha[30])

  blue100: '#EBF8FF',
  blue: '#00AAFF',
  blueDimmed: '#00AAFF4D', // colors.blue.concat(alpha[30])
}
