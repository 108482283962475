import { useContext } from 'react'
import { useMutation } from '@apollo/client'
import { toast } from 'react-toastify'
import { AuthContext } from '../context/auth.context'
import {
  USER_LOGIN,
  USER_PASSWORD_RESET,
  USER_PASSWORD_NEW,
} from '../graphql/user.graphql'

export default function useUserMutations() {
  const authContext = useContext(AuthContext)
  const [userLogin] = useMutation(USER_LOGIN)
  const [userPasswordReset] = useMutation(USER_PASSWORD_RESET)
  const [userPasswordNew] = useMutation(USER_PASSWORD_NEW)

  const handleUserLogin = (data, cb) => {
    const { email, password } = data

    userLogin({
      variables: {
        email: email.trim(),
        password: password.trim(),
      },
    })
      .then(({ data }) => {
        if (typeof cb === 'function') cb(data)
        authContext.login(
          data.userLogin.token,
          data.userLogin.userId,
          data.userLogin.role,
          new Date().getTime(),
          data.userLogin.name
        )
        toast.dark(
          <div>
            <strong>Witaj, {data.userLogin.name} 🖐</strong>
            <br />
            <span>Miłego dnia w pracy!</span>
          </div>,
          { autoClose: false }
        )
      })
      .catch((error) => {
        if (typeof cb === 'function') cb(data)
        toast.error(error.toString())
      })
  }

  const handlePasswordReset = (data, cb) => {
    const { email } = data

    userPasswordReset({
      variables: { email },
    })
      .then((res) => {
        if (typeof cb === 'function')
          cb({ success: res?.data?.userPasswordReset?.success })
      })
      .catch((error) => {
        if (typeof cb === 'function') cb()
        toast.error(error.toString())
      })
  }

  const handlePasswordNew = (data, cb) => {
    const { password, token, id } = data

    userPasswordNew({ variables: { password, token, id } })
      .then((res) => {
        if (typeof cb === 'function') {
          cb({ success: res?.data?.userPasswordNew?.success })
        }
      })
      .catch((error) => {
        if (typeof cb === 'function') cb()
        toast.error(error.toString())
      })
  }

  return {
    handleUserLogin,
    handlePasswordReset,
    handlePasswordNew,
    userLogin,
  }
}
