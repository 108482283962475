export const minutesToTime = (minutes, format) => {
  const h = Math.floor(minutes / 60)
  const m = minutes % 60

  switch (format) {
    case 'HH:MM':
      return (
        (h < 10 ? '0' + h.toFixed(0) : h.toFixed(0)) +
        ':' +
        (m < 10 ? '0' + m.toFixed(0) : m.toFixed(0))
      )
    case 'Hh_Mm':
      return h + 'h ' + m + 'm'
    default:
      return minutes
  }
}
