import { createContext, useContext } from 'react'

function noop() {}

export const UIContext = createContext({
  editActivityOpen: null,
  setEditActivityOpen: noop,

  editEstimateOpen: null,
  setEditEstimateOpen: null,
})

export const useUIContext = () => useContext(UIContext)
