/** @jsxImportSource @emotion/react */

import { useState, useRef, useEffect } from 'react'
import _ from 'lodash'

import { useQuery, useMutation } from '@apollo/client'
import {
  GET_SHIFTS,
  SHIFT_CREATE,
  SHIFT_UPDATE,
} from '../../graphql/shifts.graphql'
import { GET_USERS } from '../../graphql/user.graphql'
import { toast } from 'react-toastify'
import {
  Avatar,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  TextField,
  FormControlLabel,
  Switch,
  Button,
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import CloseIcon from '@material-ui/icons/Close'
import Skeleton from '@material-ui/lab/Skeleton'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { imageLink } from '../../utils/imageLink'
import ShiftDone from '@material-ui/icons/AssignmentTurnedIn'
import ShiftNotDone from '@material-ui/icons/AssignmentLate'
import ShiftPlanned from '@material-ui/icons/AssignmentInd'
import { colors } from '../../style'
import { SHIFTS_PREDEFINED } from '../../constants'

const SettingsShifts = ({ dataShifts, inputValues }) => {
  const {
    data: dataUsers,
    loading: loadingUsers,
    error: errorUsers,
  } = useQuery(GET_USERS)

  const [shiftCreate] = useMutation(SHIFT_CREATE)
  const [shiftUpdate] = useMutation(SHIFT_UPDATE)
  const [open, setOpen] = useState(false)
  const tableRef = useRef(null)

  const scrollToBottom = () => {
    tableRef.current?.scrollTo({
      top: tableRef.current.scrollHeight,
      behavior: 'smooth',
    })
  }

  if (loadingUsers)
    return <Skeleton variant='rect' height={300} css={{ width: '100%' }} />

  if (errorUsers) {
    toast.error(errorUsers.toString())
    return <div>{errorUsers.toString()}</div>
  }

  const onUpdateSubmit = ({ id, input, callback }) => {
    const { date, info, user, isDone } = input

    const newInput = {
      date,
      info,
      isDone,
      user: user?._id,
    }
    shiftUpdate({
      variables: { id, input: newInput },
      refetchQueries: [{ query: GET_SHIFTS, variables: { ...inputValues } }],
    })
      .then((res) => {
        if (res?.data?.shiftUpdate)
          toast.info(
            <div>
              <strong>
                🧹&nbsp;&nbsp;<span>UPDATED</span>
              </strong>
              <p>
                <strong>{new Date(date).toISOString().slice(0, 10)}</strong>
                {user && (
                  <span>
                    &nbsp;({user.firstName} {user.lastName})
                  </span>
                )}
              </p>
            </div>,
            { autoClose: 10000 }
          )
        callback()
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  const onCreateSubmit = ({ input, callback }) => {
    const { date, user, info } = input
    const newInput = {
      date,
      info,
      user: user?._id,
    }
    shiftCreate({
      variables: { input: newInput },
      update: (cache, { data }) => {
        const shiftsCache = cache.readQuery({
          query: GET_SHIFTS,
          variables: { ...inputValues },
        })
        cache.writeQuery({
          query: GET_SHIFTS,
          variables: { ...inputValues },
          data: {
            getShifts: [...shiftsCache?.getShifts, data?.shiftCreate],
          },
        })
      },
      // refetchQueries: [{ query: GET_PROJECTS_SETTINGS }],
    })
      .then((res) => {
        if (res?.data?.shiftCreate)
          toast.success(
            <div>
              <strong>
                🧹&nbsp;&nbsp;<span>CREATED</span>
              </strong>
              <p>
                <strong>{new Date(date).toISOString().slice(0, 10)}</strong>
                {input.user && (
                  <span>
                    &nbsp;({user.firstName} {user.lastName})
                  </span>
                )}
              </p>
            </div>,
            { autoClose: 10000 }
          )
        callback && callback()
        scrollToBottom()
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  return (
    <>
      <Paper elevation={1}>
        <TableContainer css={{ maxHeight: '50vh' }} ref={tableRef}>
          <Table stickyHeader aria-label='collapsible table' size='small'>
            <TableHead
              css={{
                th: { fontSize: '0.75rem', color: 'rgba(0,0,0,0.5)' },
              }}>
              <TableRow>
                <TableCell css={{ paddingLeft: '3rem' }}>Shift</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Info</TableCell>
                <TableCell>Done</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {dataShifts.map((row, id) => (
                <Row
                  key={row._id}
                  data={row}
                  onSubmit={onUpdateSubmit}
                  users={dataUsers.getUsers}
                  onDuplicate={onCreateSubmit}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Paper elevation={1}>
        <Collapse in={open}>
          <Form
            open={open}
            setOpen={setOpen}
            onSubmit={onCreateSubmit}
            button={{ title: 'CREATE' }}
            users={dataUsers.getUsers}
          />
        </Collapse>
      </Paper>
      <Button
        css={{ marginTop: '1rem' }}
        size='small'
        onClick={() => setOpen(!open)}
        color={open ? 'default' : 'primary'}
        variant='contained'>
        {!open ? 'NEW SHIFT' : 'CANCEL CREATION'}
      </Button>
    </>
  )
}

const Row = ({ data, onSubmit, users, onDuplicate }) => {
  const [open, setOpen] = useState(false)
  const { user, info, date, isDone, isPast } = data

  return (
    <>
      <TableRow
        css={[
          {
            td: { fontSize: '0.75rem' },
            '&:hover': {
              backgroundColor: '#fcfcfc',
              '.repeatEstimate': {
                opacity: 1,
              },
            },
          },
        ]}>
        <TableCell component='th' scope='row' css={{ position: 'relative' }}>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              fontSize: 'inherit',
            }}>
            <Avatar
              src={imageLink(user.image)}
              css={[
                {
                  width: 24,
                  height: 24,
                  fontSize: 12,

                  backgroundColor: user.image ? 'transparent' : user.color,
                },
              ]}
              alt=''>
              {user.firstName?.[0]}
              {user.lastName?.[0]}
            </Avatar>

            <span
              css={{
                paddingLeft: '1rem',
                fontSize: '0.75rem',
                fontWeight: 'bold',
              }}>
              {user.firstName} {user.lastName}
            </span>
          </div>
          <Button
            className='repeatEstimate'
            onClick={() => {
              onDuplicate({
                input: {
                  user,
                  date: new Date(
                    new Date(date).getTime() + 7 * 24 * 60 * 60 * 1000
                  ),
                  info,
                  isDone: false,
                },
              })
              // setOpen(true)
            }}
            size='small'
            variant='contained'
            css={{
              opacity: 0,
              position: 'absolute',
              top: '50%',
              right: 16,
              transform: 'translate(0%,-50%)',
              minWidth: 32,
              backgroundColor: 'green',
              color: 'white',
              fontSize: '0.675rem',
              '&:hover': {
                backgroundColor: 'green',
              },
            }}>
            DUPLICATE
          </Button>
        </TableCell>
        <TableCell>
          <span>{date.slice(0, 10)}&nbsp;&nbsp;</span>
        </TableCell>
        <TableCell css={{ opacity: 0.5, fontStyle: 'italic' }}>
          {info}
        </TableCell>
        <TableCell>
          {isDone ? (
            <ShiftDone css={{ color: colors.green }} />
          ) : isPast ? (
            <ShiftNotDone css={{ color: colors.red }} />
          ) : (
            <ShiftPlanned css={{ color: colors.gray600 }} />
          )}
        </TableCell>
        <TableCell>
          <IconButton
            aria-label='expand row'
            size='small'
            onClick={() => setOpen(!open)}>
            {open ? <CloseIcon /> : <EditIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow css={{ backgroundColor: '#f8f8f8', width: '100%' }}>
        <TableCell css={{ width: '100%', padding: 0, margin: 0 }} colSpan={10}>
          <Collapse
            in={open}
            timeout='auto'
            unmountOnExit
            css={{ width: '100%' }}>
            <Form
              open={open}
              setOpen={setOpen}
              data={data}
              onSubmit={onSubmit}
              button={{ title: 'UPDATE' }}
              users={users}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export const Form = ({ onSubmit, open, setOpen, data, button, users }) => {
  const initialValues = {
    user: '',
    date: new Date().toISOString().slice(0, 10),
    info: 'Dyżur Standardowy',
    isDone: false,
  }

  const [inputValues, setInputValues] = useState(
    open
      ? data
        ? { ...data, date: data.date.slice(0, 10) }
        : initialValues
      : initialValues
  )

  const handleOnChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  useEffect(() => {
    return () => setInputValues(initialValues)
  }, [open])

  // console.log(inputValues)

  return (
    <Grid
      container
      css={{
        // maxWidth: 'calc(100vw - 2rem)',
        width: '100%',
        padding: '1rem',
        justifyContent: 'space-between',
      }}
      spacing={2}>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <TextField
          fullWidth
          variant='outlined'
          label='Date'
          type='date'
          value={inputValues.date}
          onChange={(e) =>
            setInputValues({
              ...inputValues,
              date: e.target.value.slice(0, 10),
            })
          }
          size='small'
          css={sInput}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={3}>
        <Autocomplete
          value={inputValues.user}
          onChange={(event, newValue) => {
            setInputValues({
              ...inputValues,
              user: newValue,
            })
          }}
          id='company'
          autoComplete={false}
          options={users.filter((user) => user.isActive)}
          getOptionLabel={(option) =>
            option.firstName ? option.firstName + ' ' + option.lastName : ''
          }
          renderOption={(option, { selected }) => (
            <div
              css={{
                fontSize: '0.75rem',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}>
              <Avatar
                alt={option.firstName + ' ' + option.lastName}
                src={imageLink(option.image)}
                css={{
                  width: 24,
                  height: 24,
                  marginRight: 16,
                  fontSize: 12,
                  backgroundColor: option.image ? 'transparent' : option.color,
                }}>
                {option.firstName?.[0]}
                {option.lastName?.[0]}
              </Avatar>
              {option.firstName} {option.lastName}{' '}
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              // inputProps={{
              //   autocomplete: 'company',
              //   form: {
              //     autocomplete: 'off',
              //   },
              // }}
              autoComplete='off'
              variant='outlined'
              label='User'
              placeholder='User'
              size='small'
              css={sInput}
            />
          )}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={2}>
        <Autocomplete
          freeSolo
          value={inputValues.user}
          onChange={(event, newValue) => {
            setInputValues({
              ...inputValues,
              info: newValue,
            })
          }}
          id='info'
          defaultValue='Dyżur Standardowy'
          disableClearable
          autoComplete={false}
          options={SHIFTS_PREDEFINED}
          getOptionLabel={(option) => option}
          // renderOption={(option, { selected }) => (
          // )}
          renderInput={(params) => (
            <TextField
              {...params}
              autoComplete='off'
              variant='outlined'
              label='Info'
              size='small'
              css={sInput}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={2}>
        <FormControlLabel
          css={{ span: { fontSize: '0.75rem' } }}
          control={
            <>
              <Switch
                name='isDone'
                checked={inputValues.isDone}
                onChange={() =>
                  setInputValues({
                    ...inputValues,
                    isDone: !inputValues.isDone,
                  })
                }
                color='primary'
              />
            </>
          }
          label='isDone'
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={2}>
        <Button
          onClick={() =>
            onSubmit({ id: data?._id, input: inputValues, callback: setOpen })
          }
          size='small'
          variant='contained'
          disabled={!inputValues.date || !inputValues?.user}
          css={{
            width: '100%',
            backgroundColor: 'green',
            color: 'white',
            fontSize: '0.75rem',
            '&:hover': {
              backgroundColor: 'green',
            },
          }}>
          {button.title}
        </Button>
      </Grid>
    </Grid>
  )
}

const sInput = {
  input: { fontSize: '0.75rem' },
  label: { fontSize: '0.75rem' },
}

export default SettingsShifts
