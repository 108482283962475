/** @jsxImportSource @emotion/react */

import _ from 'lodash'
import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_ACTIVITIES } from '../../graphql/activity.graphql'
import { toast } from 'react-toastify'
import {
  Paper,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  IconButton,
  CircularProgress,
  Avatar,
} from '@material-ui/core'
import { useAuthContext } from '../../context/auth.context'
import EditIcon from '@material-ui/icons/Edit'
import Skeleton from '@material-ui/lab/Skeleton'
import GetAppIcon from '@material-ui/icons/GetApp'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import TableChartIcon from '@material-ui/icons/TableChart'
import { sLink, sText, sLinkIcon } from '../../components/Menu'

import ActivitiesDetails from './ActivitiesDetails'
import ActivityEditModal from './ActivityEditModal'
import ActivitiesCharts from './ActivitiesCharts'
import ActivitiesPDF from './ActivitiesPDF'
import PageWrapper from '../../components/PageWrapper'
import PageTitle from '../../components/PageTitle'

import { timeDiff } from '../../utils/timeDiff'
import { sTableCategory } from '../Tracking/Tracking'
import { imageLink } from '../../utils/imageLink'
import { minutesToTime } from '../../utils/minutesToTime'

import { PDFDownloadLink } from '@react-pdf/renderer'

import {
  sTableRow,
  sRowActive,
  sRowHasGap,
  sRowHasOverlap,
} from '../Tracking/TrackingRow'
import ActivitiesExcel from './ActivitiesExcel'
import { s } from '../../style'
import ClickupIcon from '../../assets/ClickupIcon'

const ActivitiesData = ({
  inputValues,
  onlyUnfinished,
  showAll,
  mergeActivities,
  setLoading,
}) => {
  const { userId } = useAuthContext()
  const [editActivity, setEditActivity] = useState(null)
  const [isPdfReady, setIsPdfReady] = useState(false)
  const [isExcelReady, setIsExcelReady] = useState(false)
  const [now, setNow] = useState('')
  const {
    data: activitiesData,
    loading: activitiesLoading,
    error: activitiesError,
  } = useQuery(GET_ACTIVITIES, {
    variables: inputValues,
    skip: !userId,
    // pollInterval: 30 * 60000,
    // fetchPolicy: 'network-only',
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date().toString().slice(16, 21))
    }, 1000)

    return () => clearInterval(interval)
  }, [setNow])

  useEffect(() => {
    setLoading(activitiesLoading)
  }, [setLoading, activitiesLoading])

  if (activitiesError) {
    toast.error(activitiesError.toString())
    return <div>Error</div>
  }

  if (activitiesLoading)
    return (
      <PageWrapper>
        <div css={sOuter}>
          <Skeleton variant='rect' height={300} css={{ width: '100%' }} />
        </div>
      </PageWrapper>
    )

  // console.log(activitiesData.getActivities)

  // filter activities only if companyType option is on
  const activitiesCompanyFilter = activitiesData?.getActivities?.filter(
    (activity) =>
      inputValues?.companyType
        ? activity.company.type === inputValues.companyType
        : true
  )

  // remove empty activities
  const activitiesMinutesFilter = activitiesCompanyFilter.filter((activity) =>
    activity.timeEnd && activity.minutes < 1 ? false : true
  )

  // extend activities with current time
  let activities = activitiesMinutesFilter.map((activity) => ({
    ...activity,
    minutes:
      !activity.minutes && now
        ? timeDiff(activity.timeStart, now)
        : activity.minutes,
  }))

  // filter activities if onlyUnfinished option is on
  activities = activities.filter((activity) =>
    onlyUnfinished ? !activity.timeEnd : true
  )

  // extend activities with hasGap and hasOverlap props
  for (let id = 1; id < activities.length; id++) {
    let taskA = activities[id - 1]
    let taskB = activities[id]

    if (taskA.author._id === taskB.author._id && taskA.date === taskB.date)
      if (taskA.timeEnd) {
        const diff = timeDiff(taskB.timeStart, taskA.timeEnd)
        if (diff < -1) {
          taskA.hasGap = true
          taskB.hasGap = true
        }
        if (diff > 1) {
          taskA.hasOverlap = true
          taskB.hasOverlap = true
        }
      }
  }

  // merge similar activities if mergeActivities option is on
  if (mergeActivities) {
    let mergedActivities = []

    const compare = (a, b) =>
      a.date === b.date &&
      a.author?._id === b.author?._id &&
      a.company?._id === b.company?._id &&
      a.category?._id === b.category?._id &&
      a.title === b.title

    for (let id = 0; id < activities.length; id++) {
      let task = activities[id]
      let similars = activities.filter((activity) => compare(activity, task))
      // console.log(id, similars)
      let alreadyMerged = mergedActivities.find((activity) =>
        compare(activity, task)
      )
      // console.log('aleardy', alreadyMerged)
      if (similars.length > 1 && !alreadyMerged) {
        let totalMinutes = similars.reduce((acc, curr) => acc + curr.minutes, 0)
        mergedActivities.push({ ...task, minutes: totalMinutes, merged: true })
      }
      if (similars.length === 1) mergedActivities.push({ ...task })
      // console.log('merged', mergedActivities)
    }
    activities = mergedActivities
  }

  const days =
    _(activities)
      .groupBy('date')
      .map((objs, key) => ({
        date: key,
      }))
      .value().length || 1

  const users =
    _(activities)
      .groupBy('author._id')
      .map((objs, key) => ({
        userId: key,
      }))
      .value().length || 1

  if (!activities || activities.length < 1)
    return (
      <div css={sOuter}>
        <PageWrapper>
          <h5>No data found</h5>
        </PageWrapper>
      </div>
    )

  return (
    <div css={sOuter}>
      <PageWrapper>
        {showAll && (
          <Paper elevation={1}>
            <TableContainer css={{ maxHeight: 500 }}>
              <Table stickyHeader size='small'>
                <TableHead
                  css={{
                    th: { fontSize: '0.75rem', color: 'rgba(0,0,0,0.5)' },
                  }}
                >
                  <TableRow>
                    <TableCell align='left' css={{ paddingLeft: '3rem' }}>
                      Company
                    </TableCell>
                    <TableCell align='left'>Project</TableCell>
                    <TableCell align='left'>Category</TableCell>
                    <TableCell align='left'>Title</TableCell>
                    <TableCell align='center'>Date</TableCell>
                    <TableCell align='center'>Start</TableCell>
                    <TableCell align='center'>End</TableCell>
                    <TableCell align='center'>Time</TableCell>
                    <TableCell align='right'>Author</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activities?.map((row) => (
                    <Row
                      key={row._id}
                      row={row}
                      setEditActivity={setEditActivity}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </PageWrapper>
      {!activitiesLoading && (
        <>
          <PageTitle>
            <div
              css={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                [s.sm_down]: { flexDirection: 'column' },
                width: '100%',
              }}
            >
              <h2 css={{ [s.sm_down]: { paddingBottom: '2rem' } }}>
                <strong>{activities.length}</strong> activitie
                {activities.length > 1 && 's'} in range of{' '}
                <strong>{days}</strong> day
                {days > 1 && 's'} and <strong>{users}</strong> user
                {users > 1 && 's'}
              </h2>
              <div
                css={{
                  display: 'flex',
                  [s.sm_down]: {
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: '100%',
                  },
                }}
              >
                {isExcelReady ? (
                  <ActivitiesExcel
                    data={activities.filter((activity) => activity.minutes > 0)}
                  >
                    <div
                      css={[
                        sLink,
                        { width: 'max-content', marginRight: '1rem' },
                      ]}
                      onClick={() =>
                        setTimeout(() => {
                          setIsExcelReady(!isPdfReady)
                        }, 2000)
                      }
                    >
                      <div css={sLinkIcon}>
                        <TableChartIcon fontSize='inherit' />
                      </div>
                      <div css={sText}>{'Download XLS'}</div>
                    </div>
                  </ActivitiesExcel>
                ) : (
                  <div
                    css={[sLink, { width: 'max-content', marginRight: '1rem' }]}
                    onClick={() => setIsExcelReady(!isExcelReady)}
                  >
                    <div css={sLinkIcon}>
                      <TableChartIcon fontSize='inherit' />
                    </div>
                    <div css={sText}>{'Generate XLS'}</div>
                  </div>
                )}
                {isPdfReady ? (
                  <PDFDownloadLink
                    document={
                      <ActivitiesPDF
                        data={activities.filter(
                          (activity) => activity.minutes > 0
                        )}
                      />
                    }
                    fileName={`CITO Raport - ${
                      activities[0].company.name
                    } - ${activities[0].date?.slice(0, 10)} - ${activities[
                      activities.length - 1
                    ].date?.slice(0, 10)}.pdf`}
                  >
                    {({ blob, url, loading, error }) => (
                      <div
                        css={sLink}
                        onClick={() =>
                          setTimeout(() => {
                            setIsPdfReady(!isPdfReady)
                          }, 2000)
                        }
                      >
                        <div css={sLinkIcon}>
                          {loading ? (
                            <CircularProgress
                              size={24}
                              css={{ color: 'black' }}
                            />
                          ) : (
                            <GetAppIcon fontSize='inherit' />
                          )}
                        </div>
                        <div css={sText}>
                          {loading ? 'Loading' : 'Download PDF'}
                        </div>
                      </div>
                    )}
                  </PDFDownloadLink>
                ) : (
                  <div
                    css={[sLink, { width: 'max-content' }]}
                    onClick={() => setIsPdfReady(!isPdfReady)}
                  >
                    <div css={sLinkIcon}>
                      <PictureAsPdfIcon fontSize='inherit' />
                    </div>
                    <div css={sText}>{'Generate PDF'}</div>
                  </div>
                )}
              </div>
            </div>
          </PageTitle>
          <PageWrapper>
            <PageWrapper>
              <ActivitiesDetails
                data={activities}
                currentMinutes={activities
                  .filter((activity) => !activity.timeEnd)
                  .reduce((acc, curr) => curr.minutes + acc, 0)}
                days={days}
                users={users}
              />
            </PageWrapper>
          </PageWrapper>
        </>
      )}
      {editActivity && (
        <ActivityEditModal
          editActivity={editActivity}
          setEditActivity={setEditActivity}
        />
      )}
      {!activitiesLoading && (
        <PageWrapper>
          <ActivitiesCharts data={activities} />
        </PageWrapper>
      )}
    </div>
  )
}

const Row = ({ row, setEditActivity }) => {
  const {
    _id: rowId,
    title,
    company,
    project,
    category,
    date,
    timeStart,
    timeEnd,
    minutes,
    author,
    merged,
    clickup,
  } = row
  const isBreak = company?.name === 'PRZERWA'

  return (
    <>
      <TableRow
        css={[
          sTableRow,
          !row.timeEnd && sRowActive,
          row.hasGap && sRowHasGap,
          row.hasOverlap && sRowHasOverlap,
        ]}
      >
        <TableCell align='left'>
          <div
            css={[
              {
                display: 'flex',
                alignItems: 'center',
              },
              isBreak && {
                opacity: 0.2,
                fontWeight: 'bold',
                fontSize: '0.75rem',
              },
            ]}
          >
            {company.icon ? (
              <img
                src={imageLink(company.icon)}
                css={[
                  { width: 16, height: 16, marginRight: 16 },
                  isBreak && {
                    width: 12,
                    height: 12,
                    marginRight: 18,
                    marginLeft: 2,
                  },
                ]}
                loading='lazy'
                alt={company.name}
              />
            ) : (
              <div
                css={{
                  width: 16,
                  height: 16,
                  marginRight: 16,
                  backgroundColor: 'white',
                }}
              ></div>
            )}
            {isBreak ? 'Przerwa' : company?.name}
          </div>
        </TableCell>
        <TableCell align='left'>
          <div css={{ display: 'flex', alignItems: 'center' }}>
            {project && (
              <div
                css={{
                  width: 8,
                  height: 8,
                  marginRight: 16,
                  backgroundColor: project?.color || 'rgba(0,0,0,0.2)',
                  transform: 'rotate(45deg)',
                }}
              />
            )}
            {project?.name}
          </div>
        </TableCell>
        <TableCell align='left'>
          <div css={sTableCategory(category?.color)}>{category?.name}</div>
        </TableCell>
        <TableCell
          css={{
            letterSpacing: '-0.025em',
            fontSize: '0.75rem',
            fontWeight: 'bold',
          }}
        >
          <span>{title}</span>
        </TableCell>
        <TableCell align='center'>
          <div css={{ opacity: 0.25 }}>{date?.slice(0, 10)}</div>
        </TableCell>
        <TableCell
          align='center'
          css={[{ position: 'relative' }, merged && { opacity: 0.3 }]}
        >
          {merged ? 'merged' : timeStart}
        </TableCell>
        <TableCell align='center' css={merged && { opacity: 0.3 }}>
          {merged ? 'merged' : timeEnd}
        </TableCell>
        <TableCell
          align='center'
          css={
            !timeEnd && {
              backgroundColor: 'rgb(255 0 0 /10%)',
              color: 'red',
              fontWeight: 'bold',
            }
          }
        >
          <span>{minutesToTime(minutes, 'HH:MM')}</span>
          {/* {minutes >= 60 ? (
            <>
              <strong>{Math.floor(minutes / 60)}</strong>
              <span css={sTableTimeUnit}>h&nbsp;</span>
              <strong>{minutes % 60}</strong>
              <span css={sTableTimeUnit}>m</span>
            </>
          ) : minutes > 0 ? (
            <>
              <strong>{minutes}</strong>
              <span css={sTableTimeUnit}>m</span>
            </>
          ) : (
            <strong>-</strong>
          )} */}
        </TableCell>
        <TableCell align='right'>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              fontSize: '0.675rem',
              span: {
                opacity: 0.5,
              },
            }}
          >
            <span>
              {author.firstName} {author.lastName}
            </span>
            <div
              css={{
                width: 8,
                height: 8,
                marginLeft: 8,
                borderRadius: '50%',
                backgroundColor: author.color,
                flexShrink: 0,
              }}
            />
            <Avatar
              alt={author.firstName + ' ' + author.lastName}
              src={imageLink(author.image)}
              css={{
                width: 24,
                height: 24,
                marginLeft: 8,
                fontSize: 12,
                backgroundColor: author.image ? 'transparent' : author.color,
              }}
            >
              {author.firstName?.[0]}
              {author.lastName?.[0]}
            </Avatar>
          </div>
        </TableCell>
        <TableCell>
          <IconButton onClick={() => setEditActivity(rowId)} size='small'>
            <EditIcon fontSize='inherit' />
          </IconButton>
          {clickup && (
            <a
              href={`clickup://t/${clickup.replace('#', '')}`}
              target='_blank'
              rel='noreferrer noopener'
            >
              <IconButton
                css={{
                  svg: { width: '0.875rem', height: '0.875rem' },
                  marginLeft: '0.5rem',
                }}
                size='small'
              >
                <ClickupIcon />
              </IconButton>
            </a>
          )}
        </TableCell>
      </TableRow>
    </>
  )
}

const sOuter = {
  // height: 600,
  width: '100%',
  '.MuiDataGrid-cell': { fontSize: '0.75rem' },
}

export default ActivitiesData
