/** @jsxImportSource @emotion/react */

import { useState } from 'react'
import { Grid } from '@material-ui/core'
import DashboardChart from './DashboardChart'
import DashboardEstimate from './DashboardEstimate'

const DashboardCompanies = ({
  activitiesAll,
  activitiesToday,
  estimates,
  now,
}) => {
  const [selectedCompany, setSelectedCompany] = useState(null)

  return (
    <Grid container justifyContent='space-between' css={{ padding: '2rem 0' }}>
      <Grid item xs={12} md={12} lg={4}>
        <DashboardChart
          data={activitiesToday}
          setSelectedCompany={setSelectedCompany}
        />
      </Grid>
      <Grid item xs={12} md={12} lg={7}>
        {!!activitiesToday.length && (
          <DashboardEstimate
            key={selectedCompany}
            selectedCompany={selectedCompany}
            setSelectedCompany={setSelectedCompany}
            activities={activitiesAll}
            estimates={estimates}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default DashboardCompanies
