/** @jsxImportSource @emotion/react */

import { useState, useEffect } from 'react'
import { useQuery, useMutation } from '@apollo/client'
import {
  GET_ACTIVITY,
  ACTIVITY_UPDATE,
  GET_ACTIVITIES,
} from '../../graphql/activity.graphql'
import { GET_NAMES } from '../../graphql/data.graphql'
import { useAuthContext } from '../../context/auth.context'
import { CHIEF } from '../../constants'
import { roleNameToValue } from '../../utils'
import { imageLink } from '../../utils/imageLink'
import { toast } from 'react-toastify'
import {
  Grid,
  TextField,
  Modal,
  CircularProgress,
  Button,
  Paper,
  Avatar,
  IconButton,
} from '@material-ui/core'
import { KeyboardTimePicker } from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { s } from '../../style'
import CloseIcon from '@material-ui/icons/Close'
import { getToday } from '../../utils/getToday'

const ActivityEditModal = ({ editActivity, setEditActivity, callback }) => {
  const { userRole, userId } = useAuthContext()

  const newDate = new Date()
  const today = getToday(newDate)
  const refetchVariables = {
    dateFrom: today + 'T00:00:00.000Z',
    dateTo: today + 'T23:59:59.000Z',
    authors: [userId],
  }

  const {
    data: namesData,
    loading: namesLoading,
    error: namesError,
  } = useQuery(GET_NAMES, { skip: !editActivity })

  const {
    data: dataQ,
    loading: loadingQ,
    error: errorQ,
  } = useQuery(GET_ACTIVITY, {
    variables: { id: editActivity },
    skip: !editActivity,
  })

  const initialValues = {
    title: '',
    date: '',
    timeStart: '',
    timeEnd: '',
    company: null,
    project: null,
    category: null,
    author: null,
    clickup: '',
  }
  const [inputValues, setInputValues] = useState(initialValues)

  const [activityUpdate, { loading: loadingM }] = useMutation(ACTIVITY_UPDATE)

  const handleActivityUpdate = () => {
    const activityId = editActivity
    activityUpdate({
      variables: {
        id: activityId,
        input: {
          title: inputValues.title,
          date: inputValues.date,
          timeStart: inputValues.timeStart,
          timeEnd: inputValues.timeEnd,
          company: inputValues.company?._id,
          project: inputValues.project?._id,
          category: inputValues.category?._id,
          author: inputValues.author?._id,
          clickup: inputValues.clickup,
        },
      },
      refetchQueries: [
        { query: GET_ACTIVITIES, variables: { ...refetchVariables } },
      ],
      // update: (cache, { data }) => {
      //   const activitiesCache = cache.readQuery({
      //     query: GET_ACTIVITIES,
      //     variables: refetchVariables,
      //   })
      //   cache.writeQuery({
      //     query: GET_ACTIVITIES,
      //     variables: refetchVariables,
      //     data: {
      //       getActivities: [
      //         ...activitiesCache?.getActivities,
      //         activitiesCache?.getActivities
      //       ],
      //     },
      //   })
      // },
      skip: !activityId,
    })
      .then((res) => {
        const result = res.data.activityUpdate
        if (result) {
          const willBeDeleted = inputValues.timeStart === inputValues.timeEnd
          if (willBeDeleted)
            toast.warning(
              <div>
                <strong>
                  ❌&nbsp;&nbsp;
                  <strong css={{ color: 'darkred', opacity: 1 }}>
                    DELETED
                  </strong>
                </strong>
                <p>
                  <strong>
                    {inputValues.title}
                    <span>
                      <em>
                        &nbsp; (
                        {inputValues.project?.name || inputValues.company?.name}
                        )
                      </em>
                    </span>
                  </strong>
                </p>
                <span>
                  <strong>
                    {inputValues.timeStart} - {inputValues.timeEnd}
                  </strong>
                </span>
              </div>,
              { autoClose: 10000 }
            )
          else
            toast.info(
              <div>
                <strong>
                  ✅&nbsp;&nbsp;<span>UPDATED</span>
                </strong>
                <p>
                  <strong>
                    {inputValues.title}
                    <span>
                      <em>
                        &nbsp; (
                        {inputValues.project?.name || inputValues.company?.name}
                        )
                      </em>
                    </span>
                  </strong>
                </p>
                <span>
                  <strong>
                    {inputValues.timeStart} - {inputValues.timeEnd}
                  </strong>
                </span>
              </div>,
              { autoClose: 10000 }
            )
          setEditActivity(null)
          console.log(result)
          if (callback && result) callback(result)
        }
      })
      .catch((error) => {
        toast.error(error.toString())
      })
  }

  useEffect(() => {
    if (dataQ?.getActivity) {
      // console.log(dataQ)
      setInputValues({
        title: dataQ.getActivity.title,
        date: dataQ.getActivity.date?.slice(0, 10),
        timeStart: dataQ.getActivity.timeStart,
        timeEnd: dataQ.getActivity.timeEnd,
        company: dataQ.getActivity.company,
        project: dataQ.getActivity.project,
        category: dataQ.getActivity.category,
        author: dataQ.getActivity.author,
        clickup: dataQ.getActivity.clickup,
      })
    }
  }, [dataQ])

  if (errorQ || namesError) {
    toast.error(errorQ.toString())
    return (
      <div css={sBackground} onClick={() => setEditActivity(null)}>
        <div css={sFront}>Error</div>
      </div>
    )
  }

  // if (errorM) {
  //   toast.error(errorM.toString())
  // }

  if (loadingQ || namesLoading) {
    toast.error(errorQ)
    return (
      <div css={sBackground} onClick={() => setEditActivity(null)}>
        <CircularProgress />
      </div>
    )
  }

  const { users } = namesData

  const companies = namesData.companies.filter((thing) => !thing.isHidden)
  const projects = namesData.projects.filter((thing) => !thing.isHidden)
  const categories = namesData.categories.filter((thing) => !thing.isHidden)

  const willBeDeleted = inputValues.timeStart === inputValues.timeEnd

  const projectsInCompany = inputValues.company
    ? companies
        ?.find((company) => company._id === inputValues.company._id)
        ?.projects.filter((project) => !project.isHidden).length
    : -1

  return (
    <Modal onClose={() => setEditActivity(null)} open={Boolean(editActivity)}>
      <Paper css={sFront}>
        <IconButton
          size='small'
          css={{ position: 'absolute', top: '2rem', right: '2rem' }}
          onClick={() => setEditActivity(null)}>
          <CloseIcon />
        </IconButton>
        {dataQ && namesData && (
          <>
            <div css={{ paddingBottom: '2rem' }}>
              <h3>Edit Activity</h3>
              <p css={{ fontSize: '0.75rem' }}>
                created by{' '}
                <strong>
                  {dataQ.getActivity.author.firstName}{' '}
                  {dataQ.getActivity.author.lastName}
                </strong>
              </p>
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Title'
                  type='text'
                  value={inputValues.title}
                  onChange={(e) =>
                    setInputValues({ ...inputValues, title: e.target.value })
                  }
                  size='small'
                  css={sInput}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Date'
                  type='date'
                  value={inputValues.date}
                  onChange={(e) =>
                    setInputValues({ ...inputValues, date: e.target.value })
                  }
                  size='small'
                  css={sInput}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <KeyboardTimePicker
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  ampm={false}
                  label='Start Time'
                  value={
                    new Date(`${inputValues.date}T${inputValues.timeStart}`)
                  }
                  onChange={(e) => {
                    // console.log(e)
                    setInputValues({
                      ...inputValues,
                      timeStart: e?.toString().slice(16, 21),
                    })
                  }}
                  size='small'
                  css={sInput}
                />
              </Grid>

              {/* {console.log(inputValues.timeEnd)} */}
              <Grid item xs={6} sm={4}>
                <KeyboardTimePicker
                  variant='inline'
                  fullWidth
                  inputVariant='outlined'
                  ampm={false}
                  label='End Time'
                  value={
                    // inputValues.date + 'T' + inputValues.timeEnd + ':00.000Z'
                    // inputValues.timeEnd
                    inputValues.timeEnd
                      ? new Date(`${inputValues.date}T${inputValues.timeEnd}`)
                      : null
                    // : new Date(`${inputValues.date}T00:00`)
                  }
                  onChange={(e) => {
                    // console.log(e)
                    setInputValues({
                      ...inputValues,
                      timeEnd: e?.toString().slice(16, 21),
                    })
                  }}
                  size='small'
                  css={sInput}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <Autocomplete
                  value={inputValues.company}
                  onChange={(event, newValue) => {
                    setInputValues({
                      ...inputValues,
                      company: newValue,
                    })
                  }}
                  id='company'
                  options={companies}
                  filterOptions={(options, state) => {
                    if (inputValues.category?.type === 'PRZERWA')
                      return options.filter(
                        (option) => option.name === 'PRZERWA'
                      )
                    const isProject = inputValues.project
                    const newOptions = options.filter(
                      (option) =>
                        (isProject
                          ? option?.projects?.filter(
                              (project) => project._id === isProject._id
                            ).length > 0
                          : true) &&
                        option.name
                          .toLowerCase()
                          .includes(state.inputValue?.toLowerCase())
                    )
                    return newOptions
                  }}
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  renderOption={(option, { selected }) => (
                    <div
                      css={{
                        fontSize: '0.75rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      {option.icon ? (
                        <img
                          src={option.icon}
                          css={{ width: 16, height: 16, marginRight: 16 }}
                          loading='lazy'
                          alt={option.name}
                        />
                      ) : (
                        <div
                          css={{
                            width: 16,
                            height: 16,
                            marginRight: 16,
                            border: '1px solid black',
                          }}
                        />
                      )}
                      {option.name}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Company'
                      placeholder='Company'
                      size='small'
                      css={sInput}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4} lg={4}>
                <Autocomplete
                  value={inputValues.project}
                  onChange={(event, newValue) =>
                    setInputValues({
                      ...inputValues,
                      project: newValue,
                    })
                  }
                  id='project'
                  options={projects}
                  filterOptions={(options, state) => {
                    const isCompany = inputValues.company
                    const newOptions = options.filter(
                      (option) =>
                        (isCompany
                          ? option.company._id === isCompany._id
                          : true) &&
                        option.name
                          .toLowerCase()
                          .includes(state.inputValue?.toLowerCase())
                    )
                    return newOptions
                  }}
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  renderOption={(option, { selected }) => (
                    <div
                      css={{
                        fontSize: '0.75rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <div
                        css={{
                          width: 8,
                          height: 8,
                          marginRight: 16,
                          backgroundColor: option.color || 'transparent',
                          border: option.color ? 'none' : '1px solid black',
                          transform: 'rotate(45deg)',
                        }}
                      />
                      {option.name}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={
                        inputValues.company?.require_projects &&
                        !inputValues.project
                      }
                      variant='outlined'
                      label={
                        projectsInCompany >= 0
                          ? `Project (${projectsInCompany})`
                          : 'Project'
                      }
                      placeholder='Project'
                      size='small'
                      css={sInput}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={4} lg={4}>
                <Autocomplete
                  value={inputValues.category}
                  onChange={(event, newValue) => {
                    setInputValues({
                      ...inputValues,
                      category: newValue,
                    })
                  }}
                  id='category'
                  options={categories}
                  getOptionLabel={(option) => (option.name ? option.name : '')}
                  filterOptions={(options, state) => {
                    if (inputValues.company?.name === 'PRZERWA')
                      return options.filter(
                        (option) => option.type === 'PRZERWA'
                      )
                    return options.filter((option) =>
                      option.name
                        .toLowerCase()
                        .includes(state.inputValue?.toLowerCase())
                    )
                  }}
                  renderOption={(option, { selected }) => (
                    <div
                      css={{
                        fontSize: '0.75rem',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}>
                      <div
                        css={[sTableCategory(option.color), { width: '100%' }]}>
                        {option.name}
                      </div>
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Category'
                      placeholder='Category'
                      size='small'
                      css={sInput}
                    />
                  )}
                />
              </Grid>
              {userRole >= roleNameToValue(CHIEF) && (
                <Grid item xs={12} sm={4} lg={4}>
                  <Autocomplete
                    value={inputValues.author}
                    onChange={(event, newValue) => {
                      setInputValues({
                        ...inputValues,
                        author: newValue,
                      })
                    }}
                    id='author'
                    options={users.filter((user) => user.isActive)}
                    getOptionLabel={(option) =>
                      option.firstName
                        ? option.firstName +
                          (option.lastName ? option.lastName : '')
                        : ''
                    }
                    renderOption={(option, { selected }) => (
                      <div
                        css={{
                          fontSize: '0.75rem',
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}>
                        <Avatar
                          alt={option.firstName + ' ' + option.lastName}
                          src={imageLink(option.image)}
                          css={{
                            width: 24,
                            height: 24,
                            marginRight: 16,
                            fontSize: 12,
                            backgroundColor: option.image
                              ? 'transparent'
                              : option.color,
                          }}>
                          {option.firstName?.[0]}
                          {option.lastName?.[0]}
                        </Avatar>
                        <span
                          css={
                            option._id === userId && {
                              color: '#007aff',
                              fontWeight: 'bold',
                            }
                          }>
                          {option.firstName} {option.lastName}{' '}
                        </span>
                        {option._id === userId && (
                          <span css={{ opacity: 0.5 }}>&nbsp;(you)</span>
                        )}
                      </div>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant='outlined'
                        label='Author'
                        placeholder='Author'
                        size='small'
                        css={sInput}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={4} lg={4}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='ClickUp'
                  type='text'
                  value={inputValues.clickup}
                  onChange={(e) =>
                    setInputValues({ ...inputValues, clickup: e.target.value })
                  }
                  size='small'
                  css={sInput}
                />
              </Grid>
            </Grid>

            <Grid
              css={{
                justifySelf: 'flex-end',
                alignSelf: 'flex-end',
                flexGrow: 1,
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                marginTop: '2rem',
              }}>
              {willBeDeleted && (
                <p
                  css={{
                    // backgroundColor: 'red',
                    color: 'red',
                    fontWeight: 'bold',
                    marginRight: '2rem',
                    maxWidth: '100%',
                    fontSize: '0.75rem',
                    padding: '0.5rem 1rem',
                    borderRadius: '1rem',
                    fontStyle: 'italic',
                  }}>
                  Uwaga! Czas trwania aktywności wynosi 0. Zapisanie tego
                  rekordu spowoduje jego nieodwracalne usunięcie!
                </p>
              )}
              <Button
                variant='contained'
                color='primary'
                disabled={
                  loadingM ||
                  !(
                    inputValues.title &&
                    inputValues.date &&
                    inputValues.company &&
                    inputValues.category &&
                    !(
                      inputValues.company?.require_projects &&
                      !inputValues.project
                    )
                  )
                }
                onClick={handleActivityUpdate}
                css={
                  willBeDeleted && {
                    backgroundColor: 'red',
                    '&:hover': {
                      backgroundColor: 'rgb(255 0 0 / 90%)',
                    },
                  }
                }>
                {willBeDeleted ? 'DELETE' : 'SAVE'}
                {loadingM && (
                  <CircularProgress
                    size={24}
                    css={{
                      position: 'absolute',
                      top: 6,
                    }}
                  />
                )}
              </Button>
            </Grid>
          </>
        )}
      </Paper>
    </Modal>
  )
}

const sBackground = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100vw',
  height: '100vh',
  backgroundColor: 'rgba(0,0,0,0.5)',
  zIndex: 101,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}

const sFront = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: 'white',
  padding: '4rem',
  width: '100%',
  maxWidth: 960,
  height: 'max-content',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  [s.xs]: {
    maxHeight: 'calc(100vh - 6rem)',
    padding: '3rem 2rem',
    top: 'auto',
    bottom: 0,
    transform: 'translate(-50%, 0%)',
  },
}

const sInput = {
  input: {
    fontSize: '0.875rem',
  },
  label: {
    fontSize: '0.75rem',
  },
}

const sTableCategory = (color) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: color.concat('40') || 'rgba(0,0,0,0.25)',
  padding: '0.25rem 0.5rem',
  borderRadius: '0.25rem',
  fontSize: '0.675rem',
  fontWeight: 'bold',
  color: color.concat('80') || 'rgba(0,0,0,1)',
  textShadow: '0px 0px 0px rgba(0,0,0,0.5)',
})

export default ActivityEditModal
