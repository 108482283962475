import { useState } from 'react'

export const useUI = () => {
  const [editActivityOpen, setEditActivityOpenState] = useState(null)
  const [editEstimateOpen, setEditEstimateOpenState] = useState(null)
  const setEditActivityOpen = (value) => setEditActivityOpenState(value)
  const setEditEstimateOpen = (value) => setEditEstimateOpenState(value)

  return {
    editActivityOpen,
    setEditActivityOpen,
    editEstimateOpen,
    setEditEstimateOpen,
  }
}
