/** @jsxImportSource @emotion/react */

// Apollo Client
import { useQuery } from '@apollo/client'
import { toast } from 'react-toastify'
import { GET_NAMES } from '../../graphql/data.graphql'
import { useAuthContext } from '../../context/auth.context'
import PageWrapper from '../../components/PageWrapper'

// Material UI
import {
  Checkbox,
  TextField,
  Grid,
  IconButton,
  Chip,
  Avatar,
  Button,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import ClearIcon from '@material-ui/icons/Clear'
import CircularProgress from '@material-ui/core/CircularProgress'
import SelectAllIcon from '@material-ui/icons/SelectAll'

// Utils
import { roleNameToValue } from '../../utils'
import { imageLink } from '../../utils/imageLink'
import { INACTIVE, INTERN, EXPERT, CHIEF, companyTypes } from '../../constants'

// Styles
import { alpha } from '../../style'
import { useState } from 'react'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const ActivitiesInputs = ({
  inputValues: inputValuesParent,
  setInputValues: setInputValuesParent,
  initialValues,
  isTeam,
  loading,
}) => {
  const { userId, userRole } = useAuthContext()
  const [inputValues, setInputValues] = useState(
    isTeam ? inputValuesParent : initialValues
  )

  const isIntern = userRole <= roleNameToValue(INTERN)
  const isExpert = userRole >= roleNameToValue(EXPERT)
  const isChief = userRole >= roleNameToValue(CHIEF)

  const {
    data: namesData,
    loading: namesLoading,
    error: namesError,
  } = useQuery(GET_NAMES)

  const onSubmit = () => {
    setInputValuesParent(inputValues)
  }

  if (namesLoading) return <div />

  if (namesError) {
    toast.error(namesError.message)
    return <div>Error: {namesError.message}</div>
  }

  const { categories, companies, projects, users } = namesData

  // console.log(users)

  return (
    <PageWrapper>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} lg={4} css={{ position: 'relative' }}>
          <Autocomplete
            value={users?.filter((user) =>
              inputValues.authors?.includes(user._id)
            )}
            onChange={(event, newValue) => {
              const ids = newValue?.map((obj) => obj._id)
              setInputValues({
                ...inputValues,
                authors: isIntern ? [userId] : ids,
              })
            }}
            multiple
            id='authors'
            options={
              isChief
                ? users.filter((user) => user.isActive)
                : isExpert
                ? users.filter((user) => user.isActive)
                : users.filter((user) => user._id === userId)
            }
            disableCloseOnSelect
            getOptionLabel={(option) =>
              option.firstName + ' ' + option.lastName
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  size='small'
                  avatar={
                    <Avatar
                      alt={option.firstName + ' ' + option.lastName}
                      src={imageLink(option.image)}
                      css={{ backgroundColor: option.color.concat(alpha[50]) }}
                    >
                      {option.firstName[0]}
                      {option.lastName?.[0]}
                    </Avatar>
                  }
                  variant='default'
                  label={
                    option.firstName +
                    ' ' +
                    (option.lastName ? option.lastName : '')
                  }
                  {...getTagProps({ index })}
                />
              ))
            }
            renderOption={(option, { selected }) => (
              <div
                css={{
                  fontSize: '0.75rem',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  css={[{ marginRight: 8 }]}
                  checked={selected}
                />
                <Avatar
                  alt={option.firstName + ' ' + option.lastName}
                  src={imageLink(option.image)}
                  css={{
                    width: 24,
                    height: 24,
                    marginRight: 16,
                    fontSize: 12,
                    backgroundColor: option.image
                      ? 'transparent'
                      : option.color,
                  }}
                >
                  {option.firstName?.[0]}
                  {option.lastName?.[0]}
                </Avatar>
                <span
                  css={
                    option._id === userId && {
                      color: '#007aff',
                      fontWeight: 'bold',
                    }
                  }
                >
                  {option.firstName} {option.lastName ? option.lastName : ''}{' '}
                </span>
                {option._id === userId && (
                  <span css={{ opacity: 0.5 }}>&nbsp;(you)</span>
                )}
              </div>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant='outlined'
                label='Authors'
                placeholder='Author'
                size='small'
                css={sInput}
              />
            )}
          />
          <IconButton
            size='small'
            aria-label='delete'
            css={{
              position: 'absolute',
              top: '50%',
              transform: 'translateY(-50%)',
              right: '4.5rem',
            }}
            onClick={() =>
              setInputValues({
                ...inputValues,
                authors: users.map((user) => user._id),
              })
            }
          >
            <SelectAllIcon fontSize='small' />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <div css={{ position: 'relative' }}>
            <TextField
              fullWidth
              variant='outlined'
              label='Date From'
              type='date'
              value={inputValues.dateFrom}
              onChange={(e) =>
                setInputValues({ ...inputValues, dateFrom: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              css={sInput}
            />
            <IconButton
              size='small'
              aria-label='delete'
              css={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '4rem',
              }}
              onClick={() => setInputValues({ ...inputValues, dateFrom: '' })}
            >
              <ClearIcon fontSize='small' />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <div css={{ position: 'relative' }}>
            <TextField
              fullWidth
              variant='outlined'
              label='Date To'
              type='date'
              value={inputValues.dateTo}
              onChange={(e) =>
                setInputValues({ ...inputValues, dateTo: e.target.value })
              }
              InputLabelProps={{
                shrink: true,
              }}
              size='small'
              css={sInput}
            />
            <IconButton
              size='small'
              aria-label='delete'
              css={{
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                right: '4rem',
              }}
              onClick={() => setInputValues({ ...inputValues, dateTo: '' })}
            >
              <ClearIcon fontSize='small' />
            </IconButton>
          </div>
        </Grid>
        {!isTeam && (
          <>
            <Grid item xs={12} sm={6} lg={4}>
              <div css={{ position: 'relative' }}>
                <Autocomplete
                  value={companies?.filter((company) =>
                    inputValues.companies.includes(company._id)
                  )}
                  onChange={(event, newValue) => {
                    const ids = newValue?.map((obj) => obj._id)
                    // console.log(ids)
                    setInputValues({ ...inputValues, companies: ids })
                  }}
                  multiple
                  id='companies'
                  options={companies}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size='small'
                        avatar={
                          <Avatar
                            alt={option.name}
                            src={imageLink(option.icon)}
                          />
                        }
                        variant='default'
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(option, { selected }) => (
                    <div
                      css={{
                        fontSize: '0.75rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        css={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.icon ? (
                        <img
                          src={option.icon}
                          css={{ width: 16, height: 16, marginRight: 16 }}
                          loading='lazy'
                          alt={option.name}
                        />
                      ) : (
                        <div
                          css={{
                            width: 16,
                            height: 16,
                            marginRight: 16,
                            border: '1px solid black',
                          }}
                        />
                      )}
                      {option.name}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Companies'
                      placeholder='Company'
                      size='small'
                      css={sInput}
                    />
                  )}
                />
                <IconButton
                  size='small'
                  aria-label='delete'
                  css={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '3.75rem',
                  }}
                  onClick={() =>
                    setInputValues({
                      ...inputValues,
                      companies: companies.map((company) => company._id),
                    })
                  }
                >
                  <SelectAllIcon fontSize='small' />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <div css={{ position: 'relative' }}>
                <Autocomplete
                  value={projects?.filter((project) =>
                    inputValues.projects.includes(project._id)
                  )}
                  onChange={(event, newValue) => {
                    const ids = newValue?.map((obj) => obj._id)
                    // console.log(ids)
                    setInputValues({ ...inputValues, projects: ids })
                  }}
                  multiple
                  id='projects'
                  options={projects}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size='small'
                        avatar={
                          <div
                            css={{
                              width: '8px !important',
                              height: '8px !important',
                              margin: '0 0 0 0.5rem !important',
                              backgroundColor: option.color || 'transparent',
                              border: option.color ? 'none' : '1px solid black',
                              transform: 'rotate(45deg)',
                            }}
                          />
                        }
                        variant='default'
                        label={option.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(option, { selected }) => (
                    <div
                      css={{
                        fontSize: '0.75rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        css={{ marginRight: 8 }}
                        checked={selected}
                      />
                      <div
                        css={{
                          width: 8,
                          height: 8,
                          marginRight: 16,
                          backgroundColor: option.color || 'transparent',
                          border: option.color ? 'none' : '1px solid black',
                          transform: 'rotate(45deg)',
                        }}
                      />
                      {option.name}
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Projects'
                      placeholder='Project'
                      size='small'
                      css={sInput}
                    />
                  )}
                />
                <IconButton
                  size='small'
                  aria-label='delete'
                  css={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '4rem',
                  }}
                  onClick={() =>
                    setInputValues({
                      ...inputValues,
                      projects: projects.map((project) => project._id),
                    })
                  }
                >
                  <SelectAllIcon fontSize='small' />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <div css={{ position: 'relative' }}>
                <Autocomplete
                  value={categories?.filter((category) =>
                    inputValues.categories.includes(category._id)
                  )}
                  onChange={(event, newValue) => {
                    const ids = newValue?.map((obj) => obj._id)
                    // console.log(ids)
                    setInputValues({ ...inputValues, categories: ids })
                  }}
                  multiple
                  id='categories'
                  options={categories}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        size='small'
                        variant='default'
                        label={option.name}
                        css={sTableCategory(option.color)}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderOption={(option, { selected }) => (
                    <div
                      css={{
                        fontSize: '0.75rem',
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        css={{ marginRight: 8 }}
                        checked={selected}
                        size='small'
                      />
                      <div
                        css={[sTableCategory(option.color), { width: '100%' }]}
                      >
                        {option.name}
                      </div>
                    </div>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='outlined'
                      label='Categories'
                      placeholder='Category'
                      size='small'
                      css={sInput}
                    />
                  )}
                />
                <IconButton
                  size='small'
                  aria-label='delete'
                  css={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translateY(-50%)',
                    right: '4rem',
                  }}
                  onClick={() =>
                    setInputValues({
                      ...inputValues,
                      categories: categories.map((category) => category._id),
                    })
                  }
                >
                  <SelectAllIcon fontSize='small' />
                </IconButton>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Autocomplete
                fullWidth
                value={inputValues.companyType}
                options={companyTypes}
                onChange={(event, newValue) => {
                  setInputValues({
                    ...inputValues,
                    companyType: newValue,
                  })
                }}
                renderOption={(option, { selected }) => (
                  <div css={{ fontSize: '0.75rem' }}>{option}</div>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    variant='outlined'
                    label='CompanyType'
                    type='text'
                    size='small'
                    css={sInput}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <TextField
                fullWidth
                variant='outlined'
                label='Title'
                type='text'
                value={inputValues.title}
                onChange={(e) =>
                  setInputValues({ ...inputValues, title: e.target.value })
                }
                size='small'
                css={sInput}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <TextField
                fullWidth
                variant='outlined'
                label='Minutes From'
                type='number'
                value={inputValues.minFrom}
                onChange={(e) =>
                  setInputValues({
                    ...inputValues,
                    minFrom: parseInt(e.target.value),
                  })
                }
                size='small'
                css={sInput}
              />
            </Grid>
            <Grid item xs={12} sm={6} lg={2}>
              <TextField
                fullWidth
                variant='outlined'
                label='Minutes To'
                type='number'
                value={inputValues.minTo}
                onChange={(e) =>
                  setInputValues({
                    ...inputValues,
                    minTo: parseInt(e.target.value),
                  })
                }
                size='small'
                css={sInput}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          <Button
            variant='contained'
            color='primary'
            disabled={loading}
            onClick={onSubmit}
            css={{ width: '100%' }}
          >
            {loading ? (
              <span css={{ opacity: 0.5 }}>LOADING DATA</span>
            ) : (
              'SEARCH'
            )}
            {loading && (
              <CircularProgress
                color='black'
                size={24}
                css={{
                  position: 'absolute',
                  top: 6,
                }}
              />
            )}
          </Button>
        </Grid>
      </Grid>
    </PageWrapper>
  )
}

const sInput = {
  input: { fontSize: '0.875rem' },
  label: { fontSize: '0.75rem' },
}

const sTableCategory = (color) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: color.concat('40') || 'rgba(0,0,0,0.25)',
  padding: '0.25rem 0.5rem',
  borderRadius: '0.25rem',
  fontSize: '0.675rem',
  fontWeight: 'bold',
  color: color.concat('80') || 'rgba(0,0,0,1)',
  textShadow: '0px 0px 0px rgba(0,0,0,0.5)',
})

export default ActivitiesInputs
