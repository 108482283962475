/** @jsxImportSource @emotion/react */

import { Button, TextField, Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useState } from 'react'
import citoLogo from '../../assets/cito_logo.svg'
import useUserMutations from '../../hooks/useUser.mutations'
import { s } from '../../style'
import Seo from '../../components/Seo'
import { Link } from 'react-router-dom'

const initValues = {
  email: '',
  password: '',
}

const Login = () => {
  const [inputValues, setInputValues] = useState(initValues)
  const [isLoading, setIsLoading] = useState(false)
  const { handleUserLogin } = useUserMutations()

  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true)
    handleUserLogin(inputValues, () => {
      return setIsLoading(false)
    })
  }

  const onChange = (e) =>
    setInputValues({ ...inputValues, [e.target.name]: e.target.value })

  return (
    <>
      <Seo />
      <div css={sPage}>
        <div css={sBackground} />
        <form onSubmit={handleSubmit} css={sForm}>
          <div css={sTitle}>
            <img alt='CITO' src={citoLogo} />
            {/* <h6>Company Internal Task Organizer</h6> */}
          </div>
          <Grid container css={{ width: '100%' }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                variant='outlined'
                name='email'
                type='text'
                label='Email'
                autoComplete='email'
                value={inputValues.email}
                onChange={onChange}
                css={sInput}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size='small'
                variant='outlined'
                name='password'
                type='password'
                label='Password'
                autoComplete='current-password'
                value={inputValues.password}
                onChange={onChange}
                css={sInput}
              />
            </Grid>
          </Grid>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            css={{ marginTop: '2rem', width: '100%' }}
            disabled={isLoading}>
            SIGN IN
            {isLoading && (
              <CircularProgress
                size={24}
                css={{
                  position: 'absolute',
                  top: 6,
                }}
              />
            )}
          </Button>
          <div
            css={{
              display: 'flex',
              width: '100%',
              marginTop: '2rem',
              fontSize: '0.75rem',
              opacity: 0.5,
              '&:hover': {
                opacity: 1,
              },
            }}>
            <Link to='/password-reset'>Nie pamiętasz hasła? Utwórz nowe.</Link>
          </div>
        </form>
      </div>
    </>
  )
}

export const sPage = {
  width: '100vw',
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',

  // https://www.swps.pl/images/2015/Aktualnosci/wielozadaniowy_2000_1316.jpg

  // https://images.unsplash.com/photo-1432889490240-84df33d47091?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2089&q=100
}

export const sBackground = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  background:
    'white url(https://rdbgroup.pl/icons/icon-512x512.png?v=d5db616228c938d76be33b67a2482c73) space 0 0',
  backgroundSize: '5%',
  opacity: 0.2,
}

export const sTitle = {
  textAlign: 'center',
  img: {
    height: 48,
  },
  h6: {
    marginTop: '1rem',
    fontWeight: 900,
    opacity: 0.5,
  },
  marginBottom: '2rem',
}

export const sForm = {
  transition: 'background-color 0.6s ease-in-out',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  // left: '-10%',
  width: '100%',
  height: 'max-content',
  maxWidth: 1920 / 4,
  padding: '4rem',
  backgroundColor: 'rgb(255 255 255 /25%)',
  borderRadius: '1rem',
  boxShadow: '0px 0px 16px rgb(0 0 0 /30%)',
  backdropFilter: 'blur(8px) brightness(1) contrast(1)',
  [s.sm_down]: {
    padding: '2rem',
    height: '100vh',
    left: 0,
  },
  '&:hover': {
    backgroundColor: 'rgb(255 255 255 /100%)',
  },
}

export const sInput = {
  marginTop: '2rem',
  input: { fontSize: '0.75rem' },
  label: { fontSize: '0.75rem' },
}

export default Login
